import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { fadeInOut } from "src/app/services/animations";
import { LocationTypes } from "src/app/models/location";
import { TransferOrder, TransferOrderType } from "src/app/models/transferOrder";
import { TransferOrderLine } from "src/app/models/transferOrderLine";
import { Tax } from "src/app/models/tax";
import { PrintLayoutService } from "src/app/components/print-layout/print-layout.service";

@Component({
  selector: "app-tansfer-order-print",
  templateUrl: "./tansfer-order-print.component.html",
  styleUrls: ["./tansfer-order-print.component.scss"],
  animations: [fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TansferOrderPrintComponent implements OnInit {
  public dataSourceSOLine: TransferOrderLine[] = [];
  displayedColumns = [
    "itemCode",
    "productCode",
    "productName",
    "sellingPrice",
    "qtyReceived",
    "sellingPriceTotal",
  ];

  id: number;
  transferOrder: TransferOrder;
  tax: Tax;

  constructor(
    private printLayoutService: PrintLayoutService,
    private route: ActivatedRoute
  ) {
    this.id = Number(this.route.snapshot.paramMap.get("id"));

    this.route.data.subscribe(({ transferOrder }) => {
      if (transferOrder) {
        this.transferOrder = transferOrder;
      }
    });

    this.route.data.subscribe(({ transferOrderLine }) => {
      if (transferOrderLine) {
        this.dataSourceSOLine = transferOrderLine;
      }
    });

    this.route.data.subscribe(({ tax }) => {
      if (tax) {
        this.tax = tax.rate;
      }
    });
  }

  getLocationType(value) {
    return LocationTypes[value];
  }
  getTransferOrderType(value) {
    return TransferOrderType[value];
  }

  getTaxedPrice(row: TransferOrderLine) {
    let taxRate = 1 + this.tax.rate / 100;
    let sellingPrice: number = 0;
    sellingPrice =
      row.product.productPrices?.length > 0
        ? row.product.productPrices.find((x) => x.status).sellingPrice
        : 0;

    return sellingPrice * taxRate;
  }

  getTotalTaxedPrice(row: TransferOrderLine) {
    return this.getTaxedPrice(row) * row.qtyReceived;
  }

  getTotalSum() {
    return this.dataSourceSOLine?.length > 0
      ? this.dataSourceSOLine
          .map((t) => this.getTotalTaxedPrice(t))
          .reduce((acc, value) => acc + value, 0)
      : 0;
  }

  getQuantitySum() {
    return this.dataSourceSOLine?.length > 0
      ? this.dataSourceSOLine
          .map((t) => t.qtyReceived)
          .reduce((acc, value) => acc + value, 0)
      : 0;
  }

  ngOnInit() {
    setTimeout(() => {
      this.printLayoutService.onDataReady();
    }, 1000);
  }
}
