import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Brand } from "../models/brand";
import { TaxExemption } from "../models/taxExemption";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class TaxExemptionService extends EntityService<TaxExemption> {
  private readonly API_URL = "/api/taxExemptions";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/taxExemptions");
  }

  get taxExemptionCount(): number {
    return this.taxExemptionCount;
  }

  getAllTaxExemption(): Observable<TaxExemption[]> {
    return this.httpClient.get<TaxExemption[]>(`${this.API_URL}`);
  }

  getTaxExemptionByBusinessTypeId(
    businessTypeId: number
  ): Observable<TaxExemption[]> {
    return this.httpClient.get<TaxExemption[]>(
      `${this.API_URL}/GetTaxExemptionByBusinessTypeId?businessTypeId=` +
        businessTypeId
    );
  }

  getTaxExemptionsByCustomerIdAndBusinessTypeId(
    businessTypeId: number,
    customerId: number
  ): Observable<TaxExemption> {
    return this.httpClient.get<TaxExemption>(
      `${this.API_URL}/GetTaxExemptionsByCustomerIdAndBusinessTypeId?businessTypeId=` +
        businessTypeId +
        `&customerId=` +
        customerId
    );
  }

  addTaxExemption(taxExemption: TaxExemption): Observable<TaxExemption> {
    return this.httpClient.post<TaxExemption>(this.API_URL, taxExemption);
  }

  updateTaxExemption(taxExemption: TaxExemption): Observable<TaxExemption> {
    return this.httpClient.put<TaxExemption>(
      `${this.API_URL}/` + taxExemption.taxExemptionId,
      taxExemption
    );
  }

  deleteTaxExemption(taxExemptionId: number): Observable<TaxExemption> {
    return this.httpClient.delete<TaxExemption>(
      `${this.API_URL}/` + taxExemptionId
    );
  }

  UploadExcel(formData: FormData) {
    let headers = new HttpHeaders();

    headers.append("Content-Type", "multipart/form-data");
    // headers.append("Accept", "application/json");
    const httpOptions = { headers: headers };
    return this.httpClient.post(
      this.API_URL + "/ExcelUpload",
      formData,
      httpOptions
    );
  }

  download(fileName): Observable<Blob> {
    return this.httpClient.post<Blob>(
      `${this.API_URL}/ExportFile?fileName=` + fileName,
      fileName,
      { responseType: "blob" as "json" }
    );
  }
}
