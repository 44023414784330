import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { SpecialExemption } from "../models/specialExemption";
import { SpecialExemptionService } from "../services/specialExemption.service";
import { AuthService } from "../core/services/auth.service";

@Injectable()
export class SpecialExemptionResolver implements Resolve<SpecialExemption[]> {
  constructor(
    private service: SpecialExemptionService,
    private authService: AuthService
  ) {}

  resolve(): Observable<SpecialExemption[]> {
    if (this.authService.isLoggedIn) {
      if (this.authService.currentBusiness) {
        return this.service.getSpecialExemptionByBusinessTypeId(
          this.authService.currentBusiness.businessTypeId
        );
      }
    } else {
      return null;
    }
  }
}
