import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { PaymentTerm } from "../models/paymentTerm";
import { PaymentTermService } from "../services/paymentTerm.service";

@Injectable()
export class PaymentTermResolver implements Resolve<PaymentTerm[]> {
  constructor(private service: PaymentTermService) {}

  resolve(): Observable<PaymentTerm[]> {
    return this.service.getPaymentTerms();
  }
}
