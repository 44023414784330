import { Injectable } from "@angular/core";
import { Resolve, Router } from "@angular/router";
import { Observable } from "rxjs";
import { Model } from "../models/model";
import { ModelService } from "../services/model.service";
import { AuthService } from "../core/services/auth.service";

@Injectable()
export class ModelResolver implements Resolve<Model[]> {
  constructor(
    private service: ModelService,
    private authService: AuthService
  ) {}

  resolve(): Observable<Model[]> {
    if (this.authService.isLoggedIn) {
      if (this.authService.currentBusiness) {
        return this.service.getModelByBusinessTypeId(
          this.authService.currentBusiness.businessTypeId
        );
      }
    } else {
      return null;
    }
  }
}
