import { Denomination } from "./../models/denomination";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { EntityService } from "./entity.service";
import { CustomResult } from "../models/custom-model/customResult.model";

@Injectable({
  providedIn: "root",
})
export class DenominationService extends EntityService<Denomination> {
  private readonly API_URL = "/api/denominations";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/denominations");
  }

  getAllDenominations(): Observable<Denomination[]> {
    return this.httpClient.get<Denomination[]>(
      `${this.API_URL}/GetAllDenominations`
    );
  }

  getDenominationById(id: number): Observable<CustomResult> {
    return this.httpClient.get<CustomResult>(`${this.API_URL}/` + id);
  }

  addDenomination(denomination: Denomination): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(this.API_URL, denomination);
  }

  updateDenomination(denomination: Denomination): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/` + denomination.denominationId,
      denomination
    );
  }

  deleteDenomination(denominationId: Denomination): Observable<Denomination> {
    return this.httpClient.delete<Denomination>(
      `${this.API_URL}/` + denominationId
    );
  }

  getDenominationByDateAndLocation(
    denominationDate: string,
    locationId: number,
    businessTypeId: number
  ): Observable<CustomResult> {
    return this.httpClient.get<CustomResult>(
      `${this.API_URL}/GetDenominationByDateAndLocation?denominationDate=` +
        denominationDate +
        `&locationId=` +
        locationId +
        `&businessTypeId=` +
        businessTypeId
    );
  }
}
