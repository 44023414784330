import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EntityService } from "./entity.service";
import { CustomResult } from "../models/custom-model/customResult.model";
import { Dashboard, DashboardCounts } from "../models/dashboard";

@Injectable({
  providedIn: "root",
})
export class DashboardService extends EntityService<any> {
  private readonly API_URL = "/api/Dashboard";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/Dashboard");
  }

  getExpirePromotions(businessTypeId: number) {
    return this.httpClient.get<any>(
      `${this.API_URL}/GetExpirePromotions?businessTypeId=` + businessTypeId
    );
  }

  getExpireQuotations(businessTypeId: number, locationId: number) {
    return this.httpClient.get<any>(
      `${this.API_URL}/GetExpireQuotations?businessTypeId=` +
        businessTypeId +
        `&locationId=` +
        locationId
    );
  }

  getCancelZeroItemsSales(businessTypeId: number, locationId: number) {
    return this.httpClient.get<any>(
      `${this.API_URL}/GetCancelZeroItemsSales?businessTypeId=` +
        businessTypeId +
        `&locationId=` +
        locationId
    );
  }

  getDashboardData(businessTypeId: number, locationId: number) {
    return this.httpClient.get<Dashboard>(
      `${this.API_URL}/GetDashboardData?businessTypeId=` +
        businessTypeId +
        `&locationId=` +
        locationId
    );
  }

  getDashboardCountData(businessTypeId: number, locationId: number) {
    return this.httpClient.get<DashboardCounts>(
      `${this.API_URL}/GetDashboardCountData?businessTypeId=` +
        businessTypeId +
        `&locationId=` +
        locationId
    );
  }

  getDashboardSalesMonthly(businessTypeId: number, locationId: number) {
    return this.httpClient.get<CustomResult>(
      `${this.API_URL}/GetDashboardSalesMonthly?businessTypeId=` +
        businessTypeId +
        `&locationId=` +
        locationId
    );
  }
}
