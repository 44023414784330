import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { TaxExemption } from "../models/taxExemption";
import { TaxExemptionService } from "../services/taxExemption.service";
import { AuthService } from "../core/services/auth.service";

@Injectable()
export class TaxExemptionResolver implements Resolve<TaxExemption[]> {
  constructor(
    private service: TaxExemptionService,
    private authService: AuthService
  ) {}

  resolve(): Observable<TaxExemption[]> {
    if (this.authService.isLoggedIn) {
      if (this.authService.currentBusiness) {
        return this.service.getTaxExemptionByBusinessTypeId(
          this.authService.currentBusiness.businessTypeId
        );
      }
    } else {
      return null;
    }
  }
}
