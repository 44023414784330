import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SpecialExemption } from "../models/specialExemption";
import { TaxExemption } from "../models/taxExemption";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class SpecialExemptionService extends EntityService<SpecialExemption> {
  private readonly API_URL = "/api/specialExemption";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/specialExemption");
  }

  get taxExemptionCount(): number {
    return this.taxExemptionCount;
  }

  getAllSpecialExemption(): Observable<SpecialExemption[]> {
    return this.httpClient.get<SpecialExemption[]>(`${this.API_URL}`);
  }

  getSpecialExemptionByBusinessTypeId(
    businessTypeId: number
  ): Observable<SpecialExemption[]> {
    return this.httpClient.get<SpecialExemption[]>(
      `${this.API_URL}/GetSpecialExemptionByBusinessTypeId?businessTypeId=` +
        businessTypeId
    );
  }

  getSpecialExemptionByFilters(
    businessTypeId: number,
    productId: number,
    discountId: number
  ): Observable<SpecialExemption> {
    return this.httpClient.get<SpecialExemption>(
      `${this.API_URL}/GetSpecialExemptionByFilters?businessTypeId=` +
        businessTypeId +
        `&productId=` +
        productId +
        `&discountId=` +
        discountId
    );
  }

  addSpecialExemption(
    specialExemption: SpecialExemption
  ): Observable<SpecialExemption> {
    return this.httpClient.post<SpecialExemption>(
      this.API_URL,
      specialExemption
    );
  }

  updateSpecialExemption(
    specialExemption: SpecialExemption
  ): Observable<SpecialExemption> {
    return this.httpClient.put<SpecialExemption>(
      `${this.API_URL}/` + specialExemption.specialExemptionId,
      specialExemption
    );
  }

  deleteSpecialExemption(
    specialExemptionId: number
  ): Observable<SpecialExemption> {
    return this.httpClient.delete<SpecialExemption>(
      `${this.API_URL}/` + specialExemptionId
    );
  }
}
