import { Injectable } from "@angular/core";
import { HttpClient, HttpEventType } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class UploadService {
  private readonly API_URL = "/api/documents";

  constructor(public httpClient: HttpClient) {}

  public upload(data) {
    let uploadURL = `${this.API_URL}`;
    let percentage = 0;

    return this.httpClient
      .post<any>(uploadURL, data, {
        observe: "events",
        reportProgress: true,
      })
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.Sent:
              return { status: "sent", progress: percentage };

            case HttpEventType.UploadProgress:
              const roundValue = Math.round((100 * event.loaded) / event.total);
              if (percentage < roundValue) percentage = roundValue;

              return {
                status: "Upload Progress",
                progress: percentage,
                filePath: "",
              };

            case HttpEventType.ResponseHeader:
              return {
                status: "Headers Received",
                progress: percentage,
                filePath: "",
              };

            case HttpEventType.Response:
              return event.body;
            default:
              return { status: "", progress: percentage, filePath: "" }; //`File  surprising upload event: ${event.type}.`;//`Unhandled event: ${event.type}`;
          }
        })
      );
  }
}
