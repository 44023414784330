import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { Vendor } from "../models/vendor";
import { VendorService } from "../services/vendor.service";

@Injectable()
export class VendorResolver implements Resolve<Vendor[]> {
  constructor(private service: VendorService) {}

  resolve(): Observable<Vendor[]> {
    return this.service.getAllVendors();
  }
}
