import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MissedProduct } from "src/app/models/missedProduct";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class MissedProductService extends EntityService<MissedProduct> {
  private readonly API_URL = "/api/missedproducts";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/missedproducts");
  }

  getAllMissedProducts(): Observable<MissedProduct[]> {
    return this.httpClient.get<MissedProduct[]>(`${this.API_URL}`);
  }

  get MissedProductCount(): number {
    return this.MissedProductCount;
  }

  addMissedProduct(missedProduct: MissedProduct): Observable<MissedProduct> {
    return this.httpClient.post<MissedProduct>(this.API_URL, missedProduct);
  }

  updateMissedProduct(missedProduct: MissedProduct): Observable<MissedProduct> {
    return this.httpClient.put<MissedProduct>(
      `${this.API_URL}/` + missedProduct.missedProductId,
      MissedProduct
    );
  }

  deleteMissedProduct(missedProductId: number): Observable<MissedProduct> {
    return this.httpClient.delete<MissedProduct>(
      `${this.API_URL}/` + missedProductId
    );
  }
}
