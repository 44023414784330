import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { EntityService } from "./entity.service";
import { CustomResult } from "../models/custom-model/customResult.model";
import { UserRegistration } from "../core/models/user-registration.model";
import { User } from "../core/models/user.model";
import { ApplicationUserReset } from "../core/models/user-reset.model";
import { ApplicationUserChange } from "../core/models/user-change.model";

@Injectable({
  providedIn: "root",
})
export class ApplicationUserService extends EntityService<User> {
  private readonly API_URL = "/api/applicationUsers";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/applicationUsers");
  }

  getApplicationUserCode() {
    return this.httpClient.get(`${this.API_URL}/getApplicationUserCode`, {
      responseType: "text",
    });
  }

  getAllApplicationUser(): Observable<User[]> {
    return this.httpClient.get<User[]>(`${this.API_URL}`);
  }

  getApplicationUserByUserId(UserId: string): Observable<User> {
    return this.httpClient.get<User>(
      `${this.API_URL}/getApplicationUserByUserId?UserId=` + UserId
    );
  }

  getApplicationUserByUserName(userName: string): Observable<User> {
    return this.httpClient.get<User>(
      `${this.API_URL}/getApplicationUserByUserName?userName=` + userName
    );
  }
  getApplicationUserByEmail(email: string): Observable<User> {
    return this.httpClient.get<User>(
      `${this.API_URL}/getApplicationUserByEmail?email=` + email
    );
  }
  getApplicationUserByPhone(phoneNumber: string): Observable<User> {
    return this.httpClient.get<User>(
      `${this.API_URL}/GetApplicationUserByPhone?phoneNumber=` + phoneNumber
    );
  }

  validateOldPasswordCorrect(
    userId: string,
    oldPassword: string
  ): Observable<any> {
    const endpointUrl = `${this.API_URL}/validateOldPassword`;
    return this.httpClient.get<any>(endpointUrl, {
      params: new HttpParams()
        .set("userId", userId)
        .set("oldPassword", oldPassword),
    });
  }

  addApplicationUser(
    userRegistration: UserRegistration
  ): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(this.API_URL, userRegistration);
  }

  updateApplicationUser(
    userRegistration: UserRegistration
  ): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/` + userRegistration.id,
      userRegistration
    );
  }

  resetApplicationUser(user: ApplicationUserReset): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/resetApplicationUser/` + user.id,
      user
    );
  }

  changeApplicationUser(user: ApplicationUserChange): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/changeApplicationUser/` + user.id,
      user
    );
  }

  deleteApplicationUser(id: string): Observable<User> {
    return this.httpClient.delete<User>(`${this.API_URL}/` + id);
  }
}
