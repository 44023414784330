import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { Costing } from "../models/costing.model";
import { CostingService } from "../services/costing.service";

@Injectable()
export class CostingAllResolver implements Resolve<Costing[]> {
  constructor(private service: CostingService) {}

  resolve(): Observable<Costing[]> {
    return this.service.getAllCostings();
  }
}
