import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { TaxService } from "../services/tax.service";
import { Tax } from "../models/tax";

@Injectable()
export class TaxResolver implements Resolve<Tax> {
  constructor(private service: TaxService) {}

  resolve(): Observable<Tax> {
    return this.service.getActiveTax();
  }
}
