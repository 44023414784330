import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Costing } from "src/app/models/costing.model";
import { CustomResult } from "src/app/models/custom-model/customResult.model";
import { Receiving } from "src/app/models/receiving";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class ReceivingService extends EntityService<Costing> {
  private readonly API_URL = "/api/receivings";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/receivings");
  }

  getAlReceivings(): Observable<Receiving[]> {
    return this.httpClient.get<Receiving[]>(`${this.API_URL}`);
  }

  completeReceiving(receivingId: number): Observable<CustomResult> {
    return this.httpClient.get<CustomResult>(
      `${this.API_URL}/CompleteReceiving/` + receivingId
    );
  }

  getReceivingById(id: number): Observable<Receiving> {
    return this.httpClient.get<Receiving>(`${this.API_URL}/` + id);
  }

  getReceivingByCostingId(costingId: number): Observable<Receiving> {
    return this.httpClient.get<Receiving>(
      `${this.API_URL}/GetReceivingByCostingId/` + costingId
    );
  }

  receivingSearchByBusiness(
    term: string,
    businessTypeId: number
  ): Observable<Receiving[]> {
    return this.httpClient.get<Receiving[]>(
      `${this.API_URL}/ReceivingSearchByBusiness?term=` +
        term +
        `&businessTypeId=` +
        businessTypeId
    );
  }

  addReceiving(receiving: Receiving): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(this.API_URL, receiving);
  }

  updateReceiving(receiving: Receiving): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/` + receiving.receivingId,
      receiving
    );
  }

  deleteReceiving(receivingId: number): Observable<Receiving> {
    return this.httpClient.delete<Receiving>(`${this.API_URL}/` + receivingId);
  }
}
