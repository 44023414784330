import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CustomerType } from "src/app/models/customer";
import { fadeInOut } from "src/app/services/animations";
import { Sales } from "../../../../models/sales";
import { PrintLayoutService } from "src/app/components/print-layout/print-layout.service";
import { Tax } from "src/app/models/tax";
import { EntityDataSourceCustomBindReport } from "src/app/services/entity.datasource";
import { SalesLineService } from "src/app/services/salesLine.service";
import { roundTo } from "round-to";

@Component({
  selector: "app-job-usd-print",
  templateUrl: "./job-usd-print.component.html",
  styleUrls: ["./job-usd-print.component.scss"],
  animations: [fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobUSDPrintComponent implements OnInit {
  displayedColumnsSOLine: string[] = [
    "itemCode",
    "description",
    "uom",
    "rate",
    "qty",
    "discount",
    "amount",
  ];

  id: number;
  sales: Sales;
  tax: Tax;
  dataSourceSOLine: any[] = [];
  dataSource: EntityDataSourceCustomBindReport;
  readonly endpointUrl = "/api/salesLines/GetSalesLineView";
  lineCount: number;
  exchangeRate: number = 0;
  todayDate: Date = new Date();

  constructor(
    private printLayoutService: PrintLayoutService,
    private salesLineService: SalesLineService,
    private route: ActivatedRoute
  ) {
    this.id = Number(this.route.snapshot.paramMap.get("id"));

    this.route.data.subscribe(({ sales }) => {
      if (sales) {
        this.sales = sales;
      }
    });

    this.route.data.subscribe(({ currency }) => {
      if (currency) {
        this.exchangeRate = currency.exchangeRate;
      }
    });

    this.route.data.subscribe(({ tax }) => {
      if (tax) {
        this.tax = tax;
      }
    });
  }

  ngOnInit() {
    this.dataSource = new EntityDataSourceCustomBindReport(
      this.salesLineService,
      this.endpointUrl
    );

    this.dataSource.loadReport(this.id, this.displayedColumnsSOLine);
    this.dataSource.entityCount$.subscribe((c) => (this.lineCount = c));
    this.dataSource.currentData$.subscribe((rows) => {
      this.dataSourceSOLine = rows;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.printLayoutService.onDataReady();
    }, 1000);
  }

  getCustomerType(value: string | number) {
    return CustomerType[value];
  }

  financialFormat(x: number) {
    x = x / this.exchangeRate;
    return roundTo(x, 2);
  }
}
