import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { CostingLine } from "../models/costingLine";
import { CostingLineService } from "../services/costingLine.service";

@Injectable()
export class CostingLineResolver implements Resolve<CostingLine[]> {
  constructor(private service: CostingLineService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<CostingLine[]> {
    const id = Number(route.paramMap.get("id"));
    if (id != 0) {
      return this.service.getCostingLineByCostingId(+id);
    } else {
      return null;
    }
  }
}
