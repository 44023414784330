import { Injectable } from "@angular/core";
import { EntityService } from "./entity.service";
import { Customer, CustomerAssetView, CustomerType } from "../models/customer";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CustomResult } from "../models/custom-model/customResult.model";

@Injectable({
  providedIn: "root",
})
export class CustomerService extends EntityService<Customer> {
  private readonly API_URL = "/api/customers";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/customers");
  }

  getCustomers(): Observable<Customer[]> {
    return this.httpClient.get<Customer[]>(`${this.API_URL}`);
  }

  getCustomersByType(customerType: CustomerType): Observable<Customer[]> {
    return this.httpClient.get<Customer[]>(
      `${this.API_URL}/GetCustomersByType?customerType=` +
        customerType.toString()
    );
  }

  getDefaultCustomers(): Observable<Customer> {
    return this.httpClient.get<Customer>(`${this.API_URL}/GetDefaultCustomers`);
  }

  getCustomer(id: number): Observable<Customer> {
    return this.httpClient.get<Customer>(`${this.API_URL}/` + id);
  }

  customerSearch(term: string): Observable<Customer[]> {
    return this.httpClient.get<Customer[]>(
      `${this.API_URL}/SearchCustomer?term=` + term
    );
  }

  customerAssetSearch(term: string): Observable<CustomerAssetView[]> {
    return this.httpClient.get<CustomerAssetView[]>(
      `${this.API_URL}/SearchCustomerWithAsset?term=` + term
    );
  }

  getCustomerByIdentificationNumber(nic: string): Observable<Customer> {
    return this.httpClient.get<Customer>(
      `${this.API_URL}/GetCustomerByIdentificationNumber?nic=` + nic
    );
  }

  addCustomer(customer: Customer): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(this.API_URL, customer);
  }

  updateCustomer(customer: Customer): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/` + customer.customerId,
      customer
    );
  }

  deleteCustomer(customerId: number): Observable<Customer> {
    return this.httpClient.delete<Customer>(`${this.API_URL}/` + customerId);
  }

  download(customerType): Observable<Blob> {
    return this.httpClient.post<Blob>(
      `${this.API_URL}/ExportUploadTemplate?customerType=` + customerType,
      customerType,
      { responseType: "blob" as "json" }
    );
  }
}
