import { BusinessType } from "./businessType";
import { AuditableEntity } from "./custom-model/auditableEntity";
import { Invoice } from "./invoice";
import { Location } from "./location";
import { Payment } from "./payment";
import { Sales } from "./sales";

export class Denomination extends AuditableEntity {
  denominationId: number;
  denominationDate: string;

  businessTypeId: number;
  businessType: BusinessType;

  locationId: number;
  location: Location;

  note1000: number;
  note500: number;
  note100: number;
  note50: number;
  note20: number;
  note10: number;
  note5: number;
  note2: number;
  note1: number;
  noteCoins: number;

  cash_Total: number;
  cheque_Total: number;
  bankCard_Total: number;
  bankTransfer_Total: number;
  deposit_Total: number;
  promotion_Total: number;
  donation_Total: number;
  salaryDedcution_Total: number;
  interCompany_Total: number;
  settlement_Total: number;
  waveOff_Total: number;

  excessOrShort: number;
  remarks: string;
}

export class DenominationPayments {
  denomination: Denomination;
  payment: Payment[];
  invoice: Invoice[];
  sales: Sales[];
}
