import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CustomResult } from "src/app/models/custom-model/customResult.model";
import { Product, ProductExtended } from "src/app/models/product";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class ProductService extends EntityService<Product> {
  private readonly API_URL = "/api/products";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/products");
  }

  getGeneratedItemCode(businessTypeId: number) {
    return this.httpClient.get(
      `${this.API_URL}/GetItemCode/` + businessTypeId,
      {
        responseType: "text",
      }
    );
  }

  getProductByEngineNumber(name: string): Observable<Product> {
    return this.httpClient.get<Product>(
      `${this.API_URL}/GetProductByEngineNumber?name=` + name
    );
  }

  //Start New Functions
  searchBusiness_Product(
    term: string,
    businessTypeId: number,
    locationId: number
  ): Observable<ProductExtended[]> {
    return this.httpClient.get<ProductExtended[]>(
      `${this.API_URL}/SearchBusiness_Product?term=` +
        term +
        `&businessTypeId=` +
        businessTypeId +
        `&locationId=` +
        locationId
    );
  }

  searchBusinessLocation_Product(
    term: string,
    businessTypeId: number,
    locationId: number
  ): Observable<ProductExtended[]> {
    return this.httpClient.get<ProductExtended[]>(
      `${this.API_URL}/SearchBusinessLocation_Product?term=` +
        term +
        `&businessTypeId=` +
        businessTypeId +
        `&locationId=` +
        locationId
    );
  }
  //End New Functions

  searchBusinessLocation_AllProduct(
    term: string,
    businessTypeId: number,
    locationId: number
  ): Observable<ProductExtended[]> {
    return this.httpClient.get<ProductExtended[]>(
      `${this.API_URL}/SearchBusinessLocation_AllProduct?term=` +
        term +
        `&businessTypeId=` +
        businessTypeId +
        `&locationId=` +
        locationId
    );
  }

  searchBusiness_AllProduct(
    term: string,
    businessTypeId: number,
    locationId: number
  ): Observable<ProductExtended[]> {
    return this.httpClient.get<ProductExtended[]>(
      `${this.API_URL}/SearchBusiness_AllProduct?term=` +
        term +
        `&businessTypeId=` +
        businessTypeId +
        `&locationId=` +
        locationId
    );
  }

  searchAllProduct(
    term: string,
    businessTypeId: number
  ): Observable<ProductExtended[]> {
    return this.httpClient.get<ProductExtended[]>(
      `${this.API_URL}/SearchAllProduct?term=` +
        term +
        `&businessTypeId=` +
        businessTypeId
    );
  }

  productMasterSearch(
    term: string,
    businessTypeId: number
  ): Observable<Product[]> {
    return this.httpClient.get<Product[]>(
      `${this.API_URL}/ProductMasterSearch?term=` +
        term +
        `&businessTypeId=` +
        businessTypeId
    );
  }

  productSearch(
    term: string,
    businessTypeId: number
  ): Observable<ProductExtended[]> {
    return this.httpClient.get<ProductExtended[]>(
      `${this.API_URL}/SearchProduct?term=` +
        term +
        `&businessTypeId=` +
        businessTypeId
    );
  }

  searchSoldProduct(term: string): Observable<Product[]> {
    return this.httpClient.get<Product[]>(
      `${this.API_URL}/SearchSoldProduct?term=` + term
    );
  }

  loadProductImage(productId: number): Observable<CustomResult> {
    return this.httpClient.get<CustomResult>(
      `${this.API_URL}/LoadProductImage?productId=` + productId
    );
  }

  deleteProductImage(id: number): Observable<CustomResult> {
    return this.httpClient.delete<CustomResult>(
      `${this.API_URL}/DeleteProductImage/` + id
    );
  }

  getProductByFrameNumber(name: string): Observable<Product> {
    return this.httpClient.get<Product>(
      `${this.API_URL}/GetProductByFrameNumber?name=` + name
    );
  }

  getProductByProductCode(name: string): Observable<Product> {
    return this.httpClient.get<Product>(
      `${this.API_URL}/GetProductByProductCode?name=` + name
    );
  }

  getAllProducts(): Observable<Product[]> {
    return this.httpClient.get<Product[]>(`${this.API_URL}`);
  }

  addProduct(product: Product): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(this.API_URL, product);
  }

  updateProduct(product: Product): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/` + product.productId,
      product
    );
  }

  deleteProduct(id: number): Observable<Product> {
    return this.httpClient.delete<Product>(`${this.API_URL}/` + id);
  }

  getProductById(id: number): Observable<Product> {
    return this.httpClient.get<Product>(`${this.API_URL}/` + id);
  }

  getProductCrossCheck(productId: number): Observable<CustomResult> {
    return this.httpClient.get<CustomResult>(
      `${this.API_URL}/GetProductCrossCheck?productId=` + productId
    );
  }

  productsBulkUploadTemplate(
    bussinessType: string,
    productType: string
  ): Observable<Blob> {
    return this.httpClient.post<Blob>(
      `${this.API_URL}/ProductsBulkUploadTemplate?bussinessType=` +
        bussinessType +
        `&productType=` +
        productType,
      bussinessType,
      { responseType: "blob" as "json" }
    );
  }

  productsVerifyUploadTemplate(bussinessType): Observable<Blob> {
    return this.httpClient.post<Blob>(
      `${this.API_URL}/ProductsVerifyUploadTemplate?bussinessType=` +
        bussinessType,
      bussinessType,
      { responseType: "blob" as "json" }
    );
  }

  productPriceUploadTemplate(bussinessType): Observable<Blob> {
    return this.httpClient.post<Blob>(
      `${this.API_URL}/ProductPriceUploadTemplate?bussinessType=` +
        bussinessType,
      bussinessType,
      { responseType: "blob" as "json" }
    );
  }

  bundleProductUploadTemplate(bussinessType): Observable<Blob> {
    return this.httpClient.post<Blob>(
      `${this.API_URL}/BundleProductUploadTemplate?bussinessType=` +
        bussinessType,
      bussinessType,
      { responseType: "blob" as "json" }
    );
  }

  productCategoryUploadTemplate(bussinessType: string): Observable<Blob> {
    return this.httpClient.post<Blob>(
      `${this.API_URL}/ProductCategoryUploadTemplate?bussinessType=` +
        bussinessType,
      bussinessType,
      { responseType: "blob" as "json" }
    );
  }

  productStorageUploadTemplate(bussinessType: string): Observable<Blob> {
    return this.httpClient.post<Blob>(
      `${this.API_URL}/ProductStorageUploadTemplate?bussinessType=` +
        bussinessType,
      bussinessType,
      { responseType: "blob" as "json" }
    );
  }
}
