import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { Referral } from "../models/referral";
import { ReferralService } from "../services/referral.service";

@Injectable()
export class ReferralResolver implements Resolve<Referral[]> {
  constructor(private service: ReferralService) {}

  resolve(): Observable<Referral[]> {
    return this.service.getAllReferrals();
  }
}
