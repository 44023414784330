import { Injectable } from "@angular/core";
import { EntityService } from "./entity.service";
import { Invoice } from "../models/invoice";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { CustomResult } from "../models/custom-model/customResult.model";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class InvoiceService extends EntityService<Invoice> {
  private readonly API_URL = "/api/invoices";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/invoices");
  }

  getInvoicedById(invoiceId: number): Observable<Invoice> {
    return this.httpClient.get<Invoice>(`${this.API_URL}/` + invoiceId);
  }

  getInvoiceByCustomerId(customerId: number): Observable<Invoice[]> {
    return this.httpClient.get<Invoice[]>(
      `${this.API_URL}/GetInvoiceByCustomerId/` + customerId
    );
  }

  getAllInvoices(): Observable<Invoice[]> {
    return this.httpClient.get<Invoice[]>(`${this.API_URL}`);
  }

  addInvoice(invoice: Invoice): Observable<Invoice> {
    let header = new HttpHeaders({ "content-type": "application/json" });
    return this.httpClient.post<Invoice>(this.API_URL, invoice, {
      headers: header,
    });
  }

  updateInvoice(invoice: Invoice): Observable<Invoice> {
    return this.httpClient.put<Invoice>(
      `${this.API_URL}/` + invoice.invoiceId,
      invoice
    );
  }

  invoiceSearchBusinessAndLocationWarehouse(
    term: string,
    businessTypeId: number,
    locationId: number
  ): Observable<Invoice[]> {
    return this.httpClient.get<Invoice[]>(
      `${this.API_URL}/InvoiceSearchBusinessAndLocationWarehouse?term=` +
        term +
        `&businessTypeId=` +
        businessTypeId +
        `&locationId=` +
        locationId
    );
  }

  searchInvoice(
    term: string,
    businessTypeId: number,
    locationId: number
  ): Observable<Invoice[]> {
    return this.httpClient.get<Invoice[]>(
      `${this.API_URL}/SearchInvoice?term=` +
        term +
        `&businessTypeId=` +
        businessTypeId +
        `&locationId=` +
        locationId
    );
  }

  voidInvoice(invoiceId: number, remark: string): Observable<CustomResult> {
    return this.httpClient.get<CustomResult>(
      `${this.API_URL}/VoidInvoice?invoiceId=` + invoiceId + `&remark=` + remark
    );
  }

  salesReturnByInvoice(
    invoiceId: number,
    remark: string,
    cancelReturnBy: string
  ): Observable<CustomResult> {
    return this.httpClient.get<CustomResult>(
      `${this.API_URL}/SalesReturnByInvoice?invoiceId=` +
        invoiceId +
        `&remark=` +
        remark +
        `&cancelReturnBy=` +
        cancelReturnBy
    );
  }

  deleteInvoice(invoiceId: number): Observable<Invoice> {
    return this.httpClient.delete<Invoice>(`${this.API_URL}/` + invoiceId);
  }

  settleZeroInvoices(invoice: Invoice): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/SettleZeroInvoices/` + invoice.invoiceId,
      invoice
    );
  }
}
