import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  StockAdjustmentLine,
  StockAdjustmentLineStorage,
} from "src/app/models/stockAdjustmentLine";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class StockAdjustmentLineService extends EntityService<StockAdjustmentLine> {
  private readonly API_URL = "/api/StockAdjustmentLines";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/StockAdjustmentLines");
  }

  getAllStockAdjustmentLines(): Observable<StockAdjustmentLine[]> {
    return this.httpClient.get<StockAdjustmentLine[]>(`${this.API_URL}`);
  }

  addStockAdjustmentLine(
    stockAdjustmentLine: StockAdjustmentLineStorage
  ): Observable<StockAdjustmentLineStorage> {
    return this.httpClient.post<StockAdjustmentLineStorage>(
      this.API_URL,
      stockAdjustmentLine
    );
  }

  updateStockAdjustmentLine(
    stockAdjustmentLine: StockAdjustmentLineStorage
  ): Observable<StockAdjustmentLineStorage> {
    return this.httpClient.put<StockAdjustmentLineStorage>(
      `${this.API_URL}/` + stockAdjustmentLine.stockAdjustmentLineId,
      stockAdjustmentLine
    );
  }

  deleteStockAdjustment(
    stockAdjustmentLineId: number
  ): Observable<StockAdjustmentLine> {
    return this.httpClient.delete<StockAdjustmentLine>(
      `${this.API_URL}/` + stockAdjustmentLineId
    );
  }

  download(bussinessId: number): Observable<Blob> {
    return this.httpClient.post<Blob>(
      `${this.API_URL}/ExportUploadTemplate?bussinessId=` + bussinessId,
      bussinessId,
      { responseType: "blob" as "json" }
    );
  }
}
