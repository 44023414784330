import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";

export interface CanComponentLeave {
  canLeave: () => boolean;
}

@Injectable({
  providedIn: "root",
})
export class PageUnloadAlertGuard implements CanDeactivate<CanComponentLeave> {
  canDeactivate(component: CanComponentLeave) {
    if (component.canLeave) {
      return component.canLeave();
    }
    return true;
  }
}
