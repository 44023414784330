import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EntityService } from "./entity.service";
import { Observable } from "rxjs";
import { Tax } from "../models/tax";

@Injectable({
  providedIn: "root",
})
export class TaxService extends EntityService<Tax> {
  private readonly API_URL = "/api/Taxes";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/Taxes");
  }

  getAlTaxs(): Observable<Tax[]> {
    return this.httpClient.get<Tax[]>(`${this.API_URL}`);
  }

  getActiveTax(): Observable<Tax> {
    return this.httpClient.get<Tax>(`${this.API_URL}/GetActiveTax`);
  }

  updateSatusOfTax(TaxId): Observable<any> {
    return this.httpClient.get<any>(
      `${this.API_URL}/UpdateSatusOfTax?id=` + TaxId
    );
  }

  addTax(tax: Tax): Observable<Tax> {
    return this.httpClient.post<Tax>(this.API_URL, tax);
  }

  updateTax(tax: Tax): Observable<Tax> {
    return this.httpClient.put<Tax>(`${this.API_URL}/` + tax.taxId, tax);
  }

  deleteTax(taxId: number): Observable<Tax> {
    return this.httpClient.delete<Tax>(`${this.API_URL}/` + taxId);
  }
}
