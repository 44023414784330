import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { EntityService } from "./entity.service";
import {
  ApplicationUserLocation,
  ApplicationUserLocationEnroll,
} from "../core/models/applicationUserLocation.model";
import { CustomResult } from "../models/custom-model/customResult.model";

@Injectable({
  providedIn: "root",
})
export class ApplicationUserLocationsService extends EntityService<ApplicationUserLocation> {
  private readonly API_URL = "/api/applicationUserLocations";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/applicationUserBusinesses");
  }

  get applicationUserBusinessCount(): number {
    return this.applicationUserBusinessCount;
  }

  getApplicationUserLocations(): Observable<ApplicationUserLocation[]> {
    return this.httpClient.get<ApplicationUserLocation[]>(`${this.API_URL}`);
  }

  getApplicationUserLocationByUserId(
    userId: string
  ): Observable<ApplicationUserLocation[]> {
    return this.httpClient.get<ApplicationUserLocation[]>(
      `${this.API_URL}/GetApplicationUserLocationByUserId?userId=` + userId
    );
  }

  getUserLocationsByBusinessAndLocationAndUser(
    userId: string,
    businessTypeId: string,
    locationId: number
  ): Observable<boolean> {
    return this.httpClient.get<boolean>(
      `${this.API_URL}/GetUserLocationsByBusinessAndLocationAndUser?userId=` +
        userId +
        `&businessTypeId=` +
        businessTypeId +
        `&locationId=` +
        locationId
    );
  }

  addApplicationUserLocations(
    userLocationEnroll: ApplicationUserLocationEnroll
  ): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(this.API_URL, userLocationEnroll);
  }

  updateApplicationUserLocations(
    userLocationEnroll: ApplicationUserLocationEnroll
  ): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/` + userLocationEnroll.applicationUserLocationId,
      userLocationEnroll
    );
  }

  deleteApplicationUserBusiness(
    applicationUserLocationId: number
  ): Observable<ApplicationUserLocation> {
    return this.httpClient.delete<ApplicationUserLocation>(
      `${this.API_URL}/` + applicationUserLocationId
    );
  }
}
