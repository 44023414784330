import { Injectable } from "@angular/core";
import { EntityService } from "./entity.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApprovalPolicy, ApprovalScreenType } from "../models/approvalPolicy";

@Injectable({
  providedIn: "root",
})
export class ApprovalPolicyService extends EntityService<ApprovalPolicy> {
  private readonly API_URL = "/api/approvalPolicies";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/approvalPolicies");
  }

  getAllApprovalPolicy(): Observable<ApprovalPolicy[]> {
    return this.httpClient.get<ApprovalPolicy[]>(`${this.API_URL}`);
  }

  getApprovalPolicyByBusinessAndScreen(
    businessTypeId: number,
    approvalScreenType: ApprovalScreenType
  ): Observable<ApprovalPolicy> {
    return this.httpClient.get<ApprovalPolicy>(
      `${this.API_URL}/getApprovalPolicyByBusinessAndScreen?businessTypeId=` +
        businessTypeId +
        `&approvalScreenType=` +
        approvalScreenType
    );
  }

  addApprovalPolicy(
    approvalPolicy: ApprovalPolicy
  ): Observable<ApprovalPolicy> {
    return this.httpClient.post<ApprovalPolicy>(this.API_URL, approvalPolicy);
  }

  updateApprovalPolicy(
    approvalPolicy: ApprovalPolicy
  ): Observable<ApprovalPolicy> {
    return this.httpClient.put<ApprovalPolicy>(
      `${this.API_URL}/` + approvalPolicy.approvalPolicyId,
      approvalPolicy
    );
  }

  deleteApprovalPolicy(approvalPolicyId: number): Observable<ApprovalPolicy> {
    return this.httpClient.delete<ApprovalPolicy>(
      `${this.API_URL}/` + approvalPolicyId
    );
  }
}
