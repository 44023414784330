import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CustomResult } from "src/app/models/custom-model/customResult.model";
import { Shipment, ShipmentApproval } from "src/app/models/shipment";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class ShipmentService extends EntityService<Shipment> {
  private readonly API_URL = "/api/shipments";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/shipments");
  }

  getAllShipments(): Observable<Shipment[]> {
    return this.httpClient.get<Shipment[]>(`${this.API_URL}`);
  }

  getOpenShipments(businessTypeId: number): Observable<Shipment[]> {
    return this.httpClient.get<Shipment[]>(
      `${this.API_URL}/GetOpenShipments?businessTypeId=` + businessTypeId
    );
  }

  getShipmentById(id: number): Observable<Shipment> {
    return this.httpClient.get<Shipment>(`${this.API_URL}/` + id);
  }

  shipmentSearchByBusiness(
    term: string,
    businessTypeId: number
  ): Observable<Shipment[]> {
    return this.httpClient.get<Shipment[]>(
      `${this.API_URL}/ShipmentSearchByBusiness?term=` +
        term +
        `&businessTypeId=` +
        businessTypeId
    );
  }

  getShipmentStatusWithShipmentLine(shipmentId: number): Observable<boolean> {
    const endpointUrl = `${this.API_URL}/GetShipmentStatusWithShipmentLine`;
    return this.httpClient.get<boolean>(endpointUrl, {
      params: new HttpParams().set("shipmentId", shipmentId.toString()),
    });
  }

  getShipmentByContainer(name: string): Observable<Shipment> {
    const endpointUrl = `${this.API_URL}/GetShipmentByContainer`;
    return this.httpClient.get<Shipment>(endpointUrl, {
      params: new HttpParams().set("name", name),
    });
  }

  getShipmentsByStatus(shipmentStatus: number): Observable<Shipment[]> {
    const endpointUrl = `${this.API_URL}/GetShipmentByStatus`;
    return this.httpClient.get<Shipment[]>(endpointUrl, {
      params: new HttpParams().set("shipmentStatus", shipmentStatus.toString()),
    });
  }

  getShipmentsByBusinessType(businessTypeId: number): Observable<Shipment[]> {
    const endpointUrl = `${this.API_URL}/GetShipmentsByBusinessType`;
    return this.httpClient.get<Shipment[]>(endpointUrl, {
      params: new HttpParams().set("businessTypeId", businessTypeId.toString()),
    });
  }

  addShipment(shipment: Shipment): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(this.API_URL, shipment);
  }

  updateShipment(shipment: Shipment): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/` + shipment.shipmentId,
      shipment
    );
  }

  deleteShipment(shipmentId: number): Observable<Shipment> {
    return this.httpClient.delete<Shipment>(`${this.API_URL}/` + shipmentId);
  }

  requestShipmentApproval(
    shipmentApproval: ShipmentApproval
  ): Observable<ShipmentApproval> {
    return this.httpClient.put<ShipmentApproval>(
      `${this.API_URL}/requestShipmentApproval/` + shipmentApproval.shipmentId,
      shipmentApproval
    );
  }
}
