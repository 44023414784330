import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Category } from "src/app/models/category";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class CategoryService extends EntityService<Category> {
  private readonly API_URL = "/api/categories";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/categories");
  }

  getAllCategories(): Observable<Category[]> {
    return this.httpClient.get<Category[]>(`${this.API_URL}`);
  }

  getCategoryByName(
    name: string,
    businessTypeId: number
  ): Observable<Category[]> {
    return this.httpClient.get<Category[]>(
      `${this.API_URL}/GetCategoryByName?term=` +
        name +
        `&businessTypeId=` +
        businessTypeId
    );
  }

  getCategoryByBusinessTypeId(businessTypeId: number): Observable<Category[]> {
    return this.httpClient.get<Category[]>(
      `${this.API_URL}/GetCategoryByBusinessTypeId?businessTypeId=` +
        businessTypeId
    );
  }

  addCategory(category: Category): Observable<Category> {
    return this.httpClient.post<Category>(this.API_URL, category);
  }

  updateCategory(category: Category): Observable<Category> {
    return this.httpClient.put<Category>(
      `${this.API_URL}/` + category.categoryId,
      category
    );
  }

  deleteCategory(categoryId: number): Observable<Category> {
    return this.httpClient.delete<Category>(`${this.API_URL}/` + categoryId);
  }

  categoryUploadTemplate(docName: string): Observable<Blob> {
    return this.httpClient.post<Blob>(
      `${this.API_URL}/CategoryUploadTemplate?docName=` + docName,
      docName,
      { responseType: "blob" as "json" }
    );
  }
}
