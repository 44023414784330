import { AuditableEntity } from "./custom-model/auditableEntity";
import { BusinessType } from "./businessType";
import { DeliveryOrderLine } from "./deliveryOrderLine";
import { Invoice } from "./invoice";
import { Location } from "./location";

export class DeliveryOrder extends AuditableEntity {
  deliveryOrderId: number;
  deliveryOrderNumber: string;
  deliveryOrderDate: string;
  businessTypeId: number;
  businessType: BusinessType;
  locationId: number;
  location: Location;
  invoiceId: number;
  invoice: Invoice;
  contactPersonName: string;
  contactPersonNumber: string;
  deliveryAddress: string;
  deliveryOrderStatus: DeliveryOrderStatus;
  deliveryOrderType: DeliveryOrderType;
  deliveryOrderLines: DeliveryOrderLine[];
}

export enum DeliveryType {
  DeliverNow = 1,
  DeliverLater = 2,
}

export enum DeliveryOrderStatus {
  Pending = 1,
  Delivered = 2,
}

export enum DeliveryOrderType {
  InvoiceRelease = 1,
  StorePickUp = 2,
  CustomerPickUp = 3,
  DoorStepDelivery = 4,
}
