import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { LoaderService } from "src/app/core/services/loader.service";

@Injectable({
  providedIn: "root",
})
export class CancelInterceptorService implements HttpInterceptor {
  private activeCalls: Map<string, Subject<any>> = new Map();
  private cache = new Map<string, Subject<void>>();

  constructor(private loaderService: LoaderService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loaderService.setHttpProgressStatus(true);

    if (this.activeCalls.has(request.url)) {
      const subject = this.activeCalls.get(request.url);
      return subject.asObservable();
    }
    this.activeCalls.set(request.url, new Subject<any>());

    return next.handle(request).pipe(
      filter((res) => res.type === HttpEventType.Response),
      tap((res) => {
        const subject = this.activeCalls.get(request.url);
        subject.next(res);
        subject.complete();
        this.activeCalls.delete(request.url);
        this.loaderService.setHttpProgressStatus(false);
      })
    );
  }

  // intercept(
  //   request: HttpRequest<unknown>,
  //   next: HttpHandler
  // ): Observable<HttpEvent<unknown>> {
  //   this.loaderService.setHttpProgressStatus(true);

  //   // Only cancel GET requests
  //   if (request.method !== "GET") {
  //     return next.handle(request);
  //   }

  //   // if you want to check params as well then use request.urlWithParams.
  //   const url = request.url;

  //   // check if the request is already cached
  //   const cachedResponse = this.cache.get(url);

  //   // cancel any previous requests
  //   if (cachedResponse) {
  //     cachedResponse.next();
  //   }

  //   const cancelRequests$ = new Subject<void>();

  //   // cache the new request , so that we can cancel it if needed.
  //   this.cache.set(url, cancelRequests$);

  //   const newRequest = next.handle(request).pipe(
  //     // cancel the request if a same request comes in.
  //     takeUntil(cancelRequests$),
  //     finalize(() => {
  //       this.loaderService.setHttpProgressStatus(true);
  //     }),
  //     // complete the subject when the request completes.
  //     tap((event) => {
  //       if (event instanceof HttpResponse) {
  //         this.cache.delete(url);
  //       }
  //     })
  //   );

  //   return newRequest;
  // }
}
