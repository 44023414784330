import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Vendor } from "src/app/models/vendor";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class VendorService extends EntityService<Vendor> {
  private readonly API_URL = "/api/vendors";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/vendors");
  }

  getAllVendors(): Observable<Vendor[]> {
    return this.httpClient.get<Vendor[]>(`${this.API_URL}`);
  }

  getVendorByName(name: string, countryId: string): Observable<Vendor> {
    const endpointUrl = `${this.API_URL}/GetVendorByName`;
    return this.httpClient.get<Vendor>(endpointUrl, {
      params: new HttpParams().set("name", name).set("countryId", countryId),
    });
  }

  addVendor(vendor: Vendor): Observable<Vendor> {
    return this.httpClient.post<Vendor>(this.API_URL, vendor);
  }

  updateVendor(vendor: Vendor): Observable<Vendor> {
    return this.httpClient.put<Vendor>(
      `${this.API_URL}/` + vendor.vendorId,
      vendor
    );
  }

  deleteVendor(vendorId: number): Observable<Vendor> {
    return this.httpClient.delete<Vendor>(`${this.API_URL}/` + vendorId);
  }
}
