import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EntityService } from "./entity.service";
import { Observable } from "rxjs";
import { Currency } from "../models/currency";

@Injectable({
  providedIn: "root",
})
export class CurrencyService extends EntityService<Currency> {
  private readonly API_URL = "/api/currencies";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/currencies");
  }

  getActiveCurrency(): Observable<Currency> {
    return this.httpClient.get<Currency>(`${this.API_URL}/GetActiveCurrency`);
  }

  getAllCurrencies(): Observable<Currency[]> {
    return this.httpClient.get<Currency[]>(`${this.API_URL}`);
  }

  updateSatusOfCurrency(TaxId): Observable<any> {
    return this.httpClient.get<any>(
      `${this.API_URL}/UpdateSatusOfCurrency?id=` + TaxId
    );
  }

  addCurrency(currency: Currency): Observable<Currency> {
    return this.httpClient.post<Currency>(this.API_URL, currency);
  }

  updateCurrency(currency: Currency): Observable<Currency> {
    return this.httpClient.put<Currency>(
      `${this.API_URL}/` + currency.currencyId,
      currency
    );
  }

  deleteCurrency(currencyId: number): Observable<Currency> {
    return this.httpClient.delete<Currency>(`${this.API_URL}/` + currencyId);
  }
}
