import { UnitOfMeasureResolver } from "./resolver/unitOfMeasure.resolver";
import { BrandResolver } from "./resolver/brand.resolver";
import { CommonModule, CurrencyPipe, DatePipe } from "@angular/common";
import {
  HTTP_INTERCEPTORS,
  HttpClientJsonpModule,
  HttpClientModule,
} from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgSelectModule } from "@ng-select/ng-select";
import { NguCarouselModule } from "@ngu/carousel";
import { ngfModule } from "angular-file";
import { OAuthModule } from "angular-oauth2-oidc";
import { MatTableExporterModule } from "mat-table-exporter";
import { ChartsModule, ThemeService } from "ng2-charts";
import {
  DropzoneConfigInterface,
  DropzoneModule,
  DROPZONE_CONFIG,
} from "ngx-dropzone-wrapper";
import { NgxInterceptorModule } from "ngx-interceptor";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { ToastaModule } from "ngx-toasta";
import { StaffDiscountExemptionService } from "src/app/services/staffDiscountExemption.service";
import { LoginComponent } from "./components/account/login/login.component";
import { UnautorizedComponent } from "./components/account/unautorized/unautorized.component";
import { AppErrorHandler } from "./app-error.handler";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ImageViewerComponent } from "./components/customized-componets/image-viewer/image-viewer.component";
import { CancelReturnRemarksDialogComponent } from "./components/shared/cancel-return-remarks-dialog/cancel-return-remarks-dialog.component";
import { PageUnloadAlertGuard } from "./guards/page-unload-alert.guard";
import { ApprovalPolicyResolver } from "./resolver/approvalPolicy.resolver";
import { CurrencyResolver } from "./resolver/currency.resolver";
import { DenominationResolver } from "./resolver/denomination.resolver";
import { DepositAccountResolver } from "./resolver/depositAccount.resolver";
import { DiscountEligibilityResolver } from "./resolver/discountEligibility.resolver";
import { InvoiceResolver } from "./resolver/invoice.resolver";
import { PaymentTermResolver } from "./resolver/paymentTerm.resolver";
import { ProductResolver } from "./resolver/product.resolver";
import { ReceiptResolver } from "./resolver/receipt.resolver";
import { SalesResolver } from "./resolver/sales.resolver";
import { ShipmentResolver } from "./resolver/shipment.resolver";
import { SpecialExemptionResolver } from "./resolver/specialExemption.resolver";
import { StaffDiscountExemptionResolver } from "./resolver/staffDiscountExemption.resolver";
import { StockAdjustmentResolver } from "./resolver/stockAdjustment.resolver";
import { TaxResolver } from "./resolver/tax.resolver";
import { TaxExemptionResolver } from "./resolver/taxExemption.resolver";
import { TechnicianResolver } from "./resolver/technician.resolver";
import { TransferOrderResolver } from "./resolver/transferOrder.resolver";
import { AppTitleService } from "./services/app-title.service";
import { ApplicationUserBusinessService } from "./services/applicationuserbusiness.service";
import { ApplicationUserLocationsService } from "./services/applicationuserlocations.service";
import { ApprovalPolicyService } from "./services/approvalPolicy.service";
import { ApprovalService } from "./services/approvals.service";
import { AssetService } from "./services/asset.service";
import { BusinessTypeService } from "./services/business-type.service";
import { CountryService } from "./services/country.service";
import { CurrencyService } from "./services/currency.service";
import { DepartmentService } from "./services/department.service";
import { DepositAccountService } from "./services/depositAccount.service";
import { DiscountService } from "./services/discount.service";
import { LitusEntityService } from "./services/litusEntity.service";
import { LocationService } from "./services/location.service";
import { PaymentTermService } from "./services/paymentTerm.service";
import { ReferralService } from "./services/referral.service";
import { SpecialExemptionService } from "./services/specialExemption.service";
import { TaxService } from "./services/tax.service";
import { TaxExemptionService } from "./services/taxExemption.service";
import { TechnicianService } from "./services/technician.service";
import { TransferOrderService } from "./services/transferOrder.service";
import { UploadService } from "./services/upload.service";
import { FooterModule } from "./components/shared/footer/footer.component";
import { SharedModule } from "./components/shared/shared.module";
import { ReceivingResolver } from "./resolver/receiving.resolver";
import { ReceivingLineResolver } from "./resolver/receivingLine.resolver";
import { TransferOrderLineResolver } from "./resolver/transferOrderLine.resolver";
import { BankStatementService } from "./services/bankstatements.service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { CostingResolver } from "./resolver/costing.resolver";
import { CostingLineResolver } from "./resolver/costingLine.resolver";
import { BusinessTypeResolver } from "./resolver/businessType.resolver";
import { LocationResolver } from "./resolver/location.resolver";
import { DirectiveModule } from "./components/shared/directive.module";
import { PrintMvrInvoiceComponent } from "./components/manage-sales/invoice/print-mvr-invoice/print-mvr-invoice.component";
import { PrintUsdInvoiceComponent } from "./components/manage-sales/invoice/print-usd-invoice/print-usd-invoice.component";
import { PrintMvrQuotationComponent } from "./components/manage-sales/quotations/print-mvr-quotation/print-mvr-quotation.component";
import { PrintUsdQuotationComponent } from "./components/manage-sales/quotations/print-usd-quotation/print-usd-quotation.component";
import { MVRMemoPrintComponent } from "./components/manage-sales/memo/mvrmemo-print/mvrmemo-print.component";
import { USDMemoPrintComponent } from "./components/manage-sales/memo/usdmemo-print/usdmemo-print.component";
import { ReceivingPrintComponent } from "./components/manage-stocks/receiving/receiving-print/receiving-print.component";
import { CostingPrintComponent } from "./components/manage-stocks/costing/costing-print/costing-print.component";
import { CostingProfitPrintComponent } from "./components/manage-stocks/costing/costing-profit-print/costing-profit-print.component";
import { TansferOrderPrintComponent } from "./components/manage-transfers/transfer-order/tansfer-order-print/tansfer-order-print.component";
import { ModelResolver } from "./resolver/model.resolver";
import { DiscountResolver } from "./resolver/discount.resolver";
import { ShipmentAllResolver } from "./resolver/shipmentAll.resolver";
import { VendorResolver } from "./resolver/vendor.resolver";
import { CostingAllResolver } from "./resolver/costingAll.resolver";
import { ClearanceAllResolver } from "./resolver/clearanceAll.resolver";
import { ReferralResolver } from "./resolver/referral.resolver";
import { FinancierResolver } from "./resolver/financier.resolver";
import { TransferOrderLineService } from "./services/transferOrderLine.service";
import { ApplicationRolesResolver } from "./resolver/applicationRoles.resolver";
import { ApplicationUserResolver } from "./resolver/applicationUser.resolver";
import { DeliveryOrderResolver } from "./resolver/deliveryOrder.resolver";
import { DeliveryOrderPrintComponent } from "./components/manage-sales/deliveryOrder/delivery-order-print/delivery-order-print.component";
import { SalesLineResolver } from "./resolver/salesLine.resolver";
import { PrintMvrReceiptComponent } from "./components/manage-sales/receipt/print-mvr-receipt/print-mvr-receipt.component";
import { PrintPosInvoiceComponent } from "./components/manage-sales/invoice/print-pos-invoice/print-pos-invoice.component";
import { PrintUsdReceiptComponent } from "./components/manage-sales/receipt/print-usd-receipt/print-usd-receipt.component";
import { PaymentByInvoiceResolver } from "./resolver/payment.resolver";
import { ConfirmationDialogComponent } from "src/app/components/shared/confirmation-dialog/confirmation-dialog.component";
import { ClearanceService } from "./services/clearance.service";
import { CostingService } from "./services/costing.service";
import { CostingLineService } from "./services/costingLine.service";
import { MissedProductService } from "./services/missedproduct.service";
import { ModelService } from "./services/model.service";
import { ProductService } from "./services/product.service";
import { ProductCostService } from "./services/productCost.service";
import { ProductLocationService } from "./services/productLocation.service";
import { ProductStorageService } from "./services/productstorage.service";
import { ReceivingService } from "./services/receiving.service";
import { ReceivingLineService } from "./services/receivingLine.service";
import { ShipmentService } from "./services/shipment.service";
import { StockAdjustmentService } from "./services/stockAdjustment.service";
import { StockAdjustmentLineService } from "./services/stockAdjustmentLine.service";
import { VendorService } from "./services/vendor.service";
import { BrandService } from "./services/brand.service";
import { ClearanceResolver } from "./resolver/clearance.resolver";
import { PrintLayoutComponent } from "./components/print-layout/print-layout.component";
import { PrintLayoutService } from "./components/print-layout/print-layout.service";
import { ChangeBusinessLocationComponent } from "./components/change-business-location/change-business-location.component";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { DenominationPrintComponent } from "./components/denomination/denomination-print/denomination-print.component";
import { CategoryResolver } from "./resolver/category.resolver";
import { JobMVRPrintComponent } from "./components/manage-sales/job/job-mvr-print/job-mvr-print.component";
import { JobUSDPrintComponent } from "./components/manage-sales/job/job-usd-print/job-usd-print.component";
import { ProductPromotionService } from "./services/productPromotion.service";
import { AlertService } from "./core/services/alert.service";
import { NotificationEndpoint } from "./core/services/notification-endpoint.service";
import { NotificationService } from "./core/services/notification.service";
import { AccountEndpoint } from "./core/services/account-endpoint.service";
import { AccountService } from "./core/services/account.service";
import {
  AppTranslationService,
  TranslateLanguageLoader,
} from "./core/services/app-translation.service";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { ModalModule } from "ngx-bootstrap/modal";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { OidcHelperService } from "./core/services/oidc-helper.service";
import { ThemeManager } from "./core/models/theme-manager";
import { ConfigurationService } from "./core/services/configuration.service";
import { LocalStoreManager } from "./core/services/local-store-manager.service";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { ClipboardModule } from "ngx-clipboard";
import { ErrorInterceptorService } from "./core/services/error-interceptor.service";
import { HttpInterceptorService } from "./core/services/http-interceptor.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ProductPromotionResolver } from "./resolver/productPromotion.resolver";
import { NotFoundComponent } from "./components/account/not-found/not-found.component";
import { LoaderService } from "./core/services/loader.service";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { LoaderDialogComponent } from "./components/shared/loader-dialog/loader-dialog.component";
import { DenominationComponent } from "./components/denomination/denomination.component";

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  createImageThumbnails: true,
};
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FooterModule,
    HttpClientModule,
    AppRoutingModule,
    ChartsModule,
    NguCarouselModule,
    HttpClientJsonpModule,
    DropzoneModule,
    ngfModule,
    MatTableExporterModule,
    MatProgressSpinnerModule,
    NgxMaskModule.forRoot(options),
    NgxInterceptorModule.forRoot(),

    NgSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLanguageLoader,
      },
    }),
    OAuthModule.forRoot(),
    ToastaModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    FormsModule,
    ClipboardModule,

    NgxMatSelectSearchModule,
    BrowserAnimationsModule,
    DirectiveModule,
    BrowserModule,
    ReactiveFormsModule,

    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],

  declarations: [
    AppComponent,
    PrintLayoutComponent,

    LoginComponent,
    DashboardComponent,
    UnautorizedComponent,
    CancelReturnRemarksDialogComponent,
    ConfirmationDialogComponent,
    NotFoundComponent,
    ImageViewerComponent,
    ChangeBusinessLocationComponent,

    //PRINT COMPONENTS//////////////////
    PrintMvrInvoiceComponent,
    PrintUsdInvoiceComponent,
    PrintPosInvoiceComponent,

    PrintMvrQuotationComponent,
    PrintUsdQuotationComponent,

    MVRMemoPrintComponent,
    USDMemoPrintComponent,

    PrintMvrReceiptComponent,
    PrintUsdReceiptComponent,

    JobMVRPrintComponent,
    JobUSDPrintComponent,

    ReceivingPrintComponent,
    CostingPrintComponent,
    CostingProfitPrintComponent,
    TansferOrderPrintComponent,
    DeliveryOrderPrintComponent,
    DenominationPrintComponent,

    LoaderDialogComponent,
    DenominationComponent,
  ],

  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: DROPZONE_CONFIG, useValue: DEFAULT_DROPZONE_CONFIG },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },

    DatePipe,
    PrintLayoutService,

    ApplicationUserBusinessService,
    CostingService,
    CostingLineService,
    ApprovalPolicyService,
    ApprovalService,
    DepartmentService,
    LitusEntityService,

    BrandService,
    CountryService,
    CurrencyService,
    DiscountService,
    LoaderService,
    ApplicationUserLocationsService,
    LocationService,
    ModelService,

    ProductService,
    ShipmentService,
    UploadService,
    VendorService,

    ClearanceService,
    ReferralService,
    MissedProductService,
    CurrencyPipe,
    ProductPromotionService,
    BusinessTypeService,
    ProductLocationService,
    ReceivingService,
    ReceivingLineService,
    ProductCostService,
    TransferOrderService,
    TransferOrderLineService,
    TechnicianService,
    AssetService,
    PaymentTermService,
    TaxService,
    PageUnloadAlertGuard,
    ThemeService,

    TaxExemptionService,
    DepositAccountService,

    SpecialExemptionService,
    StaffDiscountExemptionService,
    DepositAccountService,

    StockAdjustmentService,
    StockAdjustmentLineService,
    ProductStorageService,
    BankStatementService,

    //RESOLVER//////////////

    BusinessTypeResolver,
    LocationResolver,
    DepositAccountResolver,
    StockAdjustmentResolver,

    PaymentByInvoiceResolver,
    InvoiceResolver,
    CurrencyResolver,
    ReceiptResolver,
    PaymentTermResolver,
    ProductPromotionResolver,

    ShipmentResolver,
    ClearanceResolver,

    TaxExemptionResolver,
    DenominationResolver,
    FinancierResolver,
    ApprovalPolicyResolver,
    TaxResolver,
    DiscountResolver,
    DiscountEligibilityResolver,

    SalesResolver,
    SalesLineResolver,

    SpecialExemptionResolver,
    StaffDiscountExemptionResolver,
    ProductResolver,
    TechnicianResolver,
    TransferOrderResolver,
    TransferOrderLineResolver,

    ReceivingResolver,
    ReceivingLineResolver,

    CostingResolver,
    CostingLineResolver,
    ModelResolver,
    BrandResolver,
    UnitOfMeasureResolver,
    CategoryResolver,

    ShipmentAllResolver,
    ClearanceAllResolver,
    CostingAllResolver,
    VendorResolver,

    ReferralResolver,
    FinancierResolver,

    ApplicationRolesResolver,
    ApplicationUserResolver,

    DeliveryOrderResolver,
    ///////////////////////

    AlertService,
    ThemeManager,
    ConfigurationService,
    AppTitleService,
    AppTranslationService,
    NotificationService,
    NotificationEndpoint,
    AccountService,
    AccountEndpoint,
    LocalStoreManager,
    OidcHelperService,
  ],
  entryComponents: [
    CancelReturnRemarksDialogComponent,
    ConfirmationDialogComponent,
    ImageViewerComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
