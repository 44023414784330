import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DBkeys } from "../models/db-keys";
import { LoginResponse } from "../models/login-response.model";
import { LocalStoreManager } from "./local-store-manager.service";

@Injectable()
export class OidcHelperService {
  private readonly clientId = "salesapp_spa";
  private readonly scope =
    "openid email phone profile offline_access roles salesapp_api";

  private readonly tokenEndpoint = "/connect/token";

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager
  ) {}

  loginWithPassword(userName: string, password: string) {
    const header = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    const params = new HttpParams()
      .append("username", userName)
      .append("password", password)
      .append("client_id", this.clientId)
      .append("grant_type", "password")
      .append("scope", this.scope);

    return this.http.post<LoginResponse>(this.tokenEndpoint, params, {
      headers: header,
    });
  }

  refreshLogin() {
    const header = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    const params = new HttpParams()
      .append("refresh_token", this.refreshToken)
      .append("client_id", this.clientId)
      .append("grant_type", "refresh_token");

    return this.http.post<LoginResponse>(this.tokenEndpoint, params, {
      headers: header,
    });
  }

  get accessToken(): string {
    return this.localStorage.getData(DBkeys.ACCESS_TOKEN);
  }

  get accessTokenExpiryDate(): Date {
    return this.localStorage.getDataObject<Date>(DBkeys.TOKEN_EXPIRES_IN, true);
  }

  get refreshToken(): string {
    return this.localStorage.getData(DBkeys.REFRESH_TOKEN);
  }

  get isSessionExpired(): boolean {
    if (this.accessTokenExpiryDate == null) {
      return true;
    }

    return this.accessTokenExpiryDate.valueOf() <= new Date().valueOf();
  }
}
