import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { Shipment } from "../models/shipment";
import { ShipmentService } from "../services/shipment.service";

@Injectable()
export class ShipmentAllResolver implements Resolve<Shipment[]> {
  constructor(private service: ShipmentService) {}

  resolve(): Observable<Shipment[]> {
    return this.service.getAllShipments();
  }
}
