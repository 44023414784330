import { Injectable } from "@angular/core";
import { TransferOrder } from "../models/transferOrder";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { EntityService } from "./entity.service";
import { CustomResult } from "../models/custom-model/customResult.model";

@Injectable({
  providedIn: "root",
})
export class TransferOrderService extends EntityService<TransferOrder> {
  private readonly API_URL = "/api/TransferOrders";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/TransferOrders");
  }

  getAllTransferOrders(): Observable<TransferOrder[]> {
    return this.httpClient.get<TransferOrder[]>(`${this.API_URL}`);
  }

  getAllLogisticStagesById(id: number): Observable<any> {
    const endpointUrl = `${this.API_URL}/GetAllLogisticStagesById`;
    return this.httpClient.get<any>(endpointUrl, {
      params: new HttpParams().set("id", id.toString()),
    });
  }

  getTransferOrderById(id: number): Observable<TransferOrder> {
    return this.httpClient.get<TransferOrder>(`${this.API_URL}/` + id);
  }

  getTransferOrderByContainer(name: string): Observable<TransferOrder> {
    const endpointUrl = `${this.API_URL}/GetTransferOrderByContainer`;
    return this.httpClient.get<TransferOrder>(endpointUrl, {
      params: new HttpParams().set("name", name),
    });
  }

  getTransferOrdersByStatus(
    TransferOrderStatus: number
  ): Observable<TransferOrder[]> {
    const endpointUrl = `${this.API_URL}/GetTransferOrderByStatus`;
    return this.httpClient.get<TransferOrder[]>(endpointUrl, {
      params: new HttpParams().set(
        "TransferOrderStatus",
        TransferOrderStatus.toString()
      ),
    });
  }

  searchTransferOrderByBusiness(
    term: string,
    businessTypeId: number
  ): Observable<TransferOrder[]> {
    return this.httpClient.get<TransferOrder[]>(
      `${this.API_URL}/SearchTransferOrderByBusiness?term=` +
        term +
        `&businessTypeId=` +
        businessTypeId
    );
  }

  addTransferOrder(TransferOrder: TransferOrder): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(this.API_URL, TransferOrder);
  }

  updateTransferOrder(TransferOrder: TransferOrder): Observable<TransferOrder> {
    return this.httpClient.put<TransferOrder>(
      `${this.API_URL}/` + TransferOrder.transferOrderId,
      TransferOrder
    );
  }

  deleteTransferOrder(TransferOrderId: number): Observable<TransferOrder> {
    return this.httpClient.delete<TransferOrder>(
      `${this.API_URL}/` + TransferOrderId
    );
  }

  completeTransferOrder(
    TransferOrder: TransferOrder
  ): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/CompleteTransferOrder/` + TransferOrder.transferOrderId,
      TransferOrder
    );
  }
}
