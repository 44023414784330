import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { EntityService } from "./entity.service";
import { Observable } from "rxjs";
import { Approval } from "../models/approvals";
import { ApprovalsLevel } from "../models/approvalsUserMap";
import { ApprovalScreenType } from "../models/approvalPolicy";
import { CustomResult } from "../models/custom-model/customResult.model";
import { User } from "../core/models/user.model";

@Injectable({
  providedIn: "root",
})
export class ApprovalService extends EntityService<Approval> {
  private readonly API_URL = "/api/approval";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/approval");
  }

  get ApprovalCount(): number {
    return this.ApprovalCount;
  }
  getApprovalByShipmentId(ShipmentId: number): Observable<Approval> {
    return this.httpClient.get<Approval>(
      `${this.API_URL}/getApprovalByShipmentId?ShipmentId=` + ShipmentId
    );
  }
  getAlApproval(): Observable<Approval[]> {
    return this.httpClient.get<Approval[]>(`${this.API_URL}`);
  }
  getApprovalById(id: number): Observable<Approval> {
    return this.httpClient.get<Approval>(`${this.API_URL}/` + id);
  }
  addCostingApproval(approval: Approval): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(
      `${this.API_URL}/PostCostingApproval`,
      approval
    );
  }
  addClearanceApproval(approval: Approval): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(
      `${this.API_URL}/PostClearanceApproval`,
      approval
    );
  }
  addTransferOrderApproval(approval: Approval): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(
      `${this.API_URL}/PostTransferOrderApproval`,
      approval
    );
  }
  addStockAdjustmentApproval(approval: Approval): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(
      `${this.API_URL}/PostStockAdjustmentApproval`,
      approval
    );
  }

  addApproval(approval: Approval): Observable<Approval> {
    return this.httpClient.post<Approval>(this.API_URL, approval);
  }
  updateApproval(approval: Approval): Observable<Approval> {
    return this.httpClient.put<Approval>(
      `${this.API_URL}/` + approval.approvalId,
      approval
    );
  }
  deleteApproval(approvalId: number): Observable<Approval> {
    return this.httpClient.delete<Approval>(`${this.API_URL}/` + approvalId);
  }

  getLevelApprovalUsersByScreen(
    approvalsLevel: ApprovalsLevel,
    approvalScreenType: ApprovalScreenType,
    businessTypeId: number
  ): Observable<User[]> {
    const endpointUrl = `${this.API_URL}/GetLevelApprovalUsersByScreen`;
    return this.httpClient.get<User[]>(endpointUrl, {
      params: new HttpParams()
        .set("approvalsLevel", approvalsLevel.toString())
        .set("approvalScreenType", approvalScreenType.toString())
        .set(
          "businessTypeId",
          businessTypeId != null ? businessTypeId.toString() : ""
        ),
    });
  }
}
