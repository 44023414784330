import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class PrintLayoutService {
  isPrinting = false;
  callback: string;

  constructor(private router: Router) {}

  printDocument(documentName: string, id: number, callback: string) {
    this.isPrinting = true;
    this.callback = callback;

    this.router.navigate([
      "/",
      {
        outlets: {
          print: ["print", documentName, id],
        },
      },
    ]);
  }

  onDataReady() {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null } }]);
      this.router
        .navigateByUrl(this.callback, { skipLocationChange: false })
        .then(() => {
          window.location.reload();
        });
    });
  }
}
