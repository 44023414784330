import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { Invoice } from "../models/invoice";
import { InvoiceService } from "../services/invoice.service";

@Injectable()
export class InvoiceResolver implements Resolve<Invoice> {
  constructor(private service: InvoiceService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Invoice> {
    const id = Number(route.paramMap.get("id"));

    if (id != 0) {
      return this.service.getInvoicedById(+id);
    }
  }
}
