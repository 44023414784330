import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { EntityService } from "./entity.service";
import {
  ApplicationUserBusiness,
  ApplicationUserBusinessEnroll,
} from "../core/models/applicationUserBusiness.model";
import { CustomResult } from "../models/custom-model/customResult.model";

@Injectable({
  providedIn: "root",
})
export class ApplicationUserBusinessService extends EntityService<ApplicationUserBusiness> {
  private readonly API_URL = "/api/applicationUserBusinesses";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/applicationUserBusinesses");
  }

  getApplicationUserBusiness(): Observable<ApplicationUserBusiness[]> {
    return this.httpClient.get<ApplicationUserBusiness[]>(`${this.API_URL}`);
  }

  getApplicationUserBusinessesByUserId(
    userId: string
  ): Observable<ApplicationUserBusiness[]> {
    return this.httpClient.get<ApplicationUserBusiness[]>(
      `${this.API_URL}/GetApplicationUserBusinessesByUserId?userId=` + userId
    );
  }

  getUserBusinessByBusinessAndUser(
    userId: string,
    businessTypeId: string
  ): Observable<boolean> {
    return this.httpClient.get<boolean>(
      `${this.API_URL}/GetUserBusinessByBusinessAndUser?userId=` +
        userId +
        `&businessTypeId=` +
        businessTypeId
    );
  }

  addApplicationUserBusiness(
    applicationUserBusiness: ApplicationUserBusinessEnroll
  ): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(
      this.API_URL,
      applicationUserBusiness
    );
  }

  updateApplicationUserBusiness(
    applicationUserBusiness: ApplicationUserBusinessEnroll
  ): Observable<CustomResult> {
    console.log(applicationUserBusiness);

    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/` + applicationUserBusiness.applicationUserBusinessId,
      applicationUserBusiness
    );
  }

  deleteApplicationUserBusiness(
    applicationUserBusinessId: number
  ): Observable<ApplicationUserBusiness> {
    return this.httpClient.delete<ApplicationUserBusiness>(
      `${this.API_URL}/` + applicationUserBusinessId
    );
  }
}
