import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CustomResult } from "src/app/models/custom-model/customResult.model";
import { TransferOrderLine } from "src/app/models/transferOrderLine";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class TransferOrderLineService extends EntityService<TransferOrderLine> {
  private readonly API_URL = "/api/TransferOrderLines";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/TransferOrderLines");
  }

  getAllTransferOrderLines(): Observable<TransferOrderLine[]> {
    return this.httpClient.get<TransferOrderLine[]>(
      `${this.API_URL}/GetTransferOrderLines`
    );
  }

  getTransferOrderLineById(id: number): Observable<TransferOrderLine> {
    return this.httpClient.get<TransferOrderLine>(`${this.API_URL}/` + id);
  }

  getTransferOrderLineByTransferOrderId(
    transferOrderId: number
  ): Observable<TransferOrderLine[]> {
    return this.httpClient.get<TransferOrderLine[]>(
      `${this.API_URL}/GetTransferOrderLineByTransferOrderId/` + transferOrderId
    );
  }

  addTransferOrderLine(
    TransferOrderLine: TransferOrderLine
  ): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(this.API_URL, TransferOrderLine);
  }

  updateTransferOrderLine(
    TransferOrderLine: TransferOrderLine
  ): Observable<TransferOrderLine> {
    return this.httpClient.put<TransferOrderLine>(
      `${this.API_URL}/` + TransferOrderLine.transferOrderLineId,
      TransferOrderLine
    );
  }

  deleteTransferOrderLine(
    TransferOrderLineId: number
  ): Observable<TransferOrderLine> {
    return this.httpClient.delete<TransferOrderLine>(
      `${this.API_URL}/` + TransferOrderLineId
    );
  }

  download(bussinessId): Observable<Blob> {
    return this.httpClient.post<Blob>(
      `${this.API_URL}/ExportUploadTemplate?bussinessId=` + bussinessId,
      bussinessId,
      { responseType: "blob" as "json" }
    );
  }
}
