import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { fadeInOut } from "src/app/services/animations";
import { TransferOrderLine } from "src/app/models/transferOrderLine";
import { Tax } from "src/app/models/tax";
import {
  DeliveryOrder,
  DeliveryOrderStatus,
  DeliveryOrderType,
  DeliveryType,
} from "src/app/models/deliveryOrder";
import { EntityDataSourceCustomBind } from "src/app/services/entity.datasource";
import { SalesLineService } from "src/app/services/salesLine.service";
import {
  ReleasedStatus,
  SalesLine,
  SalesLineDeliveryOrderParameters,
} from "src/app/models/salesLine";
import { PrintLayoutService } from "src/app/components/print-layout/print-layout.service";

@Component({
  selector: "app-delivery-order-print",
  templateUrl: "./delivery-order-print.component.html",
  styleUrls: ["./delivery-order-print.component.scss"],
  animations: [fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryOrderPrintComponent implements OnInit {
  public dataSourceSOLine: TransferOrderLine[] = [];
  displayedColumns = [
    "itemCode",
    "productCode",
    "productName",
    "deliveryType",
    "releasedStatus",
    "qty",
  ];

  id: number;
  localData: DeliveryOrder;
  tax: Tax;

  public dataSource: EntityDataSourceCustomBind;
  private readonly endpointUrl =
    "/api/SalesLines/GetAllSalesLinesDeliveryOrder";
  tableCount: number;
  salesLineList: SalesLine[] = [];
  constructor(
    private printLayoutService: PrintLayoutService,
    private salesLineService: SalesLineService,
    private route: ActivatedRoute
  ) {
    this.id = Number(this.route.snapshot.paramMap.get("id"));

    this.route.data.subscribe(({ deliveryOrder }) => {
      if (deliveryOrder) {
        this.localData = deliveryOrder;
      }
    });

    this.route.data.subscribe(({ tax }) => {
      if (tax) {
        this.tax = tax.rate;
      }
    });
  }

  getDeliveryOrderType(value) {
    return DeliveryOrderType[value];
  }

  getDeliveryOrderStatus(value) {
    return DeliveryOrderStatus[value];
  }

  ngOnInit() {
    this.dataSource = new EntityDataSourceCustomBind(
      this.salesLineService,
      this.endpointUrl
    );

    const args: SalesLineDeliveryOrderParameters = {
      salesId: this.localData.invoice.salesId,
      locationId: this.localData.locationId,
    };

    this.dataSource.load(0, "", "asc", "", 0, 25, [], 0, args);
    this.dataSource.entityCount$.subscribe((c) => (this.tableCount = c));
    this.dataSource.currentData$.subscribe((rows) => {
      this.salesLineList = rows;
    });

    setTimeout(() => {
      this.printLayoutService.onDataReady();
    }, 1000);
  }

  getDeliveryType(value) {
    return DeliveryType[value];
  }

  getReleasedStatus(value) {
    return ReleasedStatus[value];
  }

  getQuantitySum() {
    return this.salesLineList?.length > 0
      ? this.salesLineList
          .map((t) => t.qty)
          .reduce((acc, value) => acc + value, 0)
      : 0;
  }
}
