import { AuditableEntity } from "./custom-model/auditableEntity";
import { BusinessType } from "./businessType";
import { ProductLocation } from "./productLocation";
import { Receiving } from "./receiving";

export class Location extends AuditableEntity {
  locationId: number;
  locationName: string;
  locationDetails: string;
  locationTypes: LocationTypes;
  locationAddress: string;
  locationTitle: string;
  generalNumber: string;
  hotlineNumber: string;
  email1: string;
  email2: string;
  businessTypeId: number;
  businessType: BusinessType;
  productLocations: ProductLocation[] = [];
  receivings: Receiving[] = [];
}

export enum LocationTypes {
  Warehouse = 1,
  Showroom = 2,
  Other = 3,
}
