import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Clearance } from "src/app/models/clearance.model";
import { CustomResult } from "src/app/models/custom-model/customResult.model";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class ClearanceService extends EntityService<Clearance> {
  private readonly API_URL = "/api/clearances";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/clearances");
  }

  getAllClearances(): Observable<Clearance[]> {
    return this.httpClient.get<Clearance[]>(`${this.API_URL}`);
  }

  getOpenClearances(businessTypeId: number): Observable<Clearance[]> {
    return this.httpClient.get<Clearance[]>(
      `${this.API_URL}/GetOpenClearances?businessTypeId=` + businessTypeId
    );
  }

  loadClearanceDocument(id: number): Observable<CustomResult> {
    return this.httpClient.get<CustomResult>(
      `${this.API_URL}/LoadClearanceDocument?clearanceId=` + id
    );
  }

  clearanceSearchByBusiness(
    term: string,
    businessTypeId: number
  ): Observable<Clearance[]> {
    return this.httpClient.get<Clearance[]>(
      `${this.API_URL}/ClearanceSearchByBusiness?term=` +
        term +
        `&businessTypeId=` +
        businessTypeId
    );
  }

  getClearanceById(id: number): Observable<Clearance> {
    return this.httpClient.get<Clearance>(`${this.API_URL}/` + id);
  }

  addClearance(clearance: Clearance): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(this.API_URL, clearance);
  }

  regenerateAVCostAfterAdjustQty(
    clearanceLineId: number
  ): Observable<CustomResult> {
    return this.httpClient.get<CustomResult>(
      `${this.API_URL}/RegenerateAVCostAfterAdjustQty/` + clearanceLineId
    );
  }

  updateClearance(clearance: Clearance): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/` + clearance.clearanceId,
      clearance
    );
  }

  deleteClearance(clearanceId: number): Observable<Clearance> {
    return this.httpClient.delete<Clearance>(`${this.API_URL}/` + clearanceId);
  }

  deleteClearanceDocument(clearanceId: number): Observable<CustomResult> {
    return this.httpClient.delete<CustomResult>(
      `${this.API_URL}/DeleteClearanceDocument/` + clearanceId
    );
  }

  getClearanceByShipmentId(shipmentId: number): Observable<CustomResult> {
    return this.httpClient.get<CustomResult>(
      `${this.API_URL}/GetClearanceByShipmentId/` + shipmentId
    );
  }

  getAvarageCost(
    productCostId: number,
    newCost: number
  ): Observable<CustomResult> {
    const endpointUrl = `${this.API_URL}/GetAvarageCost`;
    let parameters = new HttpParams()
      .set("productCostId", productCostId.toString())
      .set("newCost", newCost.toString());

    return this.httpClient.get<CustomResult>(endpointUrl, {
      params: parameters,
    });
  }
}
