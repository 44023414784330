import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UserLogin } from "src/app/core/models/user-login.model";
import { Utilities } from "src/app/core/models/utilities";
import {
  AlertService,
  MessageSeverity,
  DialogType,
} from "src/app/core/services/alert.service";
import { AuthService } from "src/app/core/services/auth.service";
import { ConfigurationService } from "src/app/core/services/configuration.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  userLogin = new UserLogin();
  isLoading = false;
  formResetToggle = true;
  modalClosedCallback: () => void;
  loginStatusSubscription: any;

  @Input()
  isModal = false;

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private configurations: ConfigurationService
  ) {}

  ngOnInit() {
    this.userLogin.rememberMe = this.authService.rememberMe;

    if (this.getShouldRedirect()) {
      this.authService.redirectLoginUser();
    } else {
      this.loginStatusSubscription = this.authService
        .getLoginStatusEvent()
        .subscribe((_) => {
          if (this.getShouldRedirect()) {
            this.authService.redirectLoginUser();
          }
        });
    }
  }

  ngOnDestroy() {
    if (this.loginStatusSubscription) {
      this.loginStatusSubscription.unsubscribe();
    }
  }

  getShouldRedirect() {
    return (
      !this.isModal &&
      this.authService.isLoggedIn &&
      !this.authService.isSessionExpired
    );
  }

  showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  closeModal() {
    if (this.modalClosedCallback) {
      this.modalClosedCallback();
    }
  }

  login() {
    this.isLoading = true;
    this.alertService.startLoadingMessage("", "Attempting login...");

    this.authService
      .loginWithPassword(
        this.userLogin.userName,
        this.userLogin.password,
        this.userLogin.rememberMe
      )
      .subscribe({
        next: (user) => {
          setTimeout(() => {
            this.alertService.stopLoadingMessage();
            this.isLoading = false;
            this.reset();

            if (!this.isModal) {
              this.alertService.showMessage(
                "Login",
                `Welcome ${user.userName}!`,
                MessageSeverity.success
              );
            } else {
              this.alertService.showMessage(
                "Login",
                `Session for ${user.userName} restored!`,
                MessageSeverity.success
              );
              setTimeout(() => {
                this.alertService.showMessage(
                  "Session Restored",
                  "Please try your last operation again",
                  MessageSeverity.default
                );
              }, 500);

              this.closeModal();
            }
          }, 500);
        },
        error: (error) => {
          this.alertService.stopLoadingMessage();

          if (Utilities.checkNoNetwork(error)) {
            this.alertService.showMessage(
              Utilities.noNetworkMessageCaption,
              Utilities.noNetworkMessageDetail,
              MessageSeverity.error
            );
            this.offerAlternateHost();
          } else {
            const errorMessage = Utilities.getHttpResponseMessage(error);

            if (errorMessage) {
              this.alertService.showMessage(
                "Unable to login",
                this.mapLoginErrorMessage(errorMessage),
                MessageSeverity.error
              );
            } else {
              this.alertService.showMessage(
                "Unable to login",
                "An error occured whilst logging in, please try again later.\nError: " +
                  Utilities.getResponseBody(error),
                MessageSeverity.error
              );
            }
          }

          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        },
      });
  }

  offerAlternateHost() {
    if (
      Utilities.checkIsLocalHost(location.origin) &&
      Utilities.checkIsLocalHost(this.configurations.baseUrl)
    ) {
      this.alertService.showDialog(
        "Dear Developer!\nIt appears your backend Web API service is not running...\n" +
          "Would you want to temporarily switch to the online Demo API below?(Or specify another)",
        DialogType.prompt,
        (value: string) => {
          this.configurations.baseUrl = value;
          this.alertService.showMessage(
            "API Changed!",
            "The target Web API has been changed to: " + value,
            MessageSeverity.warn
          );
        },
        null,
        null,
        null,
        this.configurations.fallbackBaseUrl
      );
    }
  }

  mapLoginErrorMessage(error: string) {
    if (error === "invalid_username_or_password") {
      return "Invalid username or password";
    }

    if (error === "invalid_grant") {
      return "This account has been disabled";
    }

    return error;
  }

  reset() {
    this.formResetToggle = false;

    setTimeout(() => {
      this.formResetToggle = true;
    });
  }
}
