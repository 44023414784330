import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { SalesLine } from "../models/salesLine";
import { SalesLineService } from "../services/salesLine.service";

@Injectable()
export class SalesLineResolver implements Resolve<SalesLine[]> {
  constructor(private service: SalesLineService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<SalesLine[]> {
    const id = Number(route.paramMap.get("id"));
    if (id != 0) {
      return this.service.getSalesLineBySalesOrderId(+id);
    }
  }
}
