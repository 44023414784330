import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { ProductPromotion } from "../models/productPromotion";
import { ProductPromotionService } from "../services/productPromotion.service";
import { AuthService } from "../core/services/auth.service";

@Injectable()
export class ProductPromotionResolver implements Resolve<ProductPromotion[]> {
  constructor(
    private service: ProductPromotionService,
    private authService: AuthService
  ) {}

  resolve(): Observable<ProductPromotion[]> {
    if (this.authService.isLoggedIn) {
      if (this.authService.currentBusiness) {
        return this.service.getProductPromotionByBusinessTypeId(
          this.authService.currentBusiness.businessTypeId
        );
      }
    } else {
      return null;
    }
  }
}
