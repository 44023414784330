import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { EntityService } from "./entity.service";
import { tap } from "rxjs/operators";
import { LitusEntity } from "../models/litusEntity";

@Injectable({
  providedIn: "root",
})
export class LitusEntityService extends EntityService<LitusEntity> {
  private readonly API_URL = "/api/litusEntities";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/litusEntities");
  }

  get litusEntityCount(): number {
    return this.litusEntityCount;
  }

  search(filter: { name: string } = { name: "" }): Observable<LitusEntity[]> {
    return this.httpClient.get<LitusEntity[]>(`${this.API_URL}`).pipe(
      tap((response: LitusEntity[]) => {
        response = response.filter((litusEntity) =>
          litusEntity.litusEntityCode.includes(filter.name)
        );
        return response;
      })
    );
  }

  getAlLitusEntity(): Observable<LitusEntity[]> {
    return this.httpClient.get<LitusEntity[]>(`${this.API_URL}`);
  }

  getLitusEntityById(id: number): Observable<LitusEntity> {
    return this.httpClient.get<LitusEntity>(`${this.API_URL}/` + id);
  }

  getLitusEntityByName(name: string): Observable<LitusEntity> {
    return this.httpClient.get<LitusEntity>(
      `${this.API_URL}/GetLitusEntityByName?name=` + name
    );
  }

  getLitusEntityByCode(name: string): Observable<LitusEntity> {
    return this.httpClient.get<LitusEntity>(
      `${this.API_URL}/GetLitusEntityByCode?name=` + name
    );
  }

  addLitusEntity(litusEntity: LitusEntity): Observable<LitusEntity> {
    return this.httpClient.post<LitusEntity>(this.API_URL, litusEntity);
  }

  updateLitusEntity(litusEntity: LitusEntity): Observable<LitusEntity> {
    return this.httpClient.put<LitusEntity>(
      `${this.API_URL}/` + litusEntity.litusEntityId,
      litusEntity
    );
  }

  deleteLitusEntity(litusEntityId: number): Observable<LitusEntity> {
    return this.httpClient.delete<LitusEntity>(
      `${this.API_URL}/` + litusEntityId
    );
  }
}
