import { Injectable } from "@angular/core";
import { EntityService } from "./entity.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { DiscountEligibility } from "../models/discountEligibility";
import { CustomResult } from "../models/custom-model/customResult.model";

@Injectable({
  providedIn: "root",
})
export class DiscountEligibilityService extends EntityService<DiscountEligibility> {
  private readonly API_URL = "/api/DiscountEligibilities";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/DiscountEligibilities");
  }

  getAllDiscountEligibilities(): Observable<DiscountEligibility[]> {
    return this.httpClient.get<DiscountEligibility[]>(`${this.API_URL}`);
  }

  getDiscountEligibilityByBusinessTypeId(
    businessTypeId: number
  ): Observable<DiscountEligibility[]> {
    return this.httpClient.get<DiscountEligibility[]>(
      `${this.API_URL}/GetDiscountEligibilityByBusinessTypeId?businessTypeId=` +
        businessTypeId
    );
  }

  addDiscountEligibility(
    discountEligibility: DiscountEligibility
  ): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(
      this.API_URL,
      discountEligibility
    );
  }

  updateDiscountEligibility(
    discountEligibility: DiscountEligibility
  ): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/` + discountEligibility.discountEligibilityId,
      discountEligibility
    );
  }

  deleteDiscountEligibility(
    discountEligibilityId: number
  ): Observable<DiscountEligibility> {
    return this.httpClient.delete<DiscountEligibility>(
      `${this.API_URL}/` + discountEligibilityId
    );
  }
}
