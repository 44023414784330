import { BusinessTypeService } from "src/app/services/business-type.service";
import { Injectable } from "@angular/core";
import { Resolve, Router } from "@angular/router";
import { Observable } from "rxjs";
import { BusinessType } from "../models/businessType";

@Injectable()
export class BusinessTypeResolver implements Resolve<BusinessType[]> {
  constructor(private service: BusinessTypeService, private router: Router) {}

  resolve(): Observable<BusinessType[]> {
    return this.service.getBusinessTypes();
  }
}
