import { Receiving } from "src/app/models/receiving";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { ReceivingService } from "../services/receiving.service";

@Injectable()
export class ReceivingResolver implements Resolve<Receiving> {
  constructor(private service: ReceivingService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Receiving> {
    const id = Number(route.paramMap.get("id"));
    if (id != 0) {
      return this.service.getReceivingById(+id);
    } else {
      return null;
    }
  }
}
