import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { ProductStorage } from "src/app/models/productStorage";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class ProductStorageService extends EntityService<ProductStorage> {
  private readonly API_URL = "/api/ProductStorages";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/ProductStorages");
  }

  getAllProductStorages(): Observable<ProductStorage[]> {
    return this.httpClient.get<ProductStorage[]>(`${this.API_URL}`);
  }

  updateSatusOfProductStorage(productStorageId): Observable<any> {
    return this.httpClient.get<any>(
      `${this.API_URL}/UpdateSatusOfProductStorage?id=` + productStorageId
    );
  }

  getProductStoragesByRemark(
    storageRemark: string,
    productId: string,
    locationId: string
  ): Observable<ProductStorage> {
    const endpointUrl = `${this.API_URL}/GetProductStoragesByRemark`;
    return this.httpClient.get<ProductStorage>(endpointUrl, {
      params: new HttpParams()
        .set("storageRemark", storageRemark)
        .set("productId", productId)
        .set("locationId", locationId),
    });
  }

  addProductStorage(
    productStorage: ProductStorage
  ): Observable<ProductStorage> {
    return this.httpClient.post<ProductStorage>(this.API_URL, productStorage);
  }

  updateProductStorage(
    productStorage: ProductStorage
  ): Observable<ProductStorage> {
    return this.httpClient.put<ProductStorage>(
      `${this.API_URL}/` + productStorage.productStorageId,
      productStorage
    );
  }

  deleteProductStorage(productStorageId: number): Observable<ProductStorage> {
    return this.httpClient.delete<ProductStorage>(
      `${this.API_URL}/` + productStorageId
    );
  }
}
