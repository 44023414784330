import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CostingLine } from "src/app/models/costingLine";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class CostingLineService extends EntityService<CostingLine> {
  private readonly API_URL = "/api/costingLines";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/costingLines");
  }

  get costingLineCount(): number {
    return this.costingLineCount;
  }

  getAllCostingLine(): Observable<CostingLine[]> {
    return this.httpClient.get<CostingLine[]>(`${this.API_URL}`);
  }
  getCostingLineById(id: number): Observable<CostingLine> {
    return this.httpClient.get<CostingLine>(`${this.API_URL}/` + id);
  }

  getCostingLineByCostingId(costingId: number): Observable<CostingLine[]> {
    return this.httpClient.get<CostingLine[]>(
      `${this.API_URL}/GetCostingLineByCostingId/` + costingId
    );
  }

  addCostingLine(costingLine: CostingLine): Observable<CostingLine> {
    return this.httpClient.post<CostingLine>(this.API_URL, costingLine);
  }

  updateCostingLine(costingLine: CostingLine): Observable<CostingLine> {
    return this.httpClient.put<CostingLine>(
      `${this.API_URL}/` + costingLine.costingLineId,
      costingLine
    );
  }

  deleteCostingLine(costingLineId: number): Observable<CostingLine> {
    return this.httpClient.delete<CostingLine>(
      `${this.API_URL}/` + costingLineId
    );
  }

  downloadDutyTemplate(bussinessId): Observable<Blob> {
    return this.httpClient.post<Blob>(
      `${this.API_URL}/ExportDutyUploadTemplate?bussinessId=` + bussinessId,
      bussinessId,
      { responseType: "blob" as "json" }
    );
  }

  downloadPriceTemplate(bussinessId): Observable<Blob> {
    return this.httpClient.post<Blob>(
      `${this.API_URL}/ExportPriceUploadTemplate?bussinessId=` + bussinessId,
      bussinessId,
      { responseType: "blob" as "json" }
    );
  }
}
