import { ChangeDetectionStrategy, Component } from "@angular/core";
import { fadeInOut } from "../../../services/animations";

@Component({
  selector: "not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"],
  animations: [fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent {}
