import { roundTo } from "round-to";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router, ActivationEnd, RouterEvent } from "@angular/router";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { LoaderService } from "src/app/core/services/loader.service";
import { DBkeys } from "../models/db-keys";
import { LocalStoreManager } from "./local-store-manager.service";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private localStoreManager: LocalStoreManager
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loaderService.setHttpProgressStatus(true);
    req = this.addAuthenticationToken(req);

    return next.handle(req).pipe(
      finalize(() => {
        const begin = performance.now();
        this.logRequestTime(begin, req.url, req.method);
        this.loaderService.setHttpProgressStatus(false);

        this.router.events.subscribe((event) => {
          if (event instanceof RouterEvent) {
            if (event.url != "/") {
              if (event instanceof ActivationEnd) {
                this.loaderService.setHttpProgressStatus(false);
              }
            }
          }
        });
      })
    );
  }

  private logRequestTime(startTime: number, url: string, method: string) {
    const duration = performance.now() - startTime;
    console.log(`HTTP ${method} ${url} - ${roundTo(duration, 2)} seconds`);
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.localStoreManager.getDataObject<string>(
      DBkeys.ACCESS_TOKEN
    );
    if (!token) {
      return request;
    }

    return request.clone({
      body: { ...request.body },
      headers: request.headers
        .set("Content-Type", `application/json`)
        .set("Authorization", `Bearer ${token}`)
        .set("Accept", `application/json, text/plain, */*`),
    });
  }
}
