import { AssetCustomerMap, Asset } from "./asset";
import { AuditableEntity } from "./custom-model/auditableEntity";
import { Model } from "./model";
import { PaymentTerm } from "./paymentTerm";

export class Customer extends AuditableEntity {
  customerId: number;
  customerType: CustomerType;

  fullName: string;
  businessName: string;
  identificationNumber: string;

  tinNo: string;
  gender: Gender;

  primaryNumber: string;
  secondaryNumber: string;

  primaryEmail: string;
  secondaryEmail: string;

  bookingHoldDays: number;

  verifiedCustomer: boolean;
  status: boolean;
  isDefault: boolean;

  address: string;
  paymentTermId: number;
  paymentTerm: PaymentTerm;
  assetCustomerMap: AssetCustomerMap[] = [];
}

export class CustomerAssetView {
  assetCustomerMapId: number | null;
  customer: Customer;
  asset: Asset;
  model: Model;
}

export enum CustomerType {
  Guest = 1,
  Regular = 2,
  Staff = 3,
  Corporate = 4,
  Internal = 5,
  Financier = 6,
}

export enum Gender {
  Male = 1,
  Female = 2,
  None = 3,
}
