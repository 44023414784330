import { NgModule } from "@angular/core";
import { NumbersOnlyDirective } from "./custom-directive/numbers-only.directive";
import { CdkDetailRowDirective } from "./custom-directive/cdk-detail-row.directive";
import { PrintMatTableDirective } from "./mat-table/print-mat-table.directive";
import { TabDirective } from "./custom-directive/tab.directive";

@NgModule({
  imports: [],
  exports: [
    TabDirective,
    NumbersOnlyDirective,
    PrintMatTableDirective,
    CdkDetailRowDirective,
  ],
  declarations: [
    TabDirective,
    NumbersOnlyDirective,
    PrintMatTableDirective,
    CdkDetailRowDirective,
  ],
})
export class DirectiveModule {}
