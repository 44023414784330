import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { Discount } from "../models/discount";
import { DiscountService } from "../services/discount.service";

@Injectable()
export class DiscountResolver implements Resolve<Discount[]> {
  constructor(private service: DiscountService) {}

  resolve(): Observable<Discount[]> {
    return this.service.getAllDiscounts();
  }
}
