import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EntityService } from "./entity.service";
import { Observable } from "rxjs";
import { CustomResult } from "../models/custom-model/customResult.model";
import { ProductPromotion } from "../models/productPromotion";

@Injectable({
  providedIn: "root",
})
export class ProductPromotionService extends EntityService<ProductPromotion> {
  private readonly API_URL = "/api/ProductPromotions";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/ProductPromotions");
  }

  getAllProductPromotions(): Observable<ProductPromotion[]> {
    return this.httpClient.get<ProductPromotion[]>(`${this.API_URL}`);
  }

  getAllActiveProductPromotions(): Observable<ProductPromotion[]> {
    return this.httpClient.get<ProductPromotion[]>(
      `${this.API_URL}/GetAllActiveProductPromotions`
    );
  }

  getProductPromotionByBusinessTypeId(
    businessTypeId: number
  ): Observable<ProductPromotion[]> {
    return this.httpClient.get<ProductPromotion[]>(
      `${this.API_URL}/GetProductPromotionByBusinessTypeId?businessTypeId=` +
        businessTypeId
    );
  }

  getProductPromotionByCode(code: string): Observable<ProductPromotion> {
    return this.httpClient.get<ProductPromotion>(
      `${this.API_URL}/GetProductPromotionByCode?code=` + code
    );
  }

  addProductPromotion(
    productPromotion: ProductPromotion
  ): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(this.API_URL, productPromotion);
  }

  updateProductPromotion(
    productPromotion: ProductPromotion
  ): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/` + productPromotion.productPromotionId,
      productPromotion
    );
  }

  deleteProductPromotion(productPromotionId: number): Observable<CustomResult> {
    return this.httpClient.delete<CustomResult>(
      `${this.API_URL}/` + productPromotionId
    );
  }

  promotionsBulkUploadTemplate(bussinessType: string): Observable<Blob> {
    return this.httpClient.post<Blob>(
      `${this.API_URL}/PromotionsBulkUploadTemplate?bussinessType=` +
        bussinessType,
      bussinessType,
      { responseType: "blob" as "json" }
    );
  }
}
