import { ReceivingLine } from "../models/receivingLine";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { ReceivingLineService } from "../services/receivingLine.service";

@Injectable()
export class ReceivingLineResolver implements Resolve<ReceivingLine[]> {
  constructor(private service: ReceivingLineService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ReceivingLine[]> {
    const id = Number(route.paramMap.get("id"));

    if (id != 0) {
      return this.service.getReceivingLineByReceivingId(+id);
    } else {
      return null;
    }
  }
}
