import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { Observable } from "rxjs";
import { Product } from "../models/product";
import { ProductService } from "../services/product.service";

@Injectable()
export class ProductResolver implements Resolve<Product> {
  constructor(private service: ProductService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Product> {
    const id = Number(route.paramMap.get("id"));

    if (id != 0) {
      return this.service.getProductById(+id);
    }
  }
}
