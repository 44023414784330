import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Location, LocationTypes } from "../../models/location";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { BusinessType } from "../../models/businessType";
import { AlertService } from "../../core/services/alert.service";
import { User } from "src/app/core/models/user.model";
import { AuthService } from "src/app/core/services/auth.service";

@Component({
  selector: "app-change-business-location",
  templateUrl: "./change-business-location.component.html",
  styleUrls: ["./change-business-location.component.scss"],
})
export class ChangeBusinessLocationComponent implements OnInit {
  businessForm: FormGroup;
  businessTypeList: BusinessType[] = [];
  locationList: Location[] = [];

  applicationUser: User;
  businessTypeBE: BusinessType;
  locationBE: Location;

  constructor(
    public dialogRef: MatDialogRef<ChangeBusinessLocationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) {
    this.applicationUser = this.authService.currentUser;
    this.businessTypeBE = this.authService.currentBusiness;
    this.locationBE = this.authService.currentLocation;

    this.applicationUser.applicationUserBusinesses.forEach((element) => {
      this.businessTypeList.push(element.businessType);
    });

    this.buildForm();
  }

  ngOnInit() {
    this.getLocationByBusinessId(this.businessTypeBE.businessTypeId);

    this.businessTypeId.valueChanges
      .pipe(debounceTime(50), distinctUntilChanged())
      .subscribe((res) => {
        this.getLocationByBusinessId(res);
      });
  }

  private buildForm() {
    this.businessForm = this.formBuilder.group({
      businessTypeId: [this.businessTypeBE.businessTypeId, Validators.required],
      businessType: [""],
      locationId: [this.locationBE.locationId, Validators.required],
      location: [""],
    });
  }

  get businessTypeId() {
    return this.businessForm.get("businessTypeId");
  }
  get businessType() {
    return this.businessForm.get("businessType");
  }
  get locationId() {
    return this.businessForm.get("locationId");
  }
  get location() {
    return this.businessForm.get("location");
  }

  getLocationByBusinessId(businessTypeId: number) {
    this.locationList = [];

    this.applicationUser.applicationUserLocations
      .filter(
        (x) =>
          x.location.businessTypeId == businessTypeId &&
          x.location.locationTypes == LocationTypes.Showroom
      )
      .forEach((element) => {
        this.locationList.push(element.location);
      });

    if (
      this.applicationUser.applicationUserLocations.find(
        (x) => x.locationId == this.locationBE.locationId
      )
    ) {
      this.locationId.setValue(this.locationBE.locationId);
    } else {
      this.locationId.setValue(null);
    }

    this.locationId.updateValueAndValidity();
  }

  doAction() {
    this.businessForm.markAllAsTouched();
    this.alertService.resetStickyMessage();

    if (!this.businessForm.valid) {
      this.alertService.showMessage("Form not valid");
      return;
    } else {
      this.alertService.resetStickyMessage();
      //---------BusinessType---------//
      let businessType: BusinessType = this.businessTypeList.find(
        (x) => x.businessTypeId == this.businessTypeId.value
      );
      this.businessType.setValue(businessType);
      //------------------//

      //---------Location---------//
      let location: Location = this.locationList.find(
        (x) => x.locationId == this.locationId.value
      );
      this.location.setValue(location);
      //------------------//

      this.dialogRef.close({ data: this.businessForm.value });
    }
  }

  closeDialog(): void {
    this.dialogRef.close({ event: "Cancel" });
  }
}
