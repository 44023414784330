import { Injectable } from "@angular/core";
import { EntityService } from "./entity.service";
import { Sales } from "../models/sales";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { CustomResult } from "../models/custom-model/customResult.model";
import { Invoice } from "../models/invoice";

@Injectable({
  providedIn: "root",
})
export class SalesService extends EntityService<Sales> {
  private readonly API_URL = "/api/Sales";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/Sales");
  }

  generateSaleInvoice(invoice: Invoice): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/GenerateSaleInvoice/` + invoice.salesId,
      invoice
    );
  }

  SalesSearchBusinessAndLocationWarehouse(
    term: string,
    businessTypeId: number,
    locationId: number
  ): Observable<Sales[]> {
    return this.httpClient.get<Sales[]>(
      `${this.API_URL}/salesSearchBusinessAndLocationWarehouse?term=` +
        term +
        `&businessTypeId=` +
        businessTypeId +
        `&locationId=` +
        locationId
    );
  }

  loadSalesDocument(id: number): Observable<CustomResult> {
    return this.httpClient.get<CustomResult>(
      `${this.API_URL}/LoadSalesDocument?salesId=` + id
    );
  }

  getSalesById(saleId: number): Observable<Sales> {
    return this.httpClient.get<Sales>(`${this.API_URL}/` + saleId);
  }

  getSalesByCustomerId(customerId: number): Observable<Sales[]> {
    return this.httpClient.get<Sales[]>(
      `${this.API_URL}/GetSalesByCustomerId/` + customerId
    );
  }

  getGeneratedSalesOrderNumber(businessTypeId: number) {
    return this.httpClient.get(
      `${this.API_URL}/GetSalesOrderNumber?businessTypeId=` + businessTypeId,
      {
        responseType: "text",
      }
    );
  }

  getSales(para: any): Observable<Sales[]> {
    return this.httpClient.get<Sales[]>(`${this.API_URL}/GetAllSales`, {
      params: para,
    });
  }

  getAllSales(): Observable<Sales[]> {
    return this.httpClient.get<Sales[]>(`${this.API_URL}`);
  }

  addSales(sales: Sales): Observable<CustomResult> {
    let header = new HttpHeaders({ "content-type": "application/json" });
    return this.httpClient.post<CustomResult>(this.API_URL, sales, {
      headers: header,
    });
  }

  updateSales(sales: Sales): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/` + sales.salesId,
      sales
    );
  }

  modifySales(sales: Sales): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/ModifySales/` + sales.salesId,
      sales
    );
  }

  deleteSales(salesId: number): Observable<Sales> {
    return this.httpClient.delete<Sales>(`${this.API_URL}/` + salesId);
  }

  voidSales(salesId: number, remark: string): Observable<CustomResult> {
    return this.httpClient.get<CustomResult>(
      `${this.API_URL}/VoidSales?salesId=` + salesId + `&remark=` + remark
    );
  }

  deleteSalesDocument(salesId: number): Observable<CustomResult> {
    return this.httpClient.delete<CustomResult>(
      `${this.API_URL}/DeleteSalesDocument/` + salesId
    );
  }
}
