import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Location } from "../models/location";
import { Observable } from "rxjs";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class LocationService extends EntityService<Location> {
  private readonly API_URL = "/api/locations";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/locations");
  }

  getLocationByType(locationType: number): Observable<Location[]> {
    const endpointUrl = `${this.API_URL}/GetLocationByType`;
    return this.httpClient.get<Location[]>(endpointUrl, {
      params: new HttpParams().set("locationType", locationType.toString()),
    });
  }

  getLocationByNameAndBusiness(
    name: string,
    businessTypeId: number,
    locationType: number
  ): Observable<Location[]> {
    return this.httpClient.get<Location[]>(
      `${this.API_URL}/GetLocationByNameAndBusiness?name=` +
        name +
        `&businessTypeId=` +
        businessTypeId +
        `&locationType=` +
        locationType
    );
  }

  getLocationByBusinessId(businessTypeId: number): Observable<Location[]> {
    return this.httpClient.get<Location[]>(
      `${this.API_URL}/getLocationByBusinessId?businessTypeId=` + businessTypeId
    );
  }

  getAllLocations(): Observable<Location[]> {
    return this.httpClient.get<Location[]>(`${this.API_URL}`);
  }

  getLocationsById(id: number): Observable<Location> {
    return this.httpClient.get<Location>(`${this.API_URL}/` + id);
  }

  getLocationsByName(name: string): Observable<Location> {
    return this.httpClient.get<Location>(
      `${this.API_URL}/GetLocationsByName?name=` + name
    );
  }

  addLocation(location: Location): Observable<Location> {
    return this.httpClient.post<Location>(this.API_URL, location);
  }

  updateLocation(location: Location): Observable<Location> {
    return this.httpClient.put<Location>(
      `${this.API_URL}/` + location.locationId,
      location
    );
  }

  deleteLocation(locationId: number): Observable<Location> {
    return this.httpClient.delete<Location>(`${this.API_URL}/` + locationId);
  }
}
