import { TransferOrderLine } from "src/app/models/transferOrderLine";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { TransferOrderLineService } from "../services/transferOrderLine.service";

@Injectable()
export class TransferOrderLineResolver implements Resolve<TransferOrderLine[]> {
  constructor(private service: TransferOrderLineService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<TransferOrderLine[]> {
    const id = Number(route.paramMap.get("id"));
    if (id != 0) {
      return this.service.getTransferOrderLineByTransferOrderId(+id);
    } else {
      return null;
    }
  }
}
