import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { Currency } from "../models/currency";
import { CurrencyService } from "../services/currency.service";

@Injectable()
export class CurrencyResolver implements Resolve<Currency> {
  constructor(private service: CurrencyService) {}

  resolve(): Observable<Currency> {
    return this.service.getActiveCurrency();
  }
}
