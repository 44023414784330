import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { EntityService } from "./entity.service";
import { tap } from "rxjs/operators";
import { Department } from "../models/department";

@Injectable({
  providedIn: "root",
})
export class DepartmentService extends EntityService<Department> {
  private readonly API_URL = "/api/departments";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/departments");
  }

  search(
    filter: { name: string } = { name: "" },
    page = 1
  ): Observable<Department[]> {
    return this.httpClient.get<Department[]>(`${this.API_URL}`).pipe(
      tap((response: Department[]) => {
        response = response.filter((department) =>
          department.departmentName.includes(filter.name)
        );
        return response;
      })
    );
  }

  getAlDepartment(): Observable<Department[]> {
    return this.httpClient.get<Department[]>(`${this.API_URL}`);
  }

  getDepartmentById(id: number): Observable<Department> {
    return this.httpClient.get<Department>(`${this.API_URL}/` + id);
  }

  getDepartmentByName(name: string): Observable<Department> {
    return this.httpClient.get<Department>(
      `${this.API_URL}/GetDepartmentByName?name=` + name
    );
  }

  addDepartment(department: Department): Observable<Department> {
    return this.httpClient.post<Department>(this.API_URL, department);
  }

  updateDepartment(department: Department): Observable<Department> {
    return this.httpClient.put<Department>(
      `${this.API_URL}/` + department.departmentId,
      department
    );
  }

  deleteDepartment(departmentId: number): Observable<Department> {
    return this.httpClient.delete<Department>(
      `${this.API_URL}/` + departmentId
    );
  }
}
