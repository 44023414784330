import { Category } from "src/app/models/category";
import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { CategoryService } from "../services/category.service";
import { AuthService } from "../core/services/auth.service";

@Injectable()
export class CategoryResolver implements Resolve<Category[]> {
  constructor(
    private service: CategoryService,
    private authService: AuthService
  ) {}

  resolve(): Observable<Category[]> {
    if (this.authService.isLoggedIn) {
      if (this.authService.currentBusiness) {
        return this.service.getCategoryByBusinessTypeId(
          this.authService.currentBusiness.businessTypeId
        );
      }
    } else {
      return null;
    }
  }
}
