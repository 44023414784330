import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { DepositAccount } from "../models/depositAccount";
import { DepositAccountService } from "../services/depositAccount.service";
import { AuthService } from "../core/services/auth.service";

@Injectable()
export class DepositAccountResolver implements Resolve<DepositAccount[]> {
  constructor(
    private service: DepositAccountService,
    private authService: AuthService
  ) {}

  resolve(): Observable<DepositAccount[]> {
    if (this.authService.isLoggedIn) {
      if (this.authService.currentBusiness) {
        return this.service.getDepositAccountByBusinessTypeId(
          this.authService.currentBusiness.businessTypeId
        );
      }
    } else {
      return null;
    }
  }
}
