import { Component, ChangeDetectionStrategy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { PrintLayoutService } from "src/app/components/print-layout/print-layout.service";
import { User } from "src/app/core/models/user.model";
import { BusinessType } from "src/app/models/businessType";
import { CustomerType } from "src/app/models/customer";
import { Location } from "src/app/models/location";
import { Sales } from "src/app/models/sales";
import { Tax } from "src/app/models/tax";
import { fadeInOut } from "src/app/services/animations";
import { EntityDataSourceCustomBindReport } from "src/app/services/entity.datasource";
import { SalesLineService } from "src/app/services/salesLine.service";
import { AuthService } from "src/app/core/services/auth.service";

@Component({
  selector: "app-print-usd-quotation",
  templateUrl: "./print-usd-quotation.component.html",
  styleUrls: ["./print-usd-quotation.component.scss"],
  animations: [fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintUsdQuotationComponent implements OnInit {
  displayedColumnsSOLine: string[] = [
    "itemCode",
    "description",
    "uom",
    "rate",
    "qty",
    "discount",
    "amount",
  ];

  id: number;
  sales: Sales;
  tax: Tax;
  exchangeRate: number = 0;
  dataSourceSOLine: any[] = [];
  dataSource: EntityDataSourceCustomBindReport;
  readonly endpointUrl = "/api/salesLines/GetSalesLineView";
  lineCount: number = 0;

  businessTypeBE: BusinessType;
  locationBE: Location;
  applicationUser: User;

  constructor(
    private printLayoutService: PrintLayoutService,
    private salesLineService: SalesLineService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.applicationUser = this.authService.currentUser;
    this.businessTypeBE = this.authService.currentBusiness;
    this.locationBE = this.authService.currentLocation;

    this.id = Number(this.route.snapshot.paramMap.get("id"));

    this.route.data.subscribe(({ sales }) => {
      if (sales) {
        this.sales = sales;
      }
    });

    this.route.data.subscribe(({ currency }) => {
      if (currency) {
        this.exchangeRate = currency.exchangeRate;
      }
    });

    this.route.data.subscribe(({ tax }) => {
      if (tax) {
        this.tax = tax;
      }
    });
  }

  ngOnInit() {
    this.dataSource = new EntityDataSourceCustomBindReport(
      this.salesLineService,
      this.endpointUrl
    );

    this.dataSource.loadReport(this.id, this.displayedColumnsSOLine);
    this.dataSource.entityCount$.subscribe((c) => (this.lineCount = c));
    this.dataSource.currentData$.subscribe((rows) => {
      this.dataSourceSOLine = rows;
    });
  }

  getDescription(row: any) {
    let description: string = row.productName;

    if (row.remark) {
      description += " (" + row.remark + ")";
    }
    if (row.storageRemark) {
      description += " ~ " + row.storageRemark + " ~ ";
    }
    return description;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.printLayoutService.onDataReady();
    }, 1000);
  }

  getCustomerType(value) {
    return CustomerType[value];
  }

  financialFormat(x) {
    x = x / this.exchangeRate;
    return x;
  }
}
