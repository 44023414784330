import { Component, OnInit } from "@angular/core";
import { User } from "src/app/core/models/user.model";
import { ActivatedRoute } from "@angular/router";

import { AuthService } from "src/app/core/services/auth.service";

@Component({
  selector: "app-print-layout",
  templateUrl: "./print-layout.component.html",
  styleUrls: ["./print-layout.component.scss"],
})
export class PrintLayoutComponent implements OnInit {
  applicationUser: User;
  currentYear: number;
  id: number;
  todayDate: Date = new Date();
  logoPath: string;

  printSalesBusinessName: string;
  denominationHeader: string;
  printLocationAddress: string;
  printLocationContactNumbers: string;
  printLocationEmails: string;
  printTinNumber: string;
  printCreatedBy: string;
  screenType: string;

  constructor(private route: ActivatedRoute, private authService: AuthService) {
    this.applicationUser = this.authService.currentUser;

    this.id = Number(this.route.snapshot.paramMap.get("id"));
  }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    this.printCreatedBy = localStorage.getItem("printCreatedBy");
    this.screenType = localStorage.getItem("screenType");

    if (this.screenType == "denomination") {
      this.denominationHeader = localStorage.getItem("denominationHeader");
      this.printLocationAddress = localStorage.getItem("printLocationAddress");
    } else {
      this.logoPath =
        localStorage.getItem("printLogoPath") != "/null/null"
          ? localStorage.getItem("printLogoPath")
          : "/Upload/DefaultImages/default-image.png";
      this.printSalesBusinessName = localStorage.getItem(
        "printSalesBusinessName"
      );
      this.printLocationAddress = localStorage.getItem("printLocationAddress");
      this.printLocationContactNumbers = localStorage.getItem(
        "printLocationContactNumbers"
      );
      this.printLocationEmails = localStorage.getItem("printLocationEmails");
      this.printTinNumber = localStorage.getItem("printTinNumber");
    }
  }
}
