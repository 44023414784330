import { Injectable } from "@angular/core";
import { SalesLine, SalesLineView } from "../models/salesLine";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { EntityService } from "./entity.service";
import { CustomResult } from "../models/custom-model/customResult.model";

@Injectable({
  providedIn: "root",
})
export class SalesLineService extends EntityService<SalesLine> {
  private readonly API_URL = "/api/SalesLines";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/SalesLines");
  }

  getAllSalesLines(): Observable<SalesLine[]> {
    return this.httpClient.get<SalesLine[]>(`${this.API_URL}`);
  }

  getSalesLineBySalesOrderId(id: number): Observable<SalesLine[]> {
    return this.httpClient.get<SalesLine[]>(
      `${this.API_URL}/GetSalesLineBySalesOrderId/` + id
    );
  }

  getSalesLineViewBySalesOrderId(id: number): Observable<SalesLineView[]> {
    return this.httpClient.get<SalesLineView[]>(
      `${this.API_URL}/GetSalesLineViewBySalesOrderId/` + id
    );
  }

  getGroupedSalesLineBySalesOrderId(id: number): Observable<SalesLine[]> {
    return this.httpClient.get<SalesLine[]>(
      `${this.API_URL}/GetGroupedSalesLineBySalesOrderId/` + id
    );
  }

  addSalesLine(salesLine: SalesLine): Observable<CustomResult> {
    let header = new HttpHeaders({ "content-type": "application/json" });
    return this.httpClient.post<CustomResult>(this.API_URL, salesLine, {
      headers: header,
    });
  }

  updateSalesLine(salesLine: SalesLine): Observable<SalesLine> {
    return this.httpClient.put<SalesLine>(
      `${this.API_URL}/` + salesLine.salesLineId,
      salesLine
    );
  }

  deleteSalesLine(salesLineId: number): Observable<SalesLine> {
    return this.httpClient.delete<SalesLine>(`${this.API_URL}/` + salesLineId);
  }
}
