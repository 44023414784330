import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EntityService } from "./entity.service";
import { Observable } from "rxjs";
import { DepositAccount } from "../models/depositAccount";

@Injectable({
  providedIn: "root",
})
export class DepositAccountService extends EntityService<DepositAccount> {
  private readonly API_URL = "/api/DepositAccounts";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/DepositAccounts");
  }

  getAllDepositAccounts(): Observable<DepositAccount[]> {
    return this.httpClient.get<DepositAccount[]>(`${this.API_URL}`);
  }

  getDepositAccountByBusinessTypeId(
    businessTypeId: number
  ): Observable<DepositAccount[]> {
    return this.httpClient.get<DepositAccount[]>(
      `${this.API_URL}/GetDepositAccountByBusinessTypeId?businessTypeId=` +
        businessTypeId
    );
  }

  addDepositAccount(
    depositAccount: DepositAccount
  ): Observable<DepositAccount> {
    return this.httpClient.post<DepositAccount>(this.API_URL, depositAccount);
  }

  updateDepositAccount(
    depositAccount: DepositAccount
  ): Observable<DepositAccount> {
    return this.httpClient.put<DepositAccount>(
      `${this.API_URL}/` + depositAccount.depositAccountId,
      depositAccount
    );
  }

  deleteDepositAccount(depositAccountId: number): Observable<DepositAccount> {
    return this.httpClient.delete<DepositAccount>(
      `${this.API_URL}/` + depositAccountId
    );
  }
}
