import { Costing } from "./../models/costing.model";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { CostingService } from "../services/costing.service";

@Injectable()
export class CostingResolver implements Resolve<Costing> {
  constructor(private service: CostingService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Costing> {
    const id = Number(route.paramMap.get("id"));

    if (id != 0) {
      return this.service.getCostingById(+id);
    }
  }
}
