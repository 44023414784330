import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { Customer, CustomerType } from "../models/customer";
import { CustomerService } from "../services/customer.service";

@Injectable()
export class FinancierResolver implements Resolve<Customer[]> {
  constructor(private service: CustomerService) {}

  resolve(): Observable<Customer[]> {
    return this.service.getCustomersByType(CustomerType.Financier);
  }
}
