import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { User } from "../core/models/user.model";
import { ApplicationUserService } from "../services/applicationuser.service";

@Injectable()
export class ApplicationUserResolver implements Resolve<User[]> {
  constructor(private service: ApplicationUserService) {}

  resolve(): Observable<User[]> {
    return this.service.getAllApplicationUser();
  }
}
