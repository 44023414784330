import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { TransferOrder } from "../models/transferOrder";
import { TransferOrderService } from "../services/transferOrder.service";

@Injectable()
export class TransferOrderResolver implements Resolve<TransferOrder> {
  constructor(private service: TransferOrderService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<TransferOrder> {
    const id = Number(route.paramMap.get("id"));
    if (id != 0) {
      return this.service.getTransferOrderById(+id);
    } else {
      return null;
    }
  }
}
