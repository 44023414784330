import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ReceivingLine, ReceivingLineView } from "src/app/models/receivingLine";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class ReceivingLineService extends EntityService<ReceivingLine> {
  private readonly API_URL = "/api/ReceivingLines";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/ReceivingLines");
  }

  getAllReceivingLine(): Observable<ReceivingLine[]> {
    return this.httpClient.get<ReceivingLine[]>(`${this.API_URL}`);
  }
  getReceivingLineById(id: number): Observable<ReceivingLine> {
    return this.httpClient.get<ReceivingLine>(`${this.API_URL}/` + id);
  }

  getReceivingLineByReceivingId(
    receivingId: number
  ): Observable<ReceivingLine[]> {
    return this.httpClient.get<ReceivingLine[]>(
      `${this.API_URL}/GetReceivingLineByReceivingId/` + receivingId
    );
  }

  getCompletedReceivingLineByReceivingId(
    receivingId: number
  ): Observable<ReceivingLineView[]> {
    return this.httpClient.get<ReceivingLineView[]>(
      `${this.API_URL}/GetCompletedReceivingLineByReceivingId/` + receivingId
    );
  }

  addReceivingLine(receivingLine: ReceivingLine): Observable<ReceivingLine> {
    return this.httpClient.post<ReceivingLine>(this.API_URL, receivingLine);
  }
  updateReceivingLine(receivingLine: ReceivingLine): Observable<ReceivingLine> {
    return this.httpClient.put<ReceivingLine>(
      `${this.API_URL}/` + receivingLine.receivingLineId,
      receivingLine
    );
  }
  deleteReceivingLine(receivingLineId: number): Observable<ReceivingLine> {
    return this.httpClient.delete<ReceivingLine>(
      `${this.API_URL}/` + receivingLineId
    );
  }
}
