import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { EntityService } from "./entity.service";
import { Referral } from "../models/referral";

@Injectable({
  providedIn: "root",
})
export class ReferralService extends EntityService<Referral> {
  private readonly API_URL = "/api/referrals";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/referrals");
  }

  get referralCount(): number {
    return this.referralCount;
  }

  getAllReferrals(): Observable<Referral[]> {
    return this.httpClient.get<Referral[]>(`${this.API_URL}`);
  }

  addReferral(referral: Referral): Observable<Referral> {
    return this.httpClient.post<Referral>(this.API_URL, referral);
  }

  updateReferral(referral: Referral): Observable<Referral> {
    return this.httpClient.put<Referral>(
      `${this.API_URL}/` + referral.id,
      referral
    );
  }

  deleteReferral(referralId: number): Observable<Referral> {
    return this.httpClient.delete<Referral>(`${this.API_URL}/` + referralId);
  }
}
