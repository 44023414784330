import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Costing } from "src/app/models/costing.model";
import { CustomResult } from "src/app/models/custom-model/customResult.model";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class CostingService extends EntityService<Costing> {
  private readonly API_URL = "/api/costings";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/costings");
  }

  getAllCostings(): Observable<Costing[]> {
    return this.httpClient.get<Costing[]>(`${this.API_URL}`);
  }

  getOpenCostings(businessTypeId: number): Observable<Costing[]> {
    return this.httpClient.get<Costing[]>(
      `${this.API_URL}/GetOpenCostings?businessTypeId=` + businessTypeId
    );
  }

  getCostingById(id: number): Observable<Costing> {
    return this.httpClient.get<Costing>(`${this.API_URL}/` + id);
  }

  getCostingByClearanceId(clearanceId: number): Observable<Costing> {
    return this.httpClient.get<Costing>(
      `${this.API_URL}/GetCostingByClearanceId/` + clearanceId
    );
  }

  costingSearchByBusiness(
    term: string,
    businessTypeId: number
  ): Observable<Costing[]> {
    return this.httpClient.get<Costing[]>(
      `${this.API_URL}/CostingSearchByBusiness?term=` +
        term +
        `&businessTypeId=` +
        businessTypeId
    );
  }

  addCosting(costing: Costing): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(this.API_URL, costing);
  }

  updateCosting(costing: Costing): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/` + costing.costingId,
      costing
    );
  }

  deleteCosting(costingId: number): Observable<Costing> {
    return this.httpClient.delete<Costing>(`${this.API_URL}/` + costingId);
  }
}
