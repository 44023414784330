import { Receiving } from "../../../../models/receiving";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { fadeInOut } from "src/app/services/animations";
import { LocationTypes } from "src/app/models/location";
import { Tax } from "src/app/models/tax";
import { ReceivingLine } from "src/app/models/receivingLine";
import { PrintLayoutService } from "src/app/components/print-layout/print-layout.service";

@Component({
  selector: "app-receiving-print",
  templateUrl: "./receiving-print.component.html",
  styleUrls: ["./receiving-print.component.scss"],
  animations: [fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceivingPrintComponent implements OnInit {
  public dataSourceSOLine: any[] = [];
  displayedColumns = [
    "itemCode",
    "productCode",
    "productName",
    "qtyReceived",
    "supplierCost",
    "costingCost",
    "sellingPrice",
    "profit",
  ];

  id: number;
  receiving: Receiving;
  tax: Tax;

  constructor(
    private printLayoutService: PrintLayoutService,
    private route: ActivatedRoute
  ) {
    this.id = Number(this.route.snapshot.paramMap.get("id"));

    this.route.data.subscribe(({ receiving }) => {
      if (receiving) {
        this.receiving = receiving;
      }
    });
    this.route.data.subscribe(({ receivingLine }) => {
      if (receivingLine) {
        this.dataSourceSOLine = receivingLine;
      }
    });
    this.route.data.subscribe(({ tax }) => {
      if (tax) {
        this.tax = tax;
      }
    });
  }

  getLocationType(value) {
    return LocationTypes[value];
  }

  getCostingPrice(row: ReceivingLine) {
    return row.receiving.costing.costingLine?.length > 0
      ? row.receiving.costing.costingLine
          .filter((x) => x.productId == row.productId)
          .pop().supplierCostPrice
      : 0;
  }

  getTotalQuantitySum() {
    return this.dataSourceSOLine?.length > 0
      ? this.dataSourceSOLine
          .map((t) => t.qtyReceived)
          .reduce((acc, value) => acc + value, 0)
      : 0;
  }

  getTotalSupplierCostPriceSum() {
    return this.dataSourceSOLine?.length > 0
      ? this.dataSourceSOLine
          .map((t) => t.qtyReceived * this.getCostingPrice(t))
          .reduce((acc, value) => acc + value, 0)
      : 0;
  }

  getTotalCostingCostSum() {
    return this.dataSourceSOLine?.length > 0
      ? this.dataSourceSOLine
          .map((t) => t.qtyReceived * t.costPrice)
          .reduce((acc, value) => acc + value, 0)
      : 0;
  }

  getTotalSellingPriceSum() {
    return this.dataSourceSOLine?.length > 0
      ? this.dataSourceSOLine
          .map((t) => t.qtyReceived * t.sellingPrice)
          .reduce((acc, value) => acc + value, 0)
      : 0;
  }

  getTotalProfitSum() {
    return this.dataSourceSOLine?.length > 0
      ? this.dataSourceSOLine
          .map((t) => t.qtyReceived * (t.sellingPrice - t.costPrice))
          .reduce((acc, value) => acc + value, 0)
      : 0;
  }

  ngOnInit() {
    setTimeout(() => {
      this.printLayoutService.onDataReady();
    }, 1000);
  }
}
