import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ProductLocation } from "src/app/models/productLocation";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class ProductLocationService extends EntityService<ProductLocation> {
  private readonly API_URL = "/api/ProductLocations";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/ProductLocations");
  }

  getAllProductLocations(): Observable<ProductLocation[]> {
    return this.httpClient.get<ProductLocation[]>(`${this.API_URL}`);
  }

  updateSatusOfProductLocation(productLocationId): Observable<any> {
    return this.httpClient.get<any>(
      `${this.API_URL}/UpdateSatusOfProductLocation?id=` + productLocationId
    );
  }

  addProductLocation(
    ProductLocation: ProductLocation
  ): Observable<ProductLocation> {
    return this.httpClient.post<ProductLocation>(this.API_URL, ProductLocation);
  }

  updateProductLocation(
    ProductLocation: ProductLocation
  ): Observable<ProductLocation> {
    return this.httpClient.put<ProductLocation>(
      `${this.API_URL}/` + ProductLocation.productLocationId,
      ProductLocation
    );
  }

  deleteProductLocation(id: number): Observable<ProductLocation> {
    return this.httpClient.delete<ProductLocation>(`${this.API_URL}/` + id);
  }
}
