import { Product } from "./product";

export class MissedProduct {
  constructor(
    missedProductId?: number,
    remark?: string,
    productId?: number,
    product?: Product
  ) {
    this.missedProductId = missedProductId;
    this.remark = remark;
    this.productId = productId;
    this.product = product;
  }

  missedProductId: number;
  remark: string;
  productId: number;
  product: Product;
}
