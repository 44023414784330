import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CustomResult } from "src/app/models/custom-model/customResult.model";
import { StockAdjustment } from "src/app/models/stockAdjustment";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class StockAdjustmentService extends EntityService<StockAdjustment> {
  private readonly API_URL = "/api/StockAdjustments";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/StockAdjustments");
  }

  getStockAdjustmentNumber(businessTypeId: number) {
    return this.httpClient.get(
      `${this.API_URL}/GetStockAdjustmentNumber/` + businessTypeId,
      {
        responseType: "text",
      }
    );
  }

  getStockAdjustmentCross(stockAdjustmentId: number): Observable<CustomResult> {
    return this.httpClient.get<CustomResult>(
      `${this.API_URL}/GetStockAdjustmentCross?stockAdjustmentId=` +
        stockAdjustmentId
    );
  }

  getStockAdjustmentBusinessCross(
    businessTypeId: number
  ): Observable<CustomResult> {
    return this.httpClient.get<CustomResult>(
      `${this.API_URL}/GetStockAdjustmentBusinessCross?businessTypeId=` +
        businessTypeId
    );
  }

  stockAdjustmentSearchByBusiness(
    term: string,
    businessTypeId: number
  ): Observable<StockAdjustment[]> {
    return this.httpClient.get<StockAdjustment[]>(
      `${this.API_URL}/SearchStockAdjustment?term=` +
        term +
        `&businessTypeId=` +
        businessTypeId
    );
  }

  geStockAdjustmentById(id: number): Observable<StockAdjustment> {
    return this.httpClient.get<StockAdjustment>(`${this.API_URL}/` + id);
  }

  addStockAdjustment(
    stockAdjustment: StockAdjustment
  ): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(this.API_URL, stockAdjustment);
  }

  updateStockAdjustment(
    stockAdjustment: StockAdjustment
  ): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/` + stockAdjustment.stockAdjustmentId,
      stockAdjustment
    );
  }

  deleteStockAdjustment(
    stockAdjustmentId: number
  ): Observable<StockAdjustment> {
    return this.httpClient.delete<StockAdjustment>(
      `${this.API_URL}/` + stockAdjustmentId
    );
  }

  completeStockAdjustment(
    stockAdjustment: StockAdjustment
  ): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/CompleteStockAdjustment/` +
        stockAdjustment.stockAdjustmentId,
      stockAdjustment
    );
  }
}
