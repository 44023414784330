import { Injectable } from "@angular/core";
import { EntityService } from "./entity.service";
import { HttpClient } from "@angular/common/http";
import { PaymentTerm } from "../models/paymentTerm";
import { Observable } from "rxjs";
import { Payment } from "../models/payment";
import { CustomResult } from "../models/custom-model/customResult.model";
import { SalesLine } from "../models/salesLine";

@Injectable({
  providedIn: "root",
})
export class PaymentService extends EntityService<PaymentTerm> {
  private readonly API_URL = "/api/payments";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/payments");
  }

  getGeneratedReceiptNumber(businessTypeId: number) {
    return this.httpClient.get(
      `${this.API_URL}/GetReceiptNumber/` + businessTypeId,
      {
        responseType: "text",
      }
    );
  }

  paymentSearchBusinessAndLocationWarehouse(
    term: string,
    businessTypeId: number,
    locationId: number
  ): Observable<Payment[]> {
    return this.httpClient.get<Payment[]>(
      `${this.API_URL}/paymentSearchBusinessAndLocationWarehouse?term=` +
        term +
        `&businessTypeId=` +
        businessTypeId +
        `&locationId=` +
        locationId
    );
  }

  getAllPayments(): Observable<Payment[]> {
    return this.httpClient.get<Payment[]>(`${this.API_URL}`);
  }

  getPaymentsById(id: number): Observable<Payment> {
    return this.httpClient.get<Payment>(`${this.API_URL}/` + id);
  }

  getPaymentsByInvoiceId(id: number): Observable<Payment> {
    return this.httpClient.get<Payment>(
      `${this.API_URL}/GetPaymentsByInvoiceId/` + id
    );
  }

  addPayment(payment: Payment): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(this.API_URL, payment);
  }

  updatePayment(payment: Payment): Observable<Payment> {
    return this.httpClient.put<Payment>(
      `${this.API_URL}/` + payment.paymentId,
      payment
    );
  }

  realizePayment(payment: Payment): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/RealizePayment/` + payment.paymentId,
      payment
    );
  }

  deletePayment(paymentId: number): Observable<Payment> {
    return this.httpClient.delete<Payment>(`${this.API_URL}/` + paymentId);
  }

  getPaymentByReferenceNumber(reference: string): Observable<Payment> {
    return this.httpClient.get<Payment>(
      `${this.API_URL}/GetPaymentByReferenceNumber?reference=` + reference
    );
  }

  loadSlipImage(paymentId: number): Observable<CustomResult> {
    return this.httpClient.get<CustomResult>(
      `${this.API_URL}/LoadSlipImage?paymentId=` + paymentId
    );
  }

  deleteSlipImage(id: number): Observable<CustomResult> {
    return this.httpClient.delete<CustomResult>(
      `${this.API_URL}/DeleteSlipImage/` + id
    );
  }

  download(docName): Observable<Blob> {
    return this.httpClient.post<Blob>(
      `${this.API_URL}/ExportUploadTemplate?docName=` + docName,
      docName,
      { responseType: "blob" as "json" }
    );
  }
}
