import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { EntityService } from "./entity.service";
import { ApplicationRoleRegister, Role } from "../core/models/role.model";
import { CustomResult } from "../models/custom-model/customResult.model";

@Injectable({
  providedIn: "root",
})
export class ApplicationRoleService extends EntityService<Role> {
  private readonly API_URL = "/api/ApplicationRoles";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/ApplicationRoles");
  }

  getApplicationRoles(): Observable<Role[]> {
    return this.httpClient.get<Role[]>(`${this.API_URL}`);
  }

  getApplicationRoleById(id: number): Observable<Role> {
    return this.httpClient.get<Role>(`${this.API_URL}/` + id);
  }

  getApplicationRoleByName(name: string): Observable<Role> {
    return this.httpClient.get<Role>(
      `${this.API_URL}/getApplicationRoleByName?name=` + name
    );
  }

  addApplicationRole(
    roleRegister: ApplicationRoleRegister
  ): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(this.API_URL, roleRegister);
  }

  updateApplicationRole(
    roleRegister: ApplicationRoleRegister
  ): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/` + roleRegister.id,
      roleRegister
    );
  }

  deleteApplicationRole(id: string): Observable<CustomResult> {
    return this.httpClient.delete<CustomResult>(`${this.API_URL}/` + id);
  }
}
