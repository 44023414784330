import { UnitOfMeasureService } from "src/app/services/unitOfMeasure.service";
import { UnitOfMeasure } from "src/app/models/unitOfMeasure";
import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";

@Injectable()
export class UnitOfMeasureResolver implements Resolve<UnitOfMeasure[]> {
  constructor(private service: UnitOfMeasureService) {}

  resolve(): Observable<UnitOfMeasure[]> {
    return this.service.getAlUnitOfMeasures();
  }
}
