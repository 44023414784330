import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Discount } from "../models/discount";
import { EntityService } from "./entity.service";
import { CustomResult } from "../models/custom-model/customResult.model";

@Injectable({
  providedIn: "root",
})
export class DiscountService extends EntityService<Discount> {
  private readonly API_URL = "/api/discounts";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/discounts");
  }

  getAllDiscounts(): Observable<Discount[]> {
    return this.httpClient.get<Discount[]>(`${this.API_URL}`);
  }

  getDiscountsByApplyStatus(applyStatus: number): Observable<Discount[]> {
    return this.httpClient.get<Discount[]>(
      `${this.API_URL}/GetDiscountsByApplyStatus/` + applyStatus
    );
  }

  getAllActiveDiscountByBusinessId(
    businessTypeId: number
  ): Observable<Discount[]> {
    return this.httpClient.get<Discount[]>(
      `${this.API_URL}/GetAllActiveDiscountByBusinessId/` + businessTypeId
    );
  }

  getAllActiveDiscounts(): Observable<Discount[]> {
    return this.httpClient.get<Discount[]>(
      `${this.API_URL}/getAllActiveDiscounts`
    );
  }

  addDiscount(discount: Discount): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(this.API_URL, discount);
  }

  checkExist(column: string, value: string) {
    let colVal = new ColumnValue();
    colVal.column = column;
    colVal.value = value;
    return this.httpClient.get<Discount>(`${this.API_URL}/` + column);
  }

  updateDiscount(discount: Discount): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/` + discount.discountId,
      discount
    );
  }

  deleteDiscount(id: number): Observable<CustomResult> {
    return this.httpClient.delete<CustomResult>(`${this.API_URL}/` + id);
  }
}

export class ColumnValue {
  column: string;
  value: string;
}
