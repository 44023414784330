import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { Technician } from "../models/technician";
import { TechnicianService } from "../services/technician.service";

@Injectable()
export class TechnicianResolver implements Resolve<Technician[]> {
  constructor(private service: TechnicianService) {}

  resolve(): Observable<Technician[]> {
    return this.service.getAlTechnicians();
  }
}
