import { Clearance } from "../models/clearance.model";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { ClearanceService } from "../services/clearance.service";

@Injectable()
export class ClearanceResolver implements Resolve<Clearance> {
  constructor(private service: ClearanceService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Clearance> {
    const id = Number(route.paramMap.get("id"));
    if (id != 0) {
      return this.service.getClearanceById(+id);
    } else {
      return null;
    }
  }
}
