import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EntityService } from "./entity.service";
import { Observable } from "rxjs";
import { Country } from "../models/country";

@Injectable({
  providedIn: "root",
})
export class CountryService extends EntityService<Country> {
  private readonly API_URL = "/api/Countries";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/Countries");
  }

  getActiveCountry(): Observable<Country> {
    return this.httpClient.get<Country>(`${this.API_URL}/GetActiveCountry`);
  }

  getAllCountries(): Observable<Country[]> {
    return this.httpClient.get<Country[]>(`${this.API_URL}`);
  }

  updateSatusOfCountry(countryId): Observable<any> {
    return this.httpClient.get<any>(
      `${this.API_URL}/UpdateSatusOfCountry?id=` + countryId
    );
  }

  getCountryByName(name: string): Observable<Country> {
    return this.httpClient.get<Country>(
      `${this.API_URL}/GetCountryByName?name=` + name
    );
  }

  addCountry(country: Country): Observable<Country> {
    return this.httpClient.post<Country>(this.API_URL, country);
  }

  updateCountry(country: Country): Observable<Country> {
    return this.httpClient.put<Country>(
      `${this.API_URL}/` + country.countryId,
      Country
    );
  }

  deleteCountry(countryId: number): Observable<Country> {
    return this.httpClient.delete<Country>(`${this.API_URL}/` + countryId);
  }
}
