import { Directive, Input, HostListener } from "@angular/core";

@Directive({
  selector: "[appPrintMatTable]",
})
export class PrintMatTableDirective {
  public _printStyle = [];

  @Input() printSectionId: string;

  @Input() printTitle: string;

  @Input() useExistingCss = false;

  @Input()
  set printStyle(values: { [key: string]: { [key: string]: string } }) {
    for (let key in values) {
      if (values.hasOwnProperty(key)) {
        this._printStyle.push(
          (key + JSON.stringify(values[key])).replace(/['"]+/g, "")
        );
      }
    }
    this.returnStyleValues();
  }

  public returnStyleValues() {
    return `<style> ${this._printStyle.join(" ").replace(/,/g, ";")} </style>`;
  }

  private _styleSheetFile = "";

  @Input()
  set styleSheetFile(cssList: string) {
    let linkTagFn = (cssFileName) =>
      `<link rel="stylesheet" type="text/css" href="${cssFileName}">`;
    if (cssList.indexOf(",") !== -1) {
      const valueArr = cssList.split(",");
      for (let val of valueArr) {
        this._styleSheetFile = this._styleSheetFile + linkTagFn(val);
      }
    } else {
      this._styleSheetFile = linkTagFn(cssList);
    }
  }

  private returnStyleSheetLinkTags() {
    return this._styleSheetFile;
  }
  private getElementTag(tag: keyof HTMLElementTagNameMap): string {
    const html: string[] = [];
    const elements = document.getElementsByTagName(tag);
    for (let index = 0; index < elements?.length; index++) {
      html.push(elements[index].outerHTML);
    }
    return html.join("\r\n");
  }

  @HostListener("click")
  public print(): void {
    let printContents,
      popupWin,
      styles = "",
      links = "";

    if (this.useExistingCss) {
      styles = this.getElementTag("style");
      links = this.getElementTag("link");
    }

    printContents = document.getElementById(this.printSectionId).innerHTML;
    popupWin = window.open("", "_blank", "top=0,left=0,height=auto,width=auto");
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>${this.printTitle ? this.printTitle : ""}</title>
          ${this.returnStyleValues()}
          ${this.returnStyleSheetLinkTags()}
          ${styles}
          ${links}
        </head>
        <body onload="window.print(); setTimeout(()=>{ window.close(); }, 0)">
          ${printContents}
        </body>
      </html>`);
    popupWin.document.close();
  }
}
