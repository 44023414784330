import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { LoaderService } from "src/app/core/services/loader.service";

@Component({
  selector: "app-loader-dialog",
  templateUrl: "./loader-dialog.component.html",
  styleUrls: ["./loader-dialog.component.scss"],
})
export class LoaderDialogComponent {
  // isLoading: Subject<boolean> = this.loaderService.isLoading;

  constructor(public loaderService: LoaderService) {}
}
