import { AuditableEntity } from "./custom-model/auditableEntity";
import { DeliveryType } from "./deliveryOrder";
import { Discount } from "./discount";
import { Location } from "./location";
import { Product } from "./product";
import { ProductCost } from "./productCost";
import { ProductPrice } from "./productPrice";
import { Sales } from "./sales";

export class SalesLine extends AuditableEntity {
  salesLineId: number;

  salesId: number;
  sales: Sales;

  productId: number;
  product: Product;

  productPriceId: number;
  productPrice: ProductPrice;

  productCostId: number;
  productCost: ProductCost;

  locationId: number;
  location: Location;

  lineDiscountId: number | null;
  lineDiscount: Discount;
  lineDiscountRate: number;

  qty: number;
  remark: string;
  deliveryType: DeliveryType;
  releasedStatus: ReleasedStatus;

  bundleProductSalesLineId: number | null;

  isEditable: boolean;
}

export class SalesLineView {
  salesid: number;
  itemCode: string;
  productCode: string;
  productName: string;
  unitName: string;
  qty: number;
  costPrice: number;
  sellingPrice: number;
  promo: number;
  amount: number;
}

export interface SalesLineViewParameters {
  salesId: number;
}

export interface SalesLineParameters {
  salesId: number;
}

export interface SalesLineDeliveryOrderParameters {
  salesId: number;
  locationId: number;
}

export enum ReleasedStatus {
  Released = 1,
  Pending = 2,
}
