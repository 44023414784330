import { Costing } from "../../../../models/costing.model";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { fadeInOut } from "src/app/services/animations";
import { LocationTypes } from "src/app/models/location";
import { CostingLine } from "src/app/models/costingLine";
import { PrintLayoutService } from "src/app/components/print-layout/print-layout.service";

@Component({
  selector: "app-costing-profit-print",
  templateUrl: "./costing-profit-print.component.html",
  styleUrls: ["./costing-profit-print.component.scss"],
  animations: [fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CostingProfitPrintComponent implements OnInit {
  public dataSourceSOLine: CostingLine[] = [];
  displayedColumns = [
    "productCode",
    "productName",
    "unitCostEach",
    "sellingPrice",
    "profit",
    "qtyReceived",
    "costingCostTotal",
    "priceTotal",
    "profitTotal",
  ];

  id: number;
  costing: Costing;
  gstRate: number = 0;
  constructor(
    private printLayoutService: PrintLayoutService,
    private route: ActivatedRoute
  ) {
    this.id = Number(this.route.snapshot.paramMap.get("id"));

    this.route.data.subscribe(({ costing }) => {
      if (costing) {
        this.costing = costing;
      }
    });

    this.route.data.subscribe(({ costingLine }) => {
      if (costingLine) {
        this.dataSourceSOLine = costingLine;
      }
    });

    this.route.data.subscribe(({ tax }) => {
      if (tax) {
        this.gstRate = tax.rate / 100;
      }
    });
  }

  getLocationType(value) {
    return LocationTypes[value];
  }

  getSupplierCost(row: CostingLine) {
    return row.supplierCostPrice * row.qtyReceived;
  }

  getCostingCost(row: CostingLine) {
    return row.unitCostEach * row.qtyReceived;
  }

  getPrice(row: CostingLine) {
    return row.sellingPrice * row.qtyReceived;
  }

  profitTotal(row: CostingLine) {
    return row.profitMargin * row.qtyReceived;
  }

  getCostingCostTotal() {
    return this.dataSourceSOLine?.length > 0
      ? this.dataSourceSOLine
          .map((t) => this.getCostingCost(t))
          .reduce((acc, value) => acc + value, 0)
      : 0;
  }

  getTotalQuantitySum() {
    return this.dataSourceSOLine?.length > 0
      ? this.dataSourceSOLine
          .map((t) => t.qtyReceived)
          .reduce((acc, value) => acc + value, 0)
      : 0;
  }

  getSellingPriceTotal() {
    return this.dataSourceSOLine?.length > 0
      ? this.dataSourceSOLine
          .map((t) => this.getPrice(t))
          .reduce((acc, value) => acc + value, 0)
      : 0;
  }

  getProfitTotal() {
    return this.getSellingPriceTotal() - this.getCostingCostTotal();
  }

  ngOnInit() {
    setTimeout(() => {
      this.printLayoutService.onDataReady();
    }, 1000);
  }
}
