import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { fadeInOut } from "../../services/animations";
import { AuthService } from "src/app/core/services/auth.service";
import { User } from "src/app/core/models/user.model";
import { BusinessType } from "src/app/models/businessType";
import { Location } from "src/app/models/location";
import * as Chart from "chart.js";
import { EntityDataSourceCustomBindReport } from "src/app/services/entity.datasource";
import { DashboardService } from "src/app/services/dashboard.service";
import {
  AlertService,
  MessageSeverity,
} from "src/app/core/services/alert.service";
import { fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  animations: [fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
  applicationUser: User;
  businessTypeBE: BusinessType;
  locationBE: Location;
  saleChart: Chart;

  displayedColumns = [
    "itemCode",
    "productCode",
    "productName",
    "brandName",
    "modelName",
    "categoryName",
  ];

  public dataSource: EntityDataSourceCustomBindReport;
  private readonly endpointUrl = "/api/Dashboard/GetLatestItems";
  newProductsDataSourceRows: any[];
  reportCount: number;

  constructor(
    public dashboardService: DashboardService,
    private authService: AuthService,
    private alertService: AlertService
  ) {
    this.applicationUser = this.authService.currentUser;
    this.businessTypeBE = this.authService.currentBusiness;
    this.locationBE = this.authService.currentLocation;
  }

  ngOnInit() {
    if (this.businessTypeBE && this.locationBE) {
      this.loadSalesData();
      this.loadLatestProducts();
    }
  }

  public ngAfterViewInit() {
    if (this.businessTypeBE && this.locationBE) {
      this.dashboardService
        .getExpirePromotions(this.businessTypeBE.businessTypeId)
        .subscribe((result: number) => {
          if (result > 0) {
            this.alertService.showMessage(
              "Dashboard",
              result + " Promotions are expired...!",
              MessageSeverity.success
            );
          }
        });

      this.dashboardService
        .getExpireQuotations(
          this.businessTypeBE.businessTypeId,
          this.locationBE.locationId
        )
        .subscribe((result: number) => {
          if (result > 0) {
            this.alertService.showMessage(
              "Dashboard",
              result + " Quotations are expired...!",
              MessageSeverity.success
            );
          }
        });

      this.dashboardService
        .getCancelZeroItemsSales(
          this.businessTypeBE.businessTypeId,
          this.locationBE.locationId
        )
        .subscribe((result: number) => {
          if (result > 0) {
            this.alertService.showMessage(
              "Dashboard",
              result + " Zero items sales are cancelled...!",
              MessageSeverity.success
            );
          }
        });
    }
  }

  loadLatestProducts() {
    this.dataSource = new EntityDataSourceCustomBindReport(
      this.dashboardService,
      this.endpointUrl
    );

    this.dataSource.loadReport(
      this.businessTypeBE.businessTypeId,
      this.displayedColumns
    );

    this.dataSource.entityCount$.subscribe((c) => (this.reportCount = c));
    this.dataSource.currentData$.subscribe((rows) => {
      this.newProductsDataSourceRows = rows;
    });
  }

  loadSalesData() {
    this.saleChart = new Chart("saleChart", {
      type: "bar", //this denotes tha type of chart

      options: {
        aspectRatio: 2.5,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });

    this.dashboardService
      .getDashboardSalesMonthly(
        this.businessTypeBE.businessTypeId,
        this.locationBE.locationId
      )
      .subscribe((data) => {
        let totalArray: number[] = [];
        let paidTotalArray: number[] = [];
        let unPaidTotalArray: number[] = [];

        data.data.forEach((element) => {
          this.addLabelData(this.saleChart, element.date);
          totalArray.push(element.total);
          paidTotalArray.push(element.paidTotal);
          unPaidTotalArray.push(element.unPaidTotal);
        });
        this.addValueData(this.saleChart, "Total", "blue", totalArray);
        this.addValueData(this.saleChart, "Paid", "green", paidTotalArray);
        this.addValueData(this.saleChart, "UnPaid", "red", unPaidTotalArray);
      });
  }

  addLabelData(chart: Chart, label: any) {
    chart.data.labels.push(label);
    chart.update();
  }

  addValueData(chart: Chart, type: string, color: string, newData: any[]) {
    var newDataset = {
      label: type,
      data: [],
      backgroundColor: color,
    };
    newData.forEach((element) => {
      newDataset.data.push(element);
    });

    chart.config.data.datasets.push(newDataset);
    chart.update();
  }
}
