import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { ApplicationRoleService } from "../services/applicationrole.service";
import { Role } from "../core/models/role.model";

@Injectable()
export class ApplicationRolesResolver implements Resolve<Role[]> {
  constructor(private service: ApplicationRoleService) {}

  resolve(): Observable<Role[]> {
    return this.service.getApplicationRoles();
  }
}
