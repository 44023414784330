import { AssetCustomerMap } from "./asset";
import { AuditableEntity } from "./custom-model/auditableEntity";
import { BusinessType } from "./businessType";
import { Customer } from "./customer";
import { DepositAccount } from "./depositAccount";
import { Location } from "./location";
import { PaymentInvoiceMap } from "./paymentInvoiceMap";
import { PaymentSalesMap } from "./paymentSalesMap";

export class Payment extends AuditableEntity {
  paymentId: number;
  receiptNumber: string;
  reference: string;
  paymentDate: Date | string | null;
  paymentMode: PaymentMode;
  paymentReceivedStatus: PaymentReceivedStatus;
  remark: string;
  referenceDate: Date | string | null;

  realizedDate: Date | string | null;
  realizedBy: string;
  realizationStatus: RealizationStatus;

  customerId: number;
  customer: Customer;
  assetCustomerMapId: number | null;
  assetCustomerMap: AssetCustomerMap;
  locationId: number;
  location: Location;
  businessTypeId: number;
  businessType: BusinessType;
  depositAccountId: number;
  depositAccount: DepositAccount;
  slipUploaded: boolean;
  imagePath: string;
  imageName: string;
  tenderAmount: number;
  amount: number;
  balanceAmount: number;
  paymentInvoiceMaps: PaymentInvoiceMap[] = [];
  paymentSalesMaps: PaymentSalesMap[] = [];
}

export class PaymentView extends AuditableEntity {
  paymentId: number;
  receiptNumber: string;
  reference: string;
  paymentDate: string;
  paymentMode: PaymentMode;
  depositAccount: DepositAccount;
  paymentReceivedStatus: PaymentReceivedStatus;
  customerId: number;
  customer: string;
  locationId: number;
  location: string;
  businessTypeId: number;
  businessType: string;
  remark: string;
  amount: number;
  paymentForInvoice: string[];
  paymentForSales: string[];
}

export class PaymentInvoiceSalesView {
  referenceId: number;
  invoiceSalesDate: string;
  referenceNumber: string;
  customerAsset: string;
  referenceType: string;
  locationId: number;
  location: Location;
  businessTypeId: number;
  businessType: BusinessType;
  itemsCount: number;
  amount: number;
  balanceDue: number;
  payment: number;
  createdBy: string;
}

export class PaymentLineView {
  paymentId: number;
  receiptNumber: string;
  reference: string;
  paymentLineDate: string;
  referenceNumber: string;
  referenceType: string;
  locationId: number;
  location: Location;
  businessTypeId: number;
  businessType: BusinessType;
  amount: number;
}

export enum PaymentMode {
  Cash = 1,
  Cheque = 2,
  BankCard = 3,
  BankTransfer = 4,
  Deposit = 5,

  Promotion = 6,
  Donation = 7,
  SalaryDedcution = 8,
  InterCompany = 9,
  Settlement = 10,
  WaveOff = 11,
}

export enum PaymentReceivedStatus {
  Paid = 1,
  Pending = 2,
  Cancelled = 3,
  SalesReturned = 4,
  Refund = 5,
}

export enum RealizationStatus {
  Pending = 1,
  Realized = 2,
}
