import { Injectable } from "@angular/core";
import { Resolve, Router } from "@angular/router";
import { Observable } from "rxjs";
import { Location } from "../models/location";
import { LocationService } from "../services/location.service";

@Injectable()
export class LocationResolver implements Resolve<Location[]> {
  constructor(private service: LocationService, private router: Router) {}

  resolve(): Observable<Location[]> {
    return this.service.getAllLocations();
  }
}
