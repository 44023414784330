import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectionStrategy,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { roundTo } from "round-to";
import { PrintLayoutService } from "src/app/components/print-layout/print-layout.service";
import { User } from "src/app/core/models/user.model";
import { CustomerType } from "src/app/models/customer";
import { Payment, PaymentLineView, PaymentMode } from "src/app/models/payment";
import { Tax } from "src/app/models/tax";
import { EntityDataSourceCustomBindReport } from "src/app/services/entity.datasource";
import { PaymentService } from "src/app/services/payment.service";

@Component({
  selector: "app-print-usd-receipt",
  templateUrl: "./print-usd-receipt.component.html",
  styleUrls: ["./print-usd-receipt.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintUsdReceiptComponent implements OnInit, AfterViewInit {
  displayedColumnsSOLine = [
    "paymentLineDate",
    "referenceNumber",
    "referenceType",
    "amount",
  ];
  dataSourceSOLine: any[] = [];
  dataSource: EntityDataSourceCustomBindReport;
  readonly endpointUrl = "/api/payments/GetPaymentsLine";
  lineCount: number;

  id: number;
  payment: Payment;
  tax: Tax;
  screenName: string;
  logedUser: User;
  todayDate: Date = new Date();
  businesslogo: string;
  exchangeRate: any;

  constructor(
    private route: ActivatedRoute,
    private printLayoutService: PrintLayoutService,
    private paymentService: PaymentService
  ) {
    this.id = Number(this.route.snapshot.paramMap.get("id"));

    this.route.data.subscribe(({ payment }) => {
      if (payment) {
        this.payment = payment;
      }
    });

    this.route.data.subscribe(({ currency }) => {
      if (currency) {
        this.exchangeRate = currency.exchangeRate;
      }
    });

    this.route.data.subscribe(({ tax }) => {
      if (tax) {
        this.tax = tax;
      }
    });
  }

  ngOnInit() {
    this.dataSource = new EntityDataSourceCustomBindReport(
      this.paymentService,
      this.endpointUrl
    );

    this.dataSource.loadReport(this.id, this.displayedColumnsSOLine);
    this.dataSource.entityCount$.subscribe((c) => (this.lineCount = c));
    this.dataSource.currentData$.subscribe((rows) => {
      this.dataSourceSOLine = rows;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.printLayoutService.onDataReady();
    }, 1000);
  }

  getCustomerType(value: string | number) {
    return CustomerType[value];
  }

  getPaymentMode(value: string | number) {
    return PaymentMode[value];
  }

  getLineAmount(paymentLineView: PaymentLineView) {
    if (this.exchangeRate != null) {
      return roundTo(paymentLineView.amount, 2) / roundTo(this.exchangeRate, 2);
    } else {
      return roundTo(paymentLineView.amount, 2);
    }
  }

  financialFormat(x: number) {
    x = x / this.exchangeRate;
    return roundTo(x, 2);
  }
}
