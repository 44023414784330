import { Injectable } from "@angular/core";
import { EntityService } from "./entity.service";
import { HttpClient } from "@angular/common/http";
import { PaymentTerm } from "../models/paymentTerm";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PaymentTermService extends EntityService<PaymentTerm> {
  private readonly API_URL = "/api/PaymentTerms";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/PaymentTerms");
  }

  getPaymentTerms(): Observable<PaymentTerm[]> {
    return this.httpClient.get<PaymentTerm[]>(`${this.API_URL}`);
  }

  addPaymentTerm(paymentTerm: PaymentTerm): Observable<PaymentTerm> {
    return this.httpClient.post<PaymentTerm>(this.API_URL, paymentTerm);
  }

  updatePaymentTerm(paymentTerm: PaymentTerm): Observable<PaymentTerm> {
    return this.httpClient.put<PaymentTerm>(
      `${this.API_URL}/` + paymentTerm.paymentTermId,
      paymentTerm
    );
  }

  deletePaymentTerm(paymentTermId: number): Observable<PaymentTerm> {
    return this.httpClient.delete<PaymentTerm>(
      `${this.API_URL}/` + paymentTermId
    );
  }
}
