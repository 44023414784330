import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { Entity } from "../models/custom-model/entity";

export class EntityService<TEntity> {
  constructor(public httpClient: HttpClient, public url: string) {}
  findEntities(
    itemId: number,
    filter = "",
    sortOrder = "asc",
    sortColumn = "",
    pageNumber = 0,
    pageSize = 10,
    tableColumns: string[] = []
  ): Observable<Entity<TEntity>> {
    // let columnsArr: string[] = [];
    // if (tableColumns != []) {
    //   for (let i = 0; i < tableColumns?.length; i++) {
    //     if (tableColumns[i] && tableColumns[i] != "actions")
    //       columnsArr.push(tableColumns[i]);
    //   }
    // }

    let parameters = new HttpParams()
      .set("id", itemId.toString())
      .set("filter", filter)
      .set("sortOrder", sortOrder)
      .set("sortingColumn", sortColumn)
      .set("pageNumber", pageNumber.toString())
      .set("pageSize", pageSize.toString());
    // .set("columns", columnsArr.join(", "));

    return this.httpClient.get<Entity<TEntity>>(`${this.url}/GetAll`, {
      params: parameters,
    });
  }

  find(
    itemId: number,
    filter = "",
    sortOrder = "asc",
    sortColumn = "",
    pageNumber = 0,
    pageSize = 10,
    tableColumns: string[] = [],
    endpointUrl,
    sortByStatus: number,
    args: any
  ): Observable<any> {
    // let columnsArr: string[] = [];
    // // if (tableColumns != []) {
    // //   for (let i = 0; i < tableColumns?.length; i++) {
    // //     if (tableColumns[i] && tableColumns[i] != "actions")
    // //       columnsArr.push(tableColumns[i]);
    // //   }
    // // }

    let parameters = new HttpParams()
      .set("id", itemId.toString())
      .set("filter", filter)
      .set("sortOrder", sortOrder)
      .set("sortingColumn", sortColumn)
      .set("pageNumber", pageNumber.toString())
      .set("pageSize", pageSize.toString())
      // .set("columns", columnsArr.join(", "))
      .set("sortByStatus", sortByStatus.toString());

    var additionalColumn = <Array<any>>args;
    if (additionalColumn?.length) {
      Object.keys(args[0]).forEach(function (key) {
        parameters = parameters.append(key, args[0][key]);
      });
    }
    return this.httpClient.get<any>(endpointUrl, {
      params: parameters,
    });
  }

  findAll(
    itemId: number,
    filter = "",
    tableColumns: string[] = [],
    endpointUrl
  ): Observable<any> {
    // let columnsArr: string[] = [];
    // if (tableColumns != []) {
    //   for (let i = 0; i < tableColumns?.length; i++) {
    //     columnsArr.push(tableColumns[i]);
    //   }
    // }
    let parameters = new HttpParams()
      .set("id", itemId.toString())
      .set("filter", filter);
    // .set("columns", columnsArr.join(", "));

    return this.httpClient
      .get<any>(endpointUrl, {
        params: parameters,
      })
      .pipe(
        catchError((error) => {
          console.log(error.message);
          return of([]);
        })
      );
  }

  findReports(
    itemId: number,
    tableColumns: string[] = [],
    endpointUrl: string,
    args: any
  ): Observable<any> {
    // let columnsArr: string[] = [];
    // if (tableColumns != []) {
    //   for (let i = 0; i < tableColumns?.length; i++) {
    //     columnsArr.push(tableColumns[i]);
    //   }
    // }
    let parameters = new HttpParams().set("id", itemId.toString());
    // .set("columns", columnsArr.join(", "));

    var additionalColumn = <Array<any>>args;
    if (additionalColumn?.length) {
      Object.keys(args[0]).forEach(function (key) {
        parameters = parameters.append(key, args[0][key]);
      });
    }
    var result = this.httpClient.get<any>(endpointUrl, {
      params: parameters,
    });
    return result;
  }

  findProducts(
    itemId: number,
    filter = "",
    sortOrder = "asc",
    sortColumn = "",
    pageNumber = 0,
    pageSize = 10,
    tableColumns: string[] = [],
    endpointUrl,
    sortByStatus: number,
    businessType: number,
    productType: number,
    productCondition: number,
    productStatus: number,
    productLocation: number,
    brand: number,
    model: number,
    priceRangeMin: number,
    priceRangeMax: number
  ): Observable<any> {
    // let columnsArr: string[] = [];
    // if (tableColumns != []) {
    //   for (let i = 0; i < tableColumns?.length; i++) {
    //     if (tableColumns[i] && tableColumns[i] != "actions")
    //       columnsArr.push(tableColumns[i]);
    //   }
    // }

    let parameters = new HttpParams()
      .set("id", itemId.toString())
      .set("filter", filter)
      .set("sortOrder", sortOrder)
      .set("sortingColumn", sortColumn)
      .set("pageNumber", pageNumber.toString())
      .set("pageSize", pageSize.toString())
      // .set("columns", columnsArr.join(", "))
      .set("sortByStatus", sortByStatus.toString())
      .set("businessType", businessType.toString())
      .set("productType", productType.toString())
      .set("productCondition", productCondition.toString())
      .set("productStatus", productStatus.toString())
      .set("productLocation", productLocation.toString())
      .set("brand", brand.toString())
      .set("model", model.toString())
      .set("priceRangeMin", priceRangeMin.toString())
      .set("priceRangeMax", priceRangeMax.toString());

    return this.httpClient.get<any>(endpointUrl, {
      params: parameters,
    });
  }
}
