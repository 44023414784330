import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { EntityService } from "./entity.service";
import { BusinessType } from "../models/businessType";
import { CustomResult } from "../models/custom-model/customResult.model";

@Injectable({
  providedIn: "root",
})
export class BusinessTypeService extends EntityService<BusinessType> {
  private readonly API_URL = "/api/BusinessTypes";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/BusinessTypes");
  }

  getBusinessTypes(): Observable<BusinessType[]> {
    return this.httpClient.get<BusinessType[]>(`${this.API_URL}`);
  }

  getBusinessTypeById(id: number): Observable<BusinessType> {
    return this.httpClient.get<BusinessType>(`${this.API_URL}/` + id);
  }

  getBusinessTypeByName(name: string): Observable<BusinessType> {
    return this.httpClient.get<BusinessType>(
      `${this.API_URL}/GetBusinessTypeByName?name=` + name
    );
  }
  addBusinessType(BusinessType: BusinessType): Observable<BusinessType> {
    return this.httpClient.post<BusinessType>(this.API_URL, BusinessType);
  }

  updateBusinessType(BusinessType: BusinessType): Observable<BusinessType> {
    return this.httpClient.put<BusinessType>(
      `${this.API_URL}/` + BusinessType.businessTypeId,
      BusinessType
    );
  }

  deleteBusinessType(BusinessTypeId: number): Observable<BusinessType> {
    return this.httpClient.delete<BusinessType>(
      `${this.API_URL}/` + BusinessTypeId
    );
  }

  loadBusinessTypeImage(businessTypeId: number): Observable<CustomResult> {
    return this.httpClient.get<CustomResult>(
      `${this.API_URL}/LoadBusinessTypeImage?businessTypeId=` + businessTypeId
    );
  }

  deleteBusinessTypeImage(id: number): Observable<CustomResult> {
    return this.httpClient.delete<CustomResult>(
      `${this.API_URL}/DeleteBusinessTypeImage/` + id
    );
  }
}
