import { BusinessType } from "../../../models/businessType";
import { Sales } from "../../../models/sales";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
} from "@angular/core";
import {
  Denomination,
  DenominationPayments,
} from "src/app/models/denomination";
import { fadeInOut } from "src/app/services/animations";
import {
  Payment,
  PaymentMode,
  PaymentReceivedStatus,
  RealizationStatus,
} from "../../../models/payment";
import { ActivatedRoute } from "@angular/router";
import { MessageType } from "src/app/models/custom-model/customResult.model";
import { CustomerType } from "src/app/models/customer";
import { Invoice, InvoiceStatus } from "src/app/models/invoice";

import { PrintLayoutService } from "src/app/components/print-layout/print-layout.service";
import { User } from "src/app/core/models/user.model";
import { AuthService } from "src/app/core/services/auth.service";
import { Location } from "src/app/models/location";

@Component({
  selector: "denomination-print",
  styleUrls: ["./denomination-print.component.scss"],
  templateUrl: "./denomination-print.component.html",
  animations: [fadeInOut],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DenominationPrintComponent implements OnInit {
  @ViewChild("content", { static: false }) content: ElementRef;

  id: number;

  cash_Total: number = 0;
  cheque_Total: number = 0;
  bankCard_Total: number = 0;
  bankTransfer_Total: number = 0;
  deposit_Total: number = 0;
  promotion_Total: number = 0;
  donation_Total: number = 0;
  salaryDedcution_Total: number = 0;
  interCompany_Total: number = 0;
  settlement_Total: number = 0;
  waveOff_Total: number = 0;

  salesReturnedTotal: number = 0;
  downpaymentTotal: number = 0;
  systempaymentsTotal: number = 0;
  denominationTotal: number = 0;
  ecxessShortTotal: number = 0;

  denomination: Denomination;
  invoices: Invoice[] = [];
  sales: Sales[] = [];
  payments: Payment[] = [];
  businessTypeList: BusinessType[] = [];

  applicationUser: User;

  businessTypeBE: BusinessType;
  locationBE: Location;

  constructor(
    private printLayoutService: PrintLayoutService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.applicationUser = this.authService.currentUser;
    this.businessTypeBE = this.authService.currentBusiness;
    this.locationBE = this.authService.currentLocation;

    this.id = Number(this.route.snapshot.paramMap.get("id"));

    this.route.data.subscribe(({ denomination }) => {
      if (denomination.messageType == MessageType.Success) {
        const denominationPayments: DenominationPayments = denomination.data;

        this.payments = denominationPayments.payment;
        this.denomination = denominationPayments.denomination;
        this.invoices = denominationPayments.invoice;
        this.sales = denominationPayments.sales;

        if (this.payments?.length > 0) {
          this.payments.forEach((element) => {
            if (
              element.paymentReceivedStatus ==
              PaymentReceivedStatus.SalesReturned
            ) {
              this.salesReturnedTotal += element.amount;
            } else {
              if (element.paymentMode == PaymentMode.Cash) {
                this.cash_Total += element.amount;
              }
              if (element.paymentMode == PaymentMode.Cheque) {
                this.cheque_Total += element.amount;
              }
              if (element.paymentMode == PaymentMode.BankCard) {
                this.bankCard_Total += element.amount;
              }
              if (element.paymentMode == PaymentMode.BankTransfer) {
                this.bankTransfer_Total += element.amount;
              }
              if (element.paymentMode == PaymentMode.Deposit) {
                this.deposit_Total += element.amount;
              }
              if (element.paymentMode == PaymentMode.Promotion) {
                this.promotion_Total += element.amount;
              }
              if (element.paymentMode == PaymentMode.Donation) {
                this.donation_Total += element.amount;
              }
              if (element.paymentMode == PaymentMode.SalaryDedcution) {
                this.salaryDedcution_Total += element.amount;
              }
              if (element.paymentMode == PaymentMode.InterCompany) {
                this.interCompany_Total += element.amount;
              }
              if (element.paymentMode == PaymentMode.Settlement) {
                this.settlement_Total += element.amount;
              }
              if (element.paymentMode == PaymentMode.WaveOff) {
                this.waveOff_Total += element.amount;
              }

              element.paymentSalesMaps.forEach((downPay) => {
                this.downpaymentTotal += downPay.amount;
              });
            }
          });

          this.systempaymentsTotal =
            this.cash_Total +
            this.cheque_Total +
            this.bankCard_Total +
            this.bankTransfer_Total +
            this.deposit_Total +
            this.promotion_Total +
            this.donation_Total +
            this.salaryDedcution_Total +
            this.interCompany_Total +
            this.settlement_Total +
            this.waveOff_Total;
        }

        if (this.denomination != null) {
          this.denominationTotal =
            this.denomination.cash_Total +
            this.denomination.bankCard_Total +
            this.denomination.cheque_Total +
            this.denomination.bankTransfer_Total +
            this.denomination.deposit_Total +
            this.denomination.promotion_Total +
            this.denomination.donation_Total +
            this.denomination.salaryDedcution_Total +
            this.denomination.interCompany_Total +
            this.denomination.settlement_Total +
            this.denomination.waveOff_Total;
        }

        var total = this.systempaymentsTotal - this.salesReturnedTotal;
        this.ecxessShortTotal = this.denominationTotal - total;
      }
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.printLayoutService.onDataReady();
    }, 1000);
  }

  getPaymentMode(value) {
    return PaymentMode[value];
  }
  getRealizationStatus(value) {
    return RealizationStatus[value];
  }

  getCustomerName(row: Payment) {
    if (row) {
      if (row.assetCustomerMapId == null) {
        if (
          row.customer.customerType == CustomerType.Corporate ||
          row.customer.customerType == CustomerType.Financier ||
          row.customer.customerType == CustomerType.Internal
        ) {
          return row.customer.businessName;
        } else {
          return row.customer.fullName;
        }
      } else {
        if (
          row.assetCustomerMap.customer.customerType ==
            CustomerType.Corporate ||
          row.assetCustomerMap.customer.customerType ==
            CustomerType.Financier ||
          row.assetCustomerMap.customer.customerType == CustomerType.Internal
        ) {
          if (row.assetCustomerMap.asset) {
            return (
              row.assetCustomerMap.customer.businessName +
              " (" +
              row.assetCustomerMap.asset.field1 +
              ")"
            );
          } else {
            return row.assetCustomerMap.customer.businessName;
          }
        } else {
          if (row.assetCustomerMap.asset) {
            return (
              row.assetCustomerMap.customer.fullName +
              " (" +
              row.assetCustomerMap.asset.field1 +
              ")"
            );
          } else {
            return row.assetCustomerMap.customer.fullName;
          }
        }
      }
    }
  }

  getAmountTotal() {
    return this.payments?.length > 0 != null
      ? this.payments
          .map((t) => t.amount)
          .reduce((acc, value) => acc + value, 0)
      : 0;
  }

  getInvoices() {
    let count: number = 0;
    if (this.invoices?.length > 0) {
      count = this.invoices?.length;
    }
    return count;
  }

  getOpenInvoices() {
    let count: number = 0;
    if (this.invoices?.length > 0) {
      count = this.invoices.filter(
        (x) => x.invoiceStatus == InvoiceStatus.UnPaid
      )?.length;
    }
    return count;
  }

  getPaidInvoices() {
    let count: number = 0;
    if (this.invoices?.length > 0) {
      count = this.invoices.filter(
        (x) => x.invoiceStatus == InvoiceStatus.Paid
      )?.length;
    }
    return count;
  }

  getPartiallyPaidInvoices() {
    let count: number = 0;
    if (this.invoices?.length > 0) {
      count = this.invoices.filter(
        (x) => x.invoiceStatus == InvoiceStatus.PartiallyPaid
      )?.length;
    }
    return count;
  }

  getCancelledInvoices() {
    let count: number = 0;
    if (this.invoices?.length > 0) {
      count = this.invoices.filter(
        (x) => x.invoiceStatus == InvoiceStatus.Cancelled
      )?.length;
    }
    return count;
  }

  getSalesReturnedInvoices() {
    let count: number = 0;
    if (this.invoices?.length > 0) {
      count = this.invoices.filter(
        (x) => x.invoiceStatus == InvoiceStatus.SalesReturned
      )?.length;
    }
    return count;
  }

  getOpenSales() {
    let count: number = 0;
    if (this.sales?.length > 0) {
      count = this.sales?.length;
    }
    return count;
  }
}
