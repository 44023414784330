import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DeliveryOrder } from "../models/deliveryOrder";
import { CustomResult } from "../models/custom-model/customResult.model";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class DeliveryOrderService extends EntityService<DeliveryOrder> {
  private readonly API_URL = "/api/DeliveryOrders";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/DeliveryOrders");
  }

  getDeliveryOrderNumber(businessTypeId: number) {
    return this.httpClient.get(
      `${this.API_URL}/GetDeliveryOrderNumber/` + businessTypeId,
      {
        responseType: "text",
      }
    );
  }

  searchDeliveryOrderByBusiness(
    term: string,
    businessTypeId: number
  ): Observable<DeliveryOrder[]> {
    return this.httpClient.get<DeliveryOrder[]>(
      `${this.API_URL}/SearchDeliveryOrderByBusiness?term=` +
        term +
        `&businessTypeId=` +
        businessTypeId
    );
  }

  addDeliveryOrder(deliveryOrder: DeliveryOrder): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(this.API_URL, deliveryOrder);
  }

  updateDeliveryOrder(deliveryOrder: DeliveryOrder): Observable<DeliveryOrder> {
    return this.httpClient.put<DeliveryOrder>(
      `${this.API_URL}/` + deliveryOrder.deliveryOrderId,
      deliveryOrder
    );
  }

  getDeliveryOrderById(id: number): Observable<DeliveryOrder> {
    return this.httpClient.get<DeliveryOrder>(`${this.API_URL}/` + id);
  }

  deleteDeliveryOrder(deliveryOrderId: number): Observable<DeliveryOrder> {
    return this.httpClient.delete<DeliveryOrder>(
      `${this.API_URL}/` + deliveryOrderId
    );
  }

  releaseDeliveryOrder(deliveryOrder: DeliveryOrder): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/ReleaseDeliveryOrder/` + deliveryOrder.deliveryOrderId,
      deliveryOrder
    );
  }
}
