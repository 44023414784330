import { AppTitleService } from "./services/app-title.service";
import { PrintLayoutService } from "./components/print-layout/print-layout.service";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  ToastaService,
  ToastaConfig,
  ToastOptions,
  ToastData,
} from "ngx-toasta";
import {
  AlertService,
  MessageSeverity,
  AlertCommand,
  AlertDialog,
  DialogType,
} from "./core/services/alert.service";
import { AuthService } from "./core/services/auth.service";
import { NotificationService } from "./core/services/notification.service";
import { LocalStoreManager } from "./core/services/local-store-manager.service";
import { LoginComponent } from "./components/account/login/login.component";
import { ModalDirective } from "ngx-bootstrap/modal";
import { AppTranslationService } from "./core/services/app-translation.service";
import { ConfigurationService } from "./core/services/configuration.service";
import { ChangeBusinessLocationComponent } from "./components/change-business-location/change-business-location.component";
import { MatDialog } from "@angular/material/dialog";
import { BusinessType } from "./models/businessType";
import { DBkeys } from "./core/models/db-keys";
import { Location } from "./models/location";
import { delay } from "rxjs/operators";
import { LoaderService } from "./core/services/loader.service";

const alertify: any = require("../app/assets/scripts/alertify.js");

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, AfterViewInit {
  isAppLoaded: boolean = false;
  loading: boolean = false;
  isUserLoggedIn: boolean = false;
  shouldShowLoginModal: boolean = false;
  removePrebootScreen: boolean = false;
  newNotificationCount = 0;
  appTitle = "SalesApp";

  stickyToasties: number[] = [];

  dataLoadingConsecutiveFailures = 0;
  notificationsLoadingSubscription: any;

  @ViewChildren("loginModal,loginControl")
  modalLoginControls: QueryList<any>;

  loginModal: ModalDirective;
  loginControl: LoginComponent;

  gT = (key: string | Array<string>, interpolateParams?: object) =>
    this.translationService.getTranslation(key, interpolateParams);

  businessTypeName: string = "";
  locationName: string = "";

  get notificationsTitle() {
    if (this.newNotificationCount) {
      return `${this.gT("app.Notifications")} (${
        this.newNotificationCount
      } ${this.gT("app.New")})`;
    } else {
      return this.gT("app.Notifications");
    }
  }

  constructor(
    private storageManager: LocalStoreManager,
    private alertService: AlertService,
    private notificationService: NotificationService,
    private appTitleService: AppTitleService,
    private authService: AuthService,
    private translationService: AppTranslationService,
    public configurations: ConfigurationService,
    public router: Router,
    public printLayoutService: PrintLayoutService,
    private dialog: MatDialog,
    private toastaService: ToastaService,
    private toastaConfig: ToastaConfig,
    private loaderService: LoaderService,
    private renderer: Renderer2
  ) {
    storageManager.initialiseStorageSyncListener();
    this.appTitleService.appName = this.appTitle;
    this.toastaConfig.theme = "material";
    this.toastaConfig.showClose = true;
    this.toastaConfig.showDuration = true;
    this.toastaConfig.timeout = 2000;
    this.toastaConfig.limit = 100;
    this.toastaConfig.position = "top-center";
  }

  ngOnInit() {
    this.isUserLoggedIn = this.authService.isLoggedIn;
    // 0.5 extra sec to display preboot/loader information. Preboot screen is removed 0.5 sec later
    setTimeout(() => (this.isAppLoaded = true), 500);
    setTimeout(() => (this.removePrebootScreen = true), 1000);

    setTimeout(() => {
      if (this.isUserLoggedIn) {
        this.alertService.resetStickyMessage();

        if (this.authService.isSessionExpired) {
          this.alertService.showMessage(
            "Session Expired",
            "Your Session has expired. Please log in again",
            MessageSeverity.warn
          );
        }
      }
    }, 500);

    this.alertService
      .getDialogEvent()
      .subscribe((alert) => this.showDialog(alert));

    this.alertService
      .getMessageEvent()
      .subscribe((message) => this.showToast(message));

    this.authService.reLoginDelegate = () => (this.shouldShowLoginModal = true);

    this.authService.getLoginStatusEvent().subscribe((isLoggedIn) => {
      this.isUserLoggedIn = isLoggedIn;

      if (this.isUserLoggedIn) {
        this.initNotificationsLoading();
      } else {
        this.unsubscribeNotifications();
      }

      setTimeout(() => {
        if (!this.isUserLoggedIn) {
          this.alertService.showMessage(
            "Session Ended!",
            "",
            MessageSeverity.default
          );
        }
      }, 500);
    });
  }

  ngAfterViewInit() {
    this.modalLoginControls.changes.subscribe((controls: QueryList<any>) => {
      controls.forEach((control) => {
        if (control) {
          if (control instanceof LoginComponent) {
            this.loginControl = control;
            this.loginControl.modalClosedCallback = () =>
              this.loginModal.hide();
          } else {
            this.loginModal = control;
            this.loginModal.show();
          }
        }
      });
    });

    this.loaderService.httpProgress().subscribe((status: boolean) => {
      if (status) {
        this.renderer.addClass(document.body, "cursor-loader");
      } else {
        this.renderer.removeClass(document.body, "cursor-loader");
      }
    });

    this.businessTypeName = this.authService.currentBusiness
      ? this.authService.currentBusiness.businessName
      : "";

    this.locationName = this.authService.currentLocation
      ? this.authService.currentLocation.locationName
      : "";
  }

  onLoginModalShown() {
    this.alertService.showMessage(
      "Session Expired",
      "Your Session has expired. Please log in again",
      MessageSeverity.info
    );
  }

  onLoginModalHidden() {
    this.alertService.resetStickyMessage();
    this.loginControl.reset();
    this.shouldShowLoginModal = false;

    if (this.authService.isSessionExpired) {
      this.alertService.showMessage(
        "Session Expired",
        "Your Session has expired. Please log in again to renew your session",
        MessageSeverity.warn
      );
    }
  }

  onLoginModalHide() {
    this.alertService.resetStickyMessage();
  }

  ngOnDestroy() {
    this.unsubscribeNotifications();
  }

  private unsubscribeNotifications() {
    if (this.notificationsLoadingSubscription) {
      this.notificationsLoadingSubscription.unsubscribe();
    }
  }

  initNotificationsLoading() {
    this.notificationsLoadingSubscription = this.notificationService
      .getNewNotificationsPeriodically()
      .subscribe(
        (notifications) => {
          this.dataLoadingConsecutiveFailures = 0;
          this.newNotificationCount = notifications.filter(
            (n) => !n.isRead
          )?.length;
        },
        (error) => {
          this.alertService.logError(error);
          if (this.dataLoadingConsecutiveFailures++ < 20) {
            setTimeout(() => this.initNotificationsLoading(), 5000);
          } else {
            this.alertService.showMessage(
              "Load Error",
              "Loading new notifications from the server failed!",
              MessageSeverity.error
            );
          }
        }
      );
  }

  markNotificationsAsRead() {
    const recentNotifications = this.notificationService.recentNotifications;

    if (recentNotifications?.length) {
      this.notificationService
        .readUnreadNotification(
          recentNotifications.map((n) => n.id),
          true
        )
        .subscribe(
          () => {
            for (const n of recentNotifications) {
              n.isRead = true;
            }
            this.newNotificationCount = recentNotifications.filter(
              (n) => !n.isRead
            )?.length;
          },
          (error) => {
            this.alertService.logError(error);
            this.alertService.showMessage(
              "Notification Error",
              "Marking read notifications failed",
              MessageSeverity.error
            );
          }
        );
    }
  }

  openDialogChangeBusiness() {
    const dialogRef = this.dialog.open(ChangeBusinessLocationComponent, {
      minWidth: "40%",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result || !result.data) {
        return;
      }
      const rememberMe = this.storageManager.getDataObject<BusinessType>(
        DBkeys.REMEMBER_ME
      );
      const bus: BusinessType = result.data.businessType;
      const loc: Location = result.data.location;

      if (rememberMe) {
        if (bus) {
          this.storageManager.savePermanentData(bus, DBkeys.ACTIVE_BUSINESS);
        }

        if (loc) {
          this.storageManager.savePermanentData(loc, DBkeys.ACTIVE_LOCATION);
        }
      } else {
        if (bus) {
          this.storageManager.saveSyncedSessionData(
            bus,
            DBkeys.ACTIVE_BUSINESS
          );
        }

        if (loc) {
          this.storageManager.saveSyncedSessionData(
            loc,
            DBkeys.ACTIVE_LOCATION
          );
        }
      }

      this.businessTypeName = bus.businessName;
      this.locationName = loc.locationName;
      window.location.reload();
    });
  }

  showDialog(dialog: AlertDialog) {
    alertify.set({
      labels: {
        ok: dialog.okLabel || "OK",
        cancel: dialog.cancelLabel || "Cancel",
      },
    });

    switch (dialog.type) {
      case DialogType.alert:
        alertify.alert(dialog.message);

        break;
      case DialogType.confirm:
        alertify.confirm(dialog.message, (e) => {
          if (e) {
            dialog.okCallback();
          } else {
            if (dialog.cancelCallback) {
              dialog.cancelCallback();
            }
          }
        });

        break;
      case DialogType.prompt:
        alertify.prompt(
          dialog.message,
          (e, val) => {
            if (e) {
              dialog.okCallback(val);
            } else {
              if (dialog.cancelCallback) {
                dialog.cancelCallback();
              }
            }
          },
          dialog.defaultValue
        );

        break;
    }
  }

  showToast(alert: AlertCommand) {
    if (alert.operation === "clear") {
      for (const id of this.stickyToasties.slice(0)) {
        this.toastaService.clear(id);
      }

      return;
    }

    const toastOptions: ToastOptions = {
      title: alert.message.summary,
      msg: alert.message.detail,
    };

    if (alert.operation === "add_sticky") {
      toastOptions.timeout = 0;

      toastOptions.onAdd = (toast: ToastData) => {
        this.stickyToasties.push(toast.id);
      };

      toastOptions.onRemove = (toast: ToastData) => {
        const index = this.stickyToasties.indexOf(toast.id, 0);

        if (index > -1) {
          this.stickyToasties.splice(index, 1);
        }

        if (alert.onRemove) {
          alert.onRemove();
        }

        toast.onAdd = null;
        toast.onRemove = null;
      };
    } else {
      toastOptions.timeout = 4000;
    }

    switch (alert.message.severity) {
      case MessageSeverity.default:
        this.toastaService.default(toastOptions);
        break;
      case MessageSeverity.info:
        this.toastaService.info(toastOptions);
        break;
      case MessageSeverity.success:
        this.toastaService.success(toastOptions);
        break;
      case MessageSeverity.error:
        this.toastaService.error(toastOptions);
        break;
      case MessageSeverity.warn:
        this.toastaService.warning(toastOptions);
        break;
      case MessageSeverity.wait:
        this.toastaService.wait(toastOptions);
        break;
    }
  }

  logout() {
    this.authService.logout();
    this.authService.redirectLogoutUser();
  }

  getYear() {
    return new Date().getUTCFullYear();
  }

  get userName(): string {
    return this.authService.currentUser
      ? this.authService.currentUser.userName
      : "";
  }

  get roleName(): string {
    return this.authService.currentUser ? this.authService.roleName : "";
  }

  get fullName(): string {
    return this.authService.currentUser
      ? this.authService.currentUser.fullName
      : "";
  }

  // get canViewStocks() {
  //   return this.accountService.userHasPermission(Permission.StockViewPolicy);
  // }

  // get canViewCustomers() {
  //   return this.accountService.userHasPermission(Permission.CustomerViewPolicy);
  // }
}
