import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ProductCost } from "src/app/models/productCost";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class ProductCostService extends EntityService<ProductCost> {
  private readonly API_URL = "/api/ProductCosts";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/ProductCosts");
  }

  getAllProductCost(): Observable<ProductCost[]> {
    return this.httpClient.get<ProductCost[]>(`${this.API_URL}`);
  }

  addProductCost(ProductCost: ProductCost): Observable<ProductCost> {
    return this.httpClient.post<ProductCost>(this.API_URL, ProductCost);
  }

  updateProductCost(ProductCost: ProductCost): Observable<ProductCost> {
    return this.httpClient.put<ProductCost>(
      `${this.API_URL}/` + ProductCost.productCostId,
      ProductCost
    );
  }

  deleteProductCost(ProductCostId: number): Observable<ProductCost> {
    return this.httpClient.delete<ProductCost>(
      `${this.API_URL}/` + ProductCostId
    );
  }
}
