import { Sales } from "../../models/sales";
import { Invoice, InvoiceStatus } from "src/app/models/invoice";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  Denomination,
  DenominationPayments,
} from "src/app/models/denomination";
import { Location } from "src/app/models/location";
import { fadeInOut } from "src/app/services/animations";
import { DatePipe } from "@angular/common";
import { DenominationService } from "../../services/denomination.service";
import {
  Payment,
  PaymentMode,
  PaymentReceivedStatus,
  RealizationStatus,
} from "../../models/payment";
import { Router } from "@angular/router";
import {
  CustomResult,
  MessageType,
} from "src/app/models/custom-model/customResult.model";
import { CustomerType } from "src/app/models/customer";
import { MatDialog } from "@angular/material/dialog";

import { PrintLayoutService } from "../print-layout/print-layout.service";
import { User } from "src/app/core/models/user.model";
import { BusinessType } from "src/app/models/businessType";
import { roundTo } from "round-to";
import {
  AlertService,
  MessageSeverity,
} from "src/app/core/services/alert.service";
import { AuthService } from "src/app/core/services/auth.service";

@Component({
  selector: "denomination",
  styleUrls: ["./denomination.component.scss"],
  templateUrl: "./denomination.component.html",
  animations: [fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DenominationComponent implements OnInit {
  @ViewChild("content", { static: false }) content: ElementRef;
  denominationForm: FormGroup;
  dateFilterValue: string;
  denominationData: Denomination;
  denominationPaymentData: Payment[] = [];
  denominationInvoiceData: Invoice[] = [];
  denominationSalesData: Sales[] = [];
  applicationUser: User;
  businessTypeBE: BusinessType;
  locationBE: Location;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private datepipe: DatePipe,
    private alertService: AlertService,
    private denominationService: DenominationService,
    private printLayoutService: PrintLayoutService,
    private router: Router,
    private authService: AuthService
  ) {
    this.applicationUser = this.authService.currentUser;
    this.businessTypeBE = this.authService.currentBusiness;
    this.locationBE = this.authService.currentLocation;

    this.buildForm();
  }

  private buildForm() {
    this.denominationForm = this.formBuilder.group({
      denominationId: [0],
      denominationDate: ["", Validators.required],
      locationId: [this.locationBE.locationId, Validators.required],
      businessTypeId: [this.businessTypeBE.businessTypeId, Validators.required],

      note1000: [0],
      note500: [0],
      note100: [0],
      note50: [0],
      note20: [0],
      note10: [0],
      note5: [0],
      note2: [0],
      note1: [0],
      noteCoins: [0],

      cash_Total: [0],
      cheque_Total: [0],
      bankCard_Total: [0],
      bankTransfer_Total: [0],
      deposit_Total: [0],
      promotion_Total: [0],
      donation_Total: [0],
      salaryDedcution_Total: [0],
      interCompany_Total: [0],
      settlement_Total: [0],
      waveOff_Total: [0],

      excessOrShort: [0],
      remarks: [""],
    });
  }

  ngOnInit() {
    this.dateFilterValue = this.datepipe.transform(new Date(), "yyyy-MM-dd");
  }

  calculate(targetId, targetValue) {
    if (targetId.split("note")[1] == "Coins") {
      let calculation = parseFloat(targetValue != 0 ? targetValue : 0);
      document.getElementById("label_".concat(targetId)).innerHTML =
        "0." + calculation;
    } else if (targetId.split("_Total")[0] == "bankCard") {
      let calculation = parseFloat(targetValue != 0 ? targetValue : 0);
      document.getElementById("label_".concat(targetId)).innerHTML = roundTo(
        calculation,
        2
      ).toFixed(2);
    } else if (targetId.split("_Total")[0] == "cheque") {
      let calculation = parseFloat(targetValue != 0 ? targetValue : 0);
      document.getElementById("label_".concat(targetId)).innerHTML = roundTo(
        calculation,
        2
      ).toFixed(2);
    } else if (targetId.split("_Total")[0] == "bankTransfer") {
      let calculation = parseFloat(targetValue != 0 ? targetValue : 0);
      document.getElementById("label_".concat(targetId)).innerHTML = roundTo(
        calculation,
        2
      ).toFixed(2);
    } else if (targetId.split("_Total")[0] == "deposit") {
      let calculation = parseFloat(targetValue != 0 ? targetValue : 0);
      document.getElementById("label_".concat(targetId)).innerHTML = roundTo(
        calculation,
        2
      ).toFixed(2);
    } else if (targetId.split("_Total")[0] == "promotion") {
      let calculation = parseFloat(targetValue != 0 ? targetValue : 0);
      document.getElementById("label_".concat(targetId)).innerHTML = roundTo(
        calculation,
        2
      ).toFixed(2);
    } else if (targetId.split("_Total")[0] == "donation") {
      let calculation = parseFloat(targetValue != 0 ? targetValue : 0);
      document.getElementById("label_".concat(targetId)).innerHTML = roundTo(
        calculation,
        2
      ).toFixed(2);
    } else if (targetId.split("_Total")[0] == "salaryDedcution") {
      let calculation = parseFloat(targetValue != 0 ? targetValue : 0);
      document.getElementById("label_".concat(targetId)).innerHTML = roundTo(
        calculation,
        2
      ).toFixed(2);
    } else if (targetId.split("_Total")[0] == "interCompany") {
      let calculation = parseFloat(targetValue != 0 ? targetValue : 0);
      document.getElementById("label_".concat(targetId)).innerHTML = roundTo(
        calculation,
        2
      ).toFixed(2);
    } else if (targetId.split("_Total")[0] == "settlement") {
      let calculation = parseFloat(targetValue != 0 ? targetValue : 0);
      document.getElementById("label_".concat(targetId)).innerHTML = roundTo(
        calculation,
        2
      ).toFixed(2);
    } else if (targetId.split("_Total")[0] == "waveOff") {
      let calculation = parseFloat(targetValue != 0 ? targetValue : 0);
      document.getElementById("label_".concat(targetId)).innerHTML = roundTo(
        calculation,
        2
      ).toFixed(2);
    } else {
      let calculation =
        targetId.split("note")[1] *
        parseFloat(targetValue != 0 ? targetValue : 0);

      document.getElementById("label_".concat(targetId)).innerHTML = roundTo(
        calculation,
        2
      ).toFixed(2);
    }

    this.sumCalculations();
  }

  sumCalculations() {
    let cashDenominationTotal = 0;

    let bankCardDenominationTotal = 0;
    let chequeDenominationTotal = 0;
    let denominationTotal = 0;
    let systemTotal = 0;
    let ecxessShortTotal = 0;

    let bankTransfer_Total = 0;
    let deposit_Total = 0;
    let promotion_Total = 0;
    let donation_Total = 0;
    let salaryDedcution_Total = 0;
    let interCompany_Total = 0;
    let settlement_Total = 0;
    let waveOff_Total = 0;

    let salesReturnsTotal = 0;

    var children = document.getElementsByClassName("cashInput");
    for (let index = 0; index < children?.length; index++) {
      let element: HTMLElement = children[index] as HTMLElement;
      cashDenominationTotal += parseFloat(element.innerHTML);
    }

    var children = document.getElementsByClassName("bankCardInput");
    for (let index = 0; index < children?.length; index++) {
      let element: HTMLElement = children[index] as HTMLElement;
      bankCardDenominationTotal += parseFloat(element.innerHTML);
    }

    var children = document.getElementsByClassName("chequeInput");
    for (let index = 0; index < children?.length; index++) {
      let element: HTMLElement = children[index] as HTMLElement;
      chequeDenominationTotal += parseFloat(element.innerHTML);
    }

    bankTransfer_Total = parseFloat(
      document.getElementById("label_bankTransfer_Total").innerHTML
    );
    deposit_Total = parseFloat(
      document.getElementById("label_deposit_Total").innerHTML
    );
    promotion_Total = parseFloat(
      document.getElementById("label_promotion_Total").innerHTML
    );
    donation_Total = parseFloat(
      document.getElementById("label_donation_Total").innerHTML
    );
    salaryDedcution_Total = parseFloat(
      document.getElementById("label_salaryDedcution_Total").innerHTML
    );
    interCompany_Total = parseFloat(
      document.getElementById("label_interCompany_Total").innerHTML
    );
    settlement_Total = parseFloat(
      document.getElementById("label_settlement_Total").innerHTML
    );
    waveOff_Total = parseFloat(
      document.getElementById("label_waveOff_Total").innerHTML
    );

    salesReturnsTotal = parseFloat(
      document.getElementById("label_saleReturnsTotal").innerHTML
    );

    denominationTotal =
      cashDenominationTotal +
      bankCardDenominationTotal +
      chequeDenominationTotal +
      bankTransfer_Total +
      deposit_Total +
      promotion_Total +
      donation_Total +
      salaryDedcution_Total +
      interCompany_Total +
      settlement_Total +
      waveOff_Total;

    document.getElementById("label_denominationTotal").innerHTML = roundTo(
      denominationTotal,
      2
    ).toFixed(2);

    document.getElementById("label_cashDenominationTotal").innerHTML = roundTo(
      cashDenominationTotal,
      2
    ).toFixed(2);

    document.getElementById("label_bankCardDenominationTotal").innerHTML =
      roundTo(bankCardDenominationTotal, 2).toFixed(2);

    document.getElementById("label_chequeDenominationTotal").innerHTML =
      roundTo(chequeDenominationTotal, 2).toFixed(2);

    systemTotal = parseFloat(
      document.getElementById("label_systemTotal").innerHTML
    );

    var total = systemTotal - salesReturnsTotal;
    ecxessShortTotal = denominationTotal - total;

    document.getElementById("label_shortexcessTotal").innerHTML = roundTo(
      ecxessShortTotal,
      2
    ).toFixed(2);
  }

  findDenomination() {
    if (this.dateFilterValue == null) {
      this.alertService.showMessage(
        "Error",
        "Filter data invalid",
        MessageSeverity.error
      );
      return;
    } else {
      this.denominationForm.reset();

      this.denominationService
        .getDenominationByDateAndLocation(
          this.datepipe.transform(new Date(this.dateFilterValue), "yyyy-MM-dd"),
          this.locationBE.locationId,
          this.businessTypeBE.businessTypeId
        )
        .subscribe((res) => {
          if (res.messageType == MessageType.Success) {
            const denominationPayments: DenominationPayments = res.data;
            this.denominationData = denominationPayments.denomination;
            this.denominationPaymentData = denominationPayments.payment;
            this.denominationInvoiceData = denominationPayments.invoice;
            this.denominationSalesData = denominationPayments.sales;

            if (
              this.denominationData == null &&
              this.denominationPaymentData?.length == 0
            ) {
              this.alertService.showMessage(
                "Error",
                "No data found",
                MessageSeverity.error
              );
              return;
            } else {
              if (this.denominationData != null) {
                this.denominationForm.patchValue(this.denominationData);

                this.calculate("note1000", this.denominationData.note1000);
                this.calculate("note500", this.denominationData.note500);
                this.calculate("note100", this.denominationData.note100);
                this.calculate("note50", this.denominationData.note50);
                this.calculate("note20", this.denominationData.note20);
                this.calculate("note10", this.denominationData.note10);
                this.calculate("note5", this.denominationData.note5);
                this.calculate("note2", this.denominationData.note2);
                this.calculate("note1", this.denominationData.note1);
                this.calculate("noteCoins", this.denominationData.noteCoins);

                this.calculate(
                  "bankCard_Total",
                  this.denominationData.bankCard_Total
                );
                this.calculate(
                  "cheque_Total",
                  this.denominationData.cheque_Total
                );
                this.calculate(
                  "bankTransfer_Total",
                  this.denominationData.bankTransfer_Total
                );
                this.calculate(
                  "deposit_Total",
                  this.denominationData.deposit_Total
                );
                this.calculate(
                  "promotion_Total",
                  this.denominationData.promotion_Total
                );
                this.calculate(
                  "donation_Total",
                  this.denominationData.donation_Total
                );
                this.calculate(
                  "salaryDedcution_Total",
                  this.denominationData.salaryDedcution_Total
                );
                this.calculate(
                  "interCompany_Total",
                  this.denominationData.interCompany_Total
                );
                this.calculate(
                  "settlement_Total",
                  this.denominationData.settlement_Total
                );

                this.calculate(
                  "waveOff_Total",
                  this.denominationData.waveOff_Total
                );

                document.getElementById(
                  "label_denominationPreparedBy"
                ).innerHTML = this.denominationData.createdBy;

                document.getElementById(
                  "label_denominationLocation"
                ).innerHTML = this.denominationData.location.locationName;

                document.getElementById("label_denominationDate").innerHTML =
                  this.datepipe.transform(
                    new Date(this.denominationData.denominationDate),
                    "dd MMMM yyyy"
                  );
              }

              if (this.denominationPaymentData?.length > 0) {
                let cash_Total: number = 0;
                let cheque_Total: number = 0;
                let bankCard_Total: number = 0;
                let bankTransfer_Total: number = 0;
                let deposit_Total: number = 0;
                let promotion_Total: number = 0;
                let donation_Total: number = 0;
                let salaryDedcution_Total: number = 0;
                let interCompany_Total: number = 0;
                let settlement_Total: number = 0;
                let waveOff_Total: number = 0;

                let systempaymentsTotal = 0;
                let salesReturnedTotal = 0;

                this.denominationPaymentData.forEach((element) => {
                  if (
                    element.paymentReceivedStatus ==
                    PaymentReceivedStatus.SalesReturned
                  ) {
                    salesReturnedTotal += element.amount;
                  } else {
                    if (element.paymentMode == PaymentMode.Cash) {
                      cash_Total += element.amount;
                    }
                    if (element.paymentMode == PaymentMode.Cheque) {
                      cheque_Total += element.amount;
                    }
                    if (element.paymentMode == PaymentMode.BankCard) {
                      bankCard_Total += element.amount;
                    }
                    if (element.paymentMode == PaymentMode.BankTransfer) {
                      bankTransfer_Total += element.amount;
                    }
                    if (element.paymentMode == PaymentMode.Deposit) {
                      deposit_Total += element.amount;
                    }
                    if (element.paymentMode == PaymentMode.Promotion) {
                      promotion_Total += element.amount;
                    }
                    if (element.paymentMode == PaymentMode.Donation) {
                      donation_Total += element.amount;
                    }
                    if (element.paymentMode == PaymentMode.SalaryDedcution) {
                      salaryDedcution_Total += element.amount;
                    }
                    if (element.paymentMode == PaymentMode.InterCompany) {
                      interCompany_Total += element.amount;
                    }
                    if (element.paymentMode == PaymentMode.Settlement) {
                      settlement_Total += element.amount;
                    }
                    if (element.paymentMode == PaymentMode.WaveOff) {
                      waveOff_Total += element.amount;
                    }
                  }
                });

                systempaymentsTotal =
                  cash_Total +
                  cheque_Total +
                  bankCard_Total +
                  bankTransfer_Total +
                  deposit_Total +
                  promotion_Total +
                  donation_Total +
                  salaryDedcution_Total +
                  interCompany_Total +
                  settlement_Total +
                  waveOff_Total;

                document.getElementById("label_saleReturnsTotal").innerHTML =
                  roundTo(salesReturnedTotal, 2).toFixed(2);

                document.getElementById("label_System_Cash").innerHTML =
                  roundTo(cash_Total, 2).toFixed(2);

                document.getElementById("label_System_Cheque").innerHTML =
                  roundTo(cheque_Total, 2).toFixed(2);

                document.getElementById("label_System_BankCard").innerHTML =
                  roundTo(bankCard_Total, 2).toFixed(2);

                //start bankCard
                document.getElementById("label_System_BankTransfer").innerHTML =
                  roundTo(bankTransfer_Total, 2).toFixed(2);
                document.getElementById("label_bankTransfer_Total").innerHTML =
                  roundTo(bankTransfer_Total, 2).toFixed(2);
                //end bankCard

                //start deposit
                document.getElementById("label_System_Deposit").innerHTML =
                  roundTo(deposit_Total, 2).toFixed(2);
                document.getElementById("label_deposit_Total").innerHTML =
                  roundTo(deposit_Total, 2).toFixed(2);
                // end deposit

                //start promotion
                document.getElementById("label_System_Promotion").innerHTML =
                  roundTo(promotion_Total, 2).toFixed(2);
                document.getElementById("label_promotion_Total").innerHTML =
                  roundTo(promotion_Total, 2).toFixed(2);
                // end promotion

                //start donation
                document.getElementById("label_System_Donation").innerHTML =
                  roundTo(donation_Total, 2).toFixed(2);
                document.getElementById("label_donation_Total").innerHTML =
                  roundTo(donation_Total, 2).toFixed(2);
                // end donation

                //start salaryDedcution
                document.getElementById(
                  "label_System_SalaryDedcution"
                ).innerHTML = roundTo(salaryDedcution_Total, 2).toFixed(2);
                document.getElementById(
                  "label_salaryDedcution_Total"
                ).innerHTML = roundTo(salaryDedcution_Total, 2).toFixed(2);
                // end salaryDedcution

                //start interCompany
                document.getElementById("label_System_InterCompany").innerHTML =
                  roundTo(interCompany_Total, 2).toFixed(2);
                document.getElementById("label_interCompany_Total").innerHTML =
                  roundTo(interCompany_Total, 2).toFixed(2);
                // end interCompany

                //start settlement
                document.getElementById("label_System_Settlement").innerHTML =
                  roundTo(settlement_Total, 2).toFixed(2);
                document.getElementById("label_settlement_Total").innerHTML =
                  roundTo(settlement_Total, 2).toFixed(2);
                // end settlement

                //start waveOff
                document.getElementById("label_System_WaveOff").innerHTML =
                  roundTo(waveOff_Total, 2).toFixed(2);
                document.getElementById("label_waveOff_Total").innerHTML =
                  roundTo(waveOff_Total, 2).toFixed(2);
                // end waveOff

                document.getElementById("label_systemTotal").innerHTML =
                  roundTo(systempaymentsTotal, 2).toFixed(2);
              }
              this.sumCalculations();
            }
          } else {
            this.alertService.showMessage(
              "Error",
              res.message,
              MessageSeverity.error
            );
          }
        });
    }
  }

  doAction() {
    var denominationTotal = parseFloat(
      document.getElementById("label_denominationTotal").innerHTML
    );

    this.denominationDate.setValue(this.dateFilterValue);
    this.locationId.setValue(this.locationBE.locationId);
    this.businessTypeId.setValue(this.locationBE.businessTypeId);

    this.cash_Total.setValue(
      parseFloat(
        document.getElementById("label_cashDenominationTotal").innerHTML
      )
    );
    this.cheque_Total.setValue(
      parseFloat(
        document.getElementById("label_chequeDenominationTotal").innerHTML
      )
    );
    this.bankCard_Total.setValue(
      parseFloat(
        document.getElementById("label_bankCardDenominationTotal").innerHTML
      )
    );
    this.bankTransfer_Total.setValue(
      parseFloat(document.getElementById("label_bankTransfer_Total").innerHTML)
    );
    this.deposit_Total.setValue(
      parseFloat(document.getElementById("label_deposit_Total").innerHTML)
    );
    this.promotion_Total.setValue(
      parseFloat(document.getElementById("label_promotion_Total").innerHTML)
    );
    this.donation_Total.setValue(
      parseFloat(document.getElementById("label_donation_Total").innerHTML)
    );
    this.salaryDedcution_Total.setValue(
      parseFloat(
        document.getElementById("label_salaryDedcution_Total").innerHTML
      )
    );
    this.interCompany_Total.setValue(
      parseFloat(document.getElementById("label_interCompany_Total").innerHTML)
    );
    this.settlement_Total.setValue(
      parseFloat(document.getElementById("label_settlement_Total").innerHTML)
    );
    this.waveOff_Total.setValue(
      parseFloat(document.getElementById("label_waveOff_Total").innerHTML)
    );
    this.excessOrShort.setValue(
      parseFloat(document.getElementById("label_shortexcessTotal").innerHTML)
    );

    if (!this.denominationForm.valid) {
      this.alertService.showMessage("Form not valid");
      return;
    } else if (denominationTotal == 0) {
      this.alertService.showMessage(
        "Error",
        "No denomination data found to save!",
        MessageSeverity.error
      );
      return;
    } else {
      let denoBE: Denomination = new Denomination();
      denoBE.denominationId = this.denominationId.value
        ? this.denominationId.value
        : 0;
      denoBE.denominationDate =
        this.denominationDate.value.toLocaleString("en-US");

      denoBE.locationId = this.locationId.value;
      denoBE.businessTypeId = this.businessTypeId.value;

      denoBE.cash_Total = this.cash_Total.value ? this.cash_Total.value : 0;
      denoBE.bankCard_Total = this.bankCard_Total.value
        ? this.bankCard_Total.value
        : 0;
      denoBE.cheque_Total = this.cheque_Total.value
        ? this.cheque_Total.value
        : 0;
      denoBE.bankTransfer_Total = this.bankTransfer_Total.value
        ? this.bankTransfer_Total.value
        : 0;
      denoBE.deposit_Total = this.deposit_Total.value
        ? this.deposit_Total.value
        : 0;
      denoBE.promotion_Total = this.promotion_Total.value
        ? this.promotion_Total.value
        : 0;
      denoBE.donation_Total = this.donation_Total.value
        ? this.donation_Total.value
        : 0;
      denoBE.donation_Total = this.salaryDedcution_Total.value
        ? this.salaryDedcution_Total.value
        : 0;
      denoBE.interCompany_Total = this.interCompany_Total.value
        ? this.interCompany_Total.value
        : 0;
      denoBE.settlement_Total = this.settlement_Total.value
        ? this.settlement_Total.value
        : 0;
      denoBE.waveOff_Total = this.waveOff_Total.value
        ? this.waveOff_Total.value
        : 0;
      denoBE.excessOrShort = this.excessOrShort.value
        ? this.excessOrShort.value
        : 0;

      denoBE.note1000 = this.note1000.value ? this.note1000.value : 0;
      denoBE.note500 = this.note500.value ? this.note500.value : 0;
      denoBE.note100 = this.note100.value ? this.note100.value : 0;
      denoBE.note50 = this.note50.value ? this.note50.value : 0;
      denoBE.note20 = this.note20.value ? this.note20.value : 0;
      denoBE.note10 = this.note10.value ? this.note10.value : 0;
      denoBE.note5 = this.note5.value ? this.note5.value : 0;
      denoBE.note2 = this.note2.value ? this.note2.value : 0;
      denoBE.note1 = this.note1.value ? this.note1.value : 0;
      denoBE.noteCoins = this.noteCoins.value ? this.noteCoins.value : 0;
      denoBE.remarks = this.remarks.value;

      if (denoBE.denominationId == 0) {
        this.denominationService
          .addDenomination(denoBE)
          .subscribe((res: CustomResult) => {
            if (res.messageType == MessageType.Success) {
              this.alertService.showMessage(
                "Denomination",
                res.message,
                MessageSeverity.success
              );

              this.findDenomination();
            } else {
              this.alertService.showMessage(
                "Denomination",
                res.message,
                MessageSeverity.error
              );
            }
          });
      } else {
        this.denominationService
          .updateDenomination(denoBE)
          .subscribe((res: CustomResult) => {
            if (res.messageType == MessageType.Success) {
              this.alertService.showMessage(
                "Denomination",
                res.message,
                MessageSeverity.success
              );

              this.findDenomination();
            } else {
              this.alertService.showMessage(
                "Denomination",
                res.message,
                MessageSeverity.error
              );
            }
          });
      }
    }
  }

  get denominationId() {
    return this.denominationForm.get("denominationId");
  }
  get denominationDate() {
    return this.denominationForm.get("denominationDate");
  }
  get locationId() {
    return this.denominationForm.get("locationId");
  }
  get businessTypeId() {
    return this.denominationForm.get("businessTypeId");
  }
  get note1000() {
    return this.denominationForm.get("note1000");
  }
  get note500() {
    return this.denominationForm.get("note500");
  }
  get note100() {
    return this.denominationForm.get("note100");
  }
  get note50() {
    return this.denominationForm.get("note50");
  }
  get note20() {
    return this.denominationForm.get("note20");
  }
  get note10() {
    return this.denominationForm.get("note10");
  }
  get note5() {
    return this.denominationForm.get("note5");
  }
  get note2() {
    return this.denominationForm.get("note2");
  }
  get note1() {
    return this.denominationForm.get("note1");
  }
  get noteCoins() {
    return this.denominationForm.get("noteCoins");
  }
  get cash_Total() {
    return this.denominationForm.get("cash_Total");
  }
  get cheque_Total() {
    return this.denominationForm.get("cheque_Total");
  }
  get bankCard_Total() {
    return this.denominationForm.get("bankCard_Total");
  }
  get bankTransfer_Total() {
    return this.denominationForm.get("bankTransfer_Total");
  }
  get deposit_Total() {
    return this.denominationForm.get("deposit_Total");
  }
  get promotion_Total() {
    return this.denominationForm.get("promotion_Total");
  }
  get donation_Total() {
    return this.denominationForm.get("donation_Total");
  }
  get salaryDedcution_Total() {
    return this.denominationForm.get("salaryDedcution_Total");
  }
  get interCompany_Total() {
    return this.denominationForm.get("interCompany_Total");
  }
  get settlement_Total() {
    return this.denominationForm.get("settlement_Total");
  }
  get waveOff_Total() {
    return this.denominationForm.get("waveOff_Total");
  }

  get excessOrShort() {
    return this.denominationForm.get("excessOrShort");
  }
  get remarks() {
    return this.denominationForm.get("remarks");
  }

  onPrintDialog() {
    if (this.denominationData) {
      setTimeout(() => {
        localStorage.removeItem("screenType");
        localStorage.removeItem("denominationHeader");
        localStorage.removeItem("printLogoPath");
        localStorage.removeItem("printSalesBusinessName");
        localStorage.removeItem("printLocationAddress");
        localStorage.removeItem("printLocationContactNumbers");
        localStorage.removeItem("printLocationEmails");
        localStorage.removeItem("printTinNumber");
        localStorage.removeItem("printCreatedBy");

        localStorage.setItem(
          "denominationHeader",
          this.denominationData.location.locationName.toUpperCase() +
            " DENOMINATION REPORT"
        );

        localStorage.setItem(
          "printLocationAddress",
          this.locationBE.locationAddress
        );
        localStorage.setItem(
          "printLocationContactNumbers",
          this.locationBE.generalNumber + " | " + this.locationBE.hotlineNumber
        );
        localStorage.setItem(
          "printLocationEmails",
          this.locationBE.email1 + " | " + this.locationBE.email2
        );
        localStorage.setItem("printCreatedBy", this.denominationData.createdBy);
        localStorage.setItem("screenType", "denomination");

        this.printLayoutService.printDocument(
          "denomination-print",
          this.denominationData.denominationId,
          this.router.url
        );
      }, 800);
    } else {
      this.alertService.showMessage(
        "Error",
        "No denomination data found",
        MessageSeverity.error
      );
      return;
    }
  }

  getCustomerName(row: Payment) {
    if (row) {
      if (row.assetCustomerMapId == null) {
        if (
          row.customer.customerType == CustomerType.Corporate ||
          row.customer.customerType == CustomerType.Financier ||
          row.customer.customerType == CustomerType.Internal
        ) {
          return row.customer.businessName;
        } else {
          return row.customer.fullName;
        }
      } else {
        if (
          row.assetCustomerMap.customer.customerType ==
            CustomerType.Corporate ||
          row.assetCustomerMap.customer.customerType ==
            CustomerType.Financier ||
          row.assetCustomerMap.customer.customerType == CustomerType.Internal
        ) {
          if (row.assetCustomerMap.asset) {
            return (
              row.assetCustomerMap.customer.businessName +
              " (" +
              row.assetCustomerMap.asset.field1 +
              ")"
            );
          } else {
            return row.assetCustomerMap.customer.businessName;
          }
        } else {
          if (row.assetCustomerMap.asset) {
            return (
              row.assetCustomerMap.customer.fullName +
              " (" +
              row.assetCustomerMap.asset.field1 +
              ")"
            );
          } else {
            return row.assetCustomerMap.customer.fullName;
          }
        }
      }
    }
  }
  getPaymentMode(value) {
    return PaymentMode[value];
  }
  getRealizationStatus(value) {
    return RealizationStatus[value];
  }
  getAmountTotal() {
    return this.denominationPaymentData?.length > 0 != null
      ? this.denominationPaymentData
          .map((t) => t.amount)
          .reduce((acc, value) => acc + value, 0)
      : 0;
  }

  getInvoices() {
    let count: number = 0;
    if (this.denominationInvoiceData?.length > 0) {
      count = this.denominationInvoiceData?.length;
    }
    return count;
  }

  getOpenInvoices() {
    let count: number = 0;
    if (this.denominationInvoiceData?.length > 0) {
      count = this.denominationInvoiceData.filter(
        (x) => x.invoiceStatus == InvoiceStatus.UnPaid
      )?.length;
    }
    return count;
  }

  getPaidInvoices() {
    let count: number = 0;
    if (this.denominationInvoiceData?.length > 0) {
      count = this.denominationInvoiceData.filter(
        (x) => x.invoiceStatus == InvoiceStatus.Paid
      )?.length;
    }
    return count;
  }

  getPartiallyPaidInvoices() {
    let count: number = 0;
    if (this.denominationInvoiceData?.length > 0) {
      count = this.denominationInvoiceData.filter(
        (x) => x.invoiceStatus == InvoiceStatus.PartiallyPaid
      )?.length;
    }
    return count;
  }

  getCancelledInvoices() {
    let count: number = 0;
    if (this.denominationInvoiceData?.length > 0) {
      count = this.denominationInvoiceData.filter(
        (x) => x.invoiceStatus == InvoiceStatus.Cancelled
      )?.length;
    }
    return count;
  }

  getSalesReturnedInvoices() {
    let count: number = 0;
    if (this.denominationInvoiceData?.length > 0) {
      count = this.denominationInvoiceData.filter(
        (x) => x.invoiceStatus == InvoiceStatus.SalesReturned
      )?.length;
    }
    return count;
  }

  getOpenSales() {
    let count: number = 0;
    if (this.denominationSalesData?.length > 0) {
      count = this.denominationSalesData?.length;
    }
    return count;
  }
}
