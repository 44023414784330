import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { StockAdjustment } from "../models/stockAdjustment";
import { StockAdjustmentService } from "../services/stockAdjustment.service";

@Injectable()
export class StockAdjustmentResolver implements Resolve<StockAdjustment> {
  constructor(private service: StockAdjustmentService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<StockAdjustment> {
    const id = Number(route.paramMap.get("id"));
    if (id != 0) {
      return this.service.geStockAdjustmentById(+id);
    } else {
      return null;
    }
  }
}
