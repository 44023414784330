import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  AlertService,
  MessageSeverity,
} from "src/app/core/services/alert.service";

@Component({
  selector: "app-cancel-return-remarks-dialog",
  templateUrl: "./cancel-return-remarks-dialog.component.html",
  styleUrls: ["./cancel-return-remarks-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancelReturnRemarksDialogComponent implements OnInit {
  popUpForm: FormGroup;

  ngOnInit() {}

  constructor(
    public dialogRef: MatDialogRef<CancelReturnRemarksDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private alertService: AlertService
  ) {
    this.buildForm();
  }

  private buildForm() {
    this.popUpForm = this.formBuilder.group({
      cancelReturnRemark: [null, Validators.required],
    });
  }

  doAction() {
    if (!this.popUpForm.valid) {
      this.alertService.showMessage("Form not valid");
      return;
    } else {
      this.alertService.resetStickyMessage();
    }
    this.dialogRef.close({ data: this.popUpForm.value });
  }

  get cancelReturnRemark() {
    return this.popUpForm.get("cancelReturnRemark");
  }

  closeDialog(): void {
    this.dialogRef.close({ event: "Cancel" });
  }
}
