import { Injectable } from "@angular/core";
import { EntityService } from "./entity.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { UnitOfMeasure } from "../models/unitOfMeasure";

@Injectable({
  providedIn: "root",
})
export class UnitOfMeasureService extends EntityService<UnitOfMeasure> {
  private readonly API_URL = "/api/UnitOfMeasures";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/UnitOfMeasures");
  }

  getAlUnitOfMeasures(): Observable<UnitOfMeasure[]> {
    return this.httpClient.get<UnitOfMeasure[]>(`${this.API_URL}`);
  }

  addUnitOfMeasure(unitOfMeasure: UnitOfMeasure): Observable<UnitOfMeasure> {
    return this.httpClient.post<UnitOfMeasure>(this.API_URL, unitOfMeasure);
  }

  updateUnitOfMeasure(unitOfMeasure: UnitOfMeasure): Observable<UnitOfMeasure> {
    return this.httpClient.put<UnitOfMeasure>(
      `${this.API_URL}/` + unitOfMeasure.unitOfMeasureId,
      unitOfMeasure
    );
  }

  deleteUnitOfMeasure(unitOfMeasureId: number): Observable<UnitOfMeasure> {
    return this.httpClient.delete<UnitOfMeasure>(
      `${this.API_URL}/` + unitOfMeasureId
    );
  }
}
