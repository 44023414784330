import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Brand } from "../models/brand";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class BrandService extends EntityService<Brand> {
  private readonly API_URL = "/api/brands";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/brands");
  }

  getAllBrands(): Observable<Brand[]> {
    return this.httpClient.get<Brand[]>(`${this.API_URL}`);
  }

  getBrandByName(name: string, businessTypeId: number): Observable<Brand[]> {
    return this.httpClient.get<Brand[]>(
      `${this.API_URL}/GetBrandByName?term=` +
        name +
        `&businessTypeId=` +
        businessTypeId
    );
  }

  getBrandByBusinessTypeId(businessTypeId: number): Observable<Brand[]> {
    return this.httpClient.get<Brand[]>(
      `${this.API_URL}/GetBrandByBusinessTypeId?businessTypeId=` +
        businessTypeId
    );
  }

  addBrand(brand: Brand): Observable<Brand> {
    return this.httpClient.post<Brand>(this.API_URL, brand);
  }

  updateBrand(brand: Brand): Observable<Brand> {
    return this.httpClient.put<Brand>(
      `${this.API_URL}/` + brand.brandId,
      brand
    );
  }

  deleteBrand(brandId: number): Observable<Brand> {
    return this.httpClient.delete<Brand>(`${this.API_URL}/` + brandId);
  }

  UploadExcel(formData: FormData) {
    let headers = new HttpHeaders();

    headers.append("Content-Type", "multipart/form-data");
    const httpOptions = { headers: headers };
    return this.httpClient.post(
      this.API_URL + "/ExcelUpload",
      formData,
      httpOptions
    );
  }

  download(fileName): Observable<Blob> {
    return this.httpClient.post<Blob>(
      `${this.API_URL}/ExportFile?fileName=` + fileName,
      fileName,
      { responseType: "blob" as "json" }
    );
  }
}
