import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { roundTo } from "round-to";
import { PrintLayoutService } from "src/app/components/print-layout/print-layout.service";
import { CustomerType } from "src/app/models/customer";
import { Invoice } from "src/app/models/invoice";
import { Payment, PaymentMode } from "src/app/models/payment";
import { Tax } from "src/app/models/tax";
import { fadeInOut } from "src/app/services/animations";
import { EntityDataSourceCustomBindReport } from "src/app/services/entity.datasource";
import { SalesLineService } from "src/app/services/salesLine.service";

@Component({
  selector: "app-print-usd-invoice",
  templateUrl: "./print-usd-invoice.component.html",
  styleUrls: ["./print-usd-invoice.component.scss"],
  animations: [fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintUsdInvoiceComponent implements OnInit, AfterViewInit {
  id: number;
  invoice: Invoice;
  tax: Tax;
  exchangeRate: number;

  displayedColumnsSOLine: string[] = [
    "itemCode",
    "description",
    "uom",
    "rate",
    "qty",
    "promotion",
    "amount",
  ];

  dataSourceSOLine: any[] = [];
  dataSource: EntityDataSourceCustomBindReport;
  readonly endpointUrl = "/api/salesLines/GetSalesLineView";
  lineCount: number;
  payment: Payment;

  constructor(
    private printLayoutService: PrintLayoutService,
    private salesLineService: SalesLineService,
    private route: ActivatedRoute
  ) {
    this.id = Number(this.route.snapshot.paramMap.get("id"));

    this.route.data.subscribe(({ invoice }) => {
      if (invoice) {
        this.invoice = invoice;

        this.invoice.paymentInvoiceMaps.forEach((x) => {
          this.payment = x.payment;
        });
      }
    });

    this.route.data.subscribe(({ currency }) => {
      if (currency) {
        this.exchangeRate = currency.exchangeRate;
      }
    });

    this.route.data.subscribe(({ tax }) => {
      if (tax) {
        this.tax = tax;
      }
    });
  }

  ngOnInit() {
    this.dataSource = new EntityDataSourceCustomBindReport(
      this.salesLineService,
      this.endpointUrl
    );

    this.dataSource.loadReport(
      this.invoice.salesId,
      this.displayedColumnsSOLine
    );
    this.dataSource.entityCount$.subscribe((c) => (this.lineCount = c));
    this.dataSource.currentData$.subscribe((rows) => {
      this.dataSourceSOLine = rows;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.printLayoutService.onDataReady();
    }, 1000);
  }

  getCustomerType(value: string | number) {
    return CustomerType[value];
  }
  getPaymentMode(value: string | number) {
    return PaymentMode[value];
  }

  financialFormat(x: number) {
    x = x / this.exchangeRate;
    return roundTo(x, 2);
  }
}
