import { Injectable } from "@angular/core";
import { Technician } from "../models/technician";
import { EntityService } from "./entity.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CustomResult } from "../models/custom-model/customResult.model";

@Injectable({
  providedIn: "root",
})
export class TechnicianService extends EntityService<Technician> {
  private readonly API_URL = "/api/technicians";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/technicians");
  }

  get technicianCount(): number {
    return this.technicianCount;
  }

  getAlTechnicians(): Observable<Technician[]> {
    return this.httpClient.get<Technician[]>(`${this.API_URL}`);
  }

  getTechnicianByName(name: string): Observable<Technician> {
    return this.httpClient.get<Technician>(
      `${this.API_URL}/GetTechnicianByName?name=` + name
    );
  }

  addTechnician(technician: Technician): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(this.API_URL, technician);
  }

  updateTechnician(technician: Technician): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/` + technician.technicianId,
      technician
    );
  }

  deleteTechnician(technicianId: number): Observable<CustomResult> {
    return this.httpClient.delete<CustomResult>(
      `${this.API_URL}/` + technicianId
    );
  }
}
