import { AuditableEntity } from "./custom-model/auditableEntity";
import { DeliveryOrderType, DeliveryOrder } from "./deliveryOrder";
import { InvoiceSalesMap } from "./invoiceSalesMap";
import { PaymentInvoiceMap } from "./paymentInvoiceMap";
import { Sales } from "./sales";
import { TransferOrder } from "./transferOrder";

export class Invoice extends AuditableEntity {
  invoiceId: number;
  invoiceNumber: string;

  invoiceDate: Date | string | null;
  dueDate: Date | string | null;

  invoiceStatus: InvoiceStatus;

  shippingChargesTotal: number;
  bagChargesTotal: number;
  lateChargesTotal: number;
  subTotal: number;
  discountTotal: number;
  taxTotal: number;
  amount: number;
  balanceDue: number;

  pONumber: string;
  contactPersonName: string;
  contactPersonNumber: string;
  deliveryAddress: string;
  deliveryOrderType: DeliveryOrderType;
  cancelReturnDate: string | null;
  cancelReturnRemark: string;

  salesId: number;
  sales: Sales;
  paymentInvoiceMaps: PaymentInvoiceMap[] = [];
  invoiceSalesMaps: InvoiceSalesMap[] = [];
  deliveryOrders: DeliveryOrder[] = [];
  transferOrders: TransferOrder[] = [];
}

export enum InvoiceStatus {
  UnPaid = 1,
  PartiallyPaid = 2,
  Paid = 3,
  Cancelled = 4,
  SalesReturned = 5,
}
