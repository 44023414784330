import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CustomResult } from "src/app/models/custom-model/customResult.model";
import { Model } from "src/app/models/model";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class ModelService extends EntityService<Model> {
  private readonly API_URL = "/api/models";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/models");
  }

  getModels(): Observable<Model[]> {
    return this.httpClient.get<Model[]>(`${this.API_URL}`);
  }

  getAllModelsWithBrand(): Observable<Model[]> {
    return this.httpClient.get<Model[]>(`${this.API_URL}`);
  }

  getModelByName(name: string, brandId: number): Observable<Model[]> {
    return this.httpClient.get<Model[]>(
      `${this.API_URL}/GetModelByName?term=` + name + `&brandId=` + brandId
    );
  }

  getModelByBrandId(brandId: number): Observable<Model[]> {
    return this.httpClient.get<Model[]>(
      `${this.API_URL}/GetModelByBrandId?brandId=` + brandId
    );
  }

  getModelByBusinessTypeId(businessTypeId: number): Observable<Model[]> {
    return this.httpClient.get<Model[]>(
      `${this.API_URL}/GetModelByBusinessTypeId?businessTypeId=` +
        businessTypeId
    );
  }

  getModelById(id: number): Observable<Model> {
    return this.httpClient.get<Model>(`${this.API_URL}/` + id);
  }

  get modelCount(): number {
    return this.modelCount;
  }

  addModel(model: Model): Observable<Model> {
    return this.httpClient.post<Model>(this.API_URL, model);
  }

  updateModel(model: Model): Observable<Model> {
    return this.httpClient.put<Model>(
      `${this.API_URL}/` + model.modelId,
      model
    );
  }

  deleteModel(modelId: number): Observable<Model> {
    return this.httpClient.delete<Model>(`${this.API_URL}/` + modelId);
  }

  loadModelImage(modelId: number): Observable<CustomResult> {
    return this.httpClient.get<CustomResult>(
      `${this.API_URL}/LoadModelImage?modelId=` + modelId
    );
  }

  deleteModelImage(id: number): Observable<CustomResult> {
    return this.httpClient.delete<CustomResult>(
      `${this.API_URL}/DeleteModelImage/` + id
    );
  }

  download(docName): Observable<Blob> {
    return this.httpClient.post<Blob>(
      `${this.API_URL}/ExportUploadTemplate?docName=` + docName,
      docName,
      { responseType: "blob" as "json" }
    );
  }
}
