import { Injectable } from "@angular/core";
import { EntityService } from "./entity.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Asset, AssetCustomerMap } from "../models/asset";
import { CustomResult } from "../models/custom-model/customResult.model";

@Injectable({
  providedIn: "root",
})
export class AssetService extends EntityService<Asset> {
  private readonly API_URL = "/api/assets";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/assets");
  }

  get assetCount(): number {
    return this.assetCount;
  }

  getAllAssets(): Observable<Asset[]> {
    return this.httpClient.get<Asset[]>(`${this.API_URL}`);
  }

  GetAssetByCustomerIdAndField2(
    customerId: number,
    field2: string
  ): Observable<AssetCustomerMap> {
    return this.httpClient.get<AssetCustomerMap>(
      `${this.API_URL}/GetAssetByCustomerIdAndField2?customerId=` +
        customerId +
        `&field2=` +
        field2
    );
  }

  SearchCustomerAsset(term: string): Observable<AssetCustomerMap[]> {
    return this.httpClient.get<AssetCustomerMap[]>(
      `${this.API_URL}/SearchCustomerAsset?term=` + term
    );
  }

  GetAssetByCustomerIdAndField1(
    customerId: number,
    field1: string
  ): Observable<AssetCustomerMap> {
    return this.httpClient.get<AssetCustomerMap>(
      `${this.API_URL}/GetAssetByCustomerIdAndField1?customerId=` +
        customerId +
        `&field1=` +
        field1
    );
  }

  getAssetByName(name: string): Observable<Asset> {
    return this.httpClient.get<Asset>(
      `${this.API_URL}/GetAssetByName?name=` + name
    );
  }

  addAsset(asset: Asset): Observable<Asset> {
    let header = new HttpHeaders({ "content-type": "application/json" });
    return this.httpClient.post<Asset>(this.API_URL, asset, {
      headers: header,
    });
  }

  searchAssetCustomerMapByBusinessType(
    term: string,
    businessTypeId: number
  ): Observable<AssetCustomerMap[]> {
    return this.httpClient.get<AssetCustomerMap[]>(
      `${this.API_URL}/SearchAssetCustomerMapByBusinessType?term=` +
        term +
        `&businessTypeId=` +
        businessTypeId
    );
  }

  updateAsset(asset: Asset): Observable<Asset> {
    return this.httpClient.put<Asset>(
      `${this.API_URL}/` + asset.assetId,
      asset
    );
  }

  addAssetCustomerMap(
    assetCustomerMap: AssetCustomerMap
  ): Observable<CustomResult> {
    return this.httpClient.post<CustomResult>(
      this.API_URL + "/AssetCustomerMapPost",
      assetCustomerMap
    );
  }

  updateAssetCustomerMap(
    assetCustomerMap: AssetCustomerMap
  ): Observable<CustomResult> {
    return this.httpClient.put<CustomResult>(
      `${this.API_URL}/PutAssetCustomerMap/` +
        assetCustomerMap.assetCustomerMapId,
      assetCustomerMap
    );
  }

  deleteAsset(assetId: number): Observable<Asset> {
    return this.httpClient.delete<Asset>(`${this.API_URL}/` + assetId);
  }

  deleteAssetFromMap(AssetCustomerMapId: number): Observable<AssetCustomerMap> {
    return this.httpClient.delete<AssetCustomerMap>(
      `${this.API_URL}/AssetCustomerMap/` + AssetCustomerMapId
    );
  }
}
