import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { ApprovalPolicy } from "../models/approvalPolicy";
import { ApprovalPolicyService } from "../services/approvalPolicy.service";

@Injectable()
export class ApprovalPolicyResolver implements Resolve<ApprovalPolicy[]> {
  constructor(private service: ApprovalPolicyService) {}

  resolve(): Observable<ApprovalPolicy[]> {
    return this.service.getAllApprovalPolicy();
  }
}
