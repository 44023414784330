import { ApprovalPolicy } from "./approvalPolicy";
import { Approval } from "./approvals";
import { AuditableEntity } from "./custom-model/auditableEntity";
import { BusinessType } from "./businessType";
import { Invoice } from "./invoice";
import { Location } from "./location";
import { TransferOrderLine } from "./transferOrderLine";

export class TransferOrder extends AuditableEntity {
  transferOrderId: number;
  transferOrderNumber: string;
  fromLocationId: number;
  fromLocation: Location;
  toLocationId: number;
  toLocation: Location;
  invoiceId: number | null;
  invoice: Invoice;
  businessTypeId: number;
  businessType: BusinessType;

  isReceived: boolean;
  receivedBy: string;
  receivedDate: Date | string | null;

  remarks: string;
  transferOrderStatus: TransferOrderStatus;
  transferOrderType: TransferOrderType;
  approvalPolicyId: number;
  approvalPolicy: ApprovalPolicy;
  approvalId: number | null;
  approval: Approval;
  approved: boolean;
  transferOrderLine: TransferOrderLine[];
}

export class TrackTransferOrder extends AuditableEntity {
  date: string;
  remarks: string;
  userName: string;
}

export enum TransferOrderStatus {
  Open = 1,
  Ready = 2,
  Received = 3,
}

export enum TransferOrderType {
  Sales = 1,
  Stock = 2,
}
