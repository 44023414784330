import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { EntityService } from "./entity.service";
import { Entity } from "../models/custom-model/entity";
import { EndpointBase } from "../core/services/endpoint-base.service";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "../core/services/auth.service";

export class EntityDataSource<TEntity> implements DataSource<TEntity> {
  private entitiesSubject = new BehaviorSubject<TEntity[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public count = new BehaviorSubject<number>(10);
  public entityCount$ = this.count.asObservable();
  public currentData$ = this.entitiesSubject.asObservable();

  constructor(private entitiyService: EntityService<TEntity>) {}

  loadEntities(
    id: number,
    filter: string,
    sortOrder: string,
    sortColumn: string,
    pageNumber: number,
    pageSize: number,
    tableColumns: string[]
  ) {
    this.loadingSubject.next(true);

    this.entitiyService
      .findEntities(
        id,
        filter,
        sortOrder,
        sortColumn,
        pageNumber,
        pageSize,
        tableColumns
      )
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((entity: Entity<TEntity>) => {
        this.count.next(entity.total);
        this.entitiesSubject.next(entity.data);
      });
  }

  connect(collectionViewer: CollectionViewer): Observable<TEntity[]> {
    return this.entitiesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.entitiesSubject.complete();
    this.loadingSubject.complete();
    this.count.complete();
  }
}

export class EntityDataSourceCustomBindReport implements DataSource<any> {
  public entitiesSubject = new BehaviorSubject<any[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public count = new BehaviorSubject<number>(0);
  public entityCount$ = this.count.asObservable();
  public currentData$ = this.entitiesSubject.asObservable();

  constructor(
    private service: EntityService<any>,
    private endpointUrl: string
  ) {}

  loadReport(id: number, tableColumns: string[], ...args: any) {
    this.loadingSubject.next(true);
    this.service
      .findReports(id, tableColumns, this.endpointUrl, args)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((result: any) => {
        this.count.next(result.total);
        this.entitiesSubject.next(result.data);
      });
  }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.entitiesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.entitiesSubject.complete();
    this.loadingSubject.complete();
    this.count.complete();
  }
}

export class EntityDataSourceCustomBindAll implements DataSource<any> {
  public entitiesSubject = new BehaviorSubject<any[]>([]);
  public loadingSubject = new BehaviorSubject<boolean>(false);
  public count = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public entityCount$ = this.count.asObservable();
  public currentData$ = this.entitiesSubject.asObservable();

  constructor(
    private service: EntityService<any>,
    private endpointUrl: string
  ) {}

  load(id: number, filter: string, tableColumns: string[]) {
    this.loadingSubject.next(true);
    this.service
      .findAll(id, filter, tableColumns, this.endpointUrl)
      .pipe(
        catchError((err) => {
          return of([]);
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((result: any) => {
        this.count.next(result.total);
        this.entitiesSubject.next(result.data);
      });
  }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.entitiesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.entitiesSubject.complete();
    this.loadingSubject.complete();
    this.count.complete();
  }
}

export class EntityDataSourceCustomBind implements DataSource<any> {
  public entitiesSubject = new BehaviorSubject<any[]>([]);
  public loadingSubject = new BehaviorSubject<boolean>(false);
  public count = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public entityCount$ = this.count.asObservable();
  public currentData$ = this.entitiesSubject.asObservable();

  constructor(
    private service: EntityService<any>,
    private endpointUrl: string
  ) {}

  load(
    id: number,
    filter: string,
    sortOrder: string,
    sortColumn: string,
    pageNumber: number,
    pageSize: number,
    tableColumns: string[],
    sortByStatus: number,
    ...args: any
  ) {
    this.loadingSubject.next(true);

    this.service
      .find(
        id,
        filter,
        sortOrder,
        sortColumn,
        pageNumber,
        pageSize,
        tableColumns,
        this.endpointUrl,
        sortByStatus,
        args
      )
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((result: any) => {
        this.count.next(result.total);
        this.entitiesSubject.next(result.data);
      });
  }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.entitiesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.entitiesSubject.complete();
    this.loadingSubject.complete();
  }

  // load(
  //   id: number,
  //   filter: string,
  //   sortOrder: string,
  //   sortColumn: string,
  //   pageNumber: number,
  //   pageSize: number,
  //   tableColumns: string[],
  //   sortByStatus: number,
  //   ...args: any
  // ) {
  //   this.loadingSubject.next(true);
  //   this.service
  //     .find(
  //       id,
  //       filter,
  //       sortOrder,
  //       sortColumn,
  //       pageNumber,
  //       pageSize,
  //       tableColumns,
  //       this.endpointUrl,
  //       sortByStatus,
  //       args
  //     )
  //     .pipe(
  //       catchError((err) => {
  //         console.log(err.message);
  //         return of([]);
  //       }),
  //       finalize(() => this.loadingSubject.next(false))
  //     )
  //     .subscribe((result: any) => {
  //       this.count.next(result.total);
  //       this.entitiesSubject.next(result.data);
  //     });
  // }

  // connect(collectionViewer: CollectionViewer): Observable<any[]> {
  //   return this.entitiesSubject.asObservable();
  // }

  // disconnect(collectionViewer: CollectionViewer): void {
  //   this.entitiesSubject.complete();
  //   this.loadingSubject.complete();
  //   this.count.complete();
  // }
}

export class EntityDataSourceCustomBindProduct implements DataSource<any> {
  private entitiesSubject = new BehaviorSubject<any[]>([]);

  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  public count = new BehaviorSubject<number>(0);

  public entityCount$ = this.count.asObservable();

  public currentData$ = this.entitiesSubject.asObservable();

  constructor(
    private service: EntityService<any>,
    private endpointUrl: string
  ) {}

  load(
    id: number,
    filter: string,
    sortOrder: string,
    sortColumn: string,
    pageNumber: number,
    pageSize: number,
    tableColumns: string[],
    sortByStatus: number,
    businessType: number,
    productType: number,
    productCondition: number,
    productStatus: number,
    productLocation: number,
    brand: number,
    model: number,
    priceRangeMin: number,
    priceRangeMax: number
  ) {
    this.loadingSubject.next(true);

    this.service
      .findProducts(
        id,
        filter,
        sortOrder,
        sortColumn,
        pageNumber,
        pageSize,
        tableColumns,
        this.endpointUrl,
        sortByStatus,
        businessType,
        productType,
        productCondition,
        productStatus,
        productLocation,
        brand,
        model,
        priceRangeMin,
        priceRangeMax
      )
      .pipe(
        catchError((err) => {
          console.log(err.message);
          return of([]);
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((result: any) => {
        this.count.next(result.total);
        this.entitiesSubject.next(result.data);
      });
  }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.entitiesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.entitiesSubject.complete();
    this.loadingSubject.complete();
    this.count.complete();
  }
}
