import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BankStatement } from "../models/bankStatements";
import { CustomResult } from "../models/custom-model/customResult.model";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class BankStatementService extends EntityService<BankStatement> {
  private readonly API_URL = "/api/bankStatements";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/bankStatements");
  }

  getAllBankStatements(): Observable<BankStatement[]> {
    return this.httpClient.get<BankStatement[]>(`${this.API_URL}`);
  }

  addBankStatement(bankStatement: BankStatement): Observable<BankStatement> {
    return this.httpClient.post<BankStatement>(this.API_URL, bankStatement);
  }

  updateBankStatement(bankStatement: BankStatement): Observable<BankStatement> {
    return this.httpClient.put<BankStatement>(
      `${this.API_URL}/` + bankStatement.bankStatementId,
      bankStatement
    );
  }

  deleteBankStatement(bankStatementId: number): Observable<BankStatement> {
    return this.httpClient.delete<BankStatement>(
      `${this.API_URL}/` + bankStatementId
    );
  }

  bulkVerify(
    businessTypeId: number,
    fromDate: string,
    toDate: string,
    userName: string
  ): Observable<CustomResult> {
    return this.httpClient.get<CustomResult>(
      `${this.API_URL}/BulkVerify?businessTypeId=` +
        businessTypeId +
        `&fromDate=` +
        fromDate +
        `&toDate=` +
        toDate +
        `&userName=` +
        userName
    );
  }

  download(businessName): Observable<Blob> {
    return this.httpClient.post<Blob>(
      `${this.API_URL}/ExportUploadTemplate?businessName=` + businessName,
      businessName,
      { responseType: "blob" as "json" }
    );
  }
}
