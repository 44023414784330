import { Clearance } from "../models/clearance.model";
import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { ClearanceService } from "../services/clearance.service";

@Injectable()
export class ClearanceAllResolver implements Resolve<Clearance[]> {
  constructor(private service: ClearanceService) {}

  resolve(): Observable<Clearance[]> {
    return this.service.getAllClearances();
  }
}
