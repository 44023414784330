import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StaffDiscountExemption } from "../models/staffDiscountExemption";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: "root",
})
export class StaffDiscountExemptionService extends EntityService<StaffDiscountExemption> {
  private readonly API_URL = "/api/staffDiscountExemption";

  constructor(public httpClient: HttpClient) {
    super(httpClient, "/api/staffDiscountExemption");
  }

  get taxExemptionCount(): number {
    return this.taxExemptionCount;
  }

  getAllStaffDiscountExemption(): Observable<StaffDiscountExemption[]> {
    return this.httpClient.get<StaffDiscountExemption[]>(`${this.API_URL}`);
  }

  getStaffDiscountExemptionByBusinessTypeId(
    businessTypeId: number
  ): Observable<StaffDiscountExemption[]> {
    return this.httpClient.get<StaffDiscountExemption[]>(
      `${this.API_URL}/GetStaffDiscountExemptionByBusinessTypeId?businessTypeId=` +
        businessTypeId
    );
  }

  getStaffDiscountExemptionByFilters(
    businessTypeId: number,
    productId: number,
    staffDiscountScreen: number,
    discountId: number
  ): Observable<StaffDiscountExemption> {
    return this.httpClient.get<StaffDiscountExemption>(
      `${this.API_URL}/GetStaffDiscountExemptionByFilters?businessTypeId=` +
        businessTypeId +
        `&productId=` +
        productId +
        `&staffDiscountScreen=` +
        staffDiscountScreen +
        `&discountId=` +
        discountId
    );
  }

  addStaffDiscountExemption(
    staffDiscountExemption: StaffDiscountExemption
  ): Observable<StaffDiscountExemption> {
    return this.httpClient.post<StaffDiscountExemption>(
      this.API_URL,
      staffDiscountExemption
    );
  }

  updateStaffDiscountExemption(
    staffDiscountExemption: StaffDiscountExemption
  ): Observable<StaffDiscountExemption> {
    return this.httpClient.put<StaffDiscountExemption>(
      `${this.API_URL}/` + staffDiscountExemption.staffDiscountExemptionId,
      staffDiscountExemption
    );
  }

  deleteStaffDiscountExemption(
    staffDiscountExemptionId: number
  ): Observable<StaffDiscountExemption> {
    return this.httpClient.delete<StaffDiscountExemption>(
      `${this.API_URL}/` + staffDiscountExemptionId
    );
  }
}
