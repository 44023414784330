import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { DiscountEligibility } from "../models/discountEligibility";
import { DiscountEligibilityService } from "../services/discountEligibility.service";
import { AuthService } from "../core/services/auth.service";

@Injectable()
export class DiscountEligibilityResolver
  implements Resolve<DiscountEligibility[]>
{
  constructor(
    private service: DiscountEligibilityService,
    private authService: AuthService
  ) {}

  resolve(): Observable<DiscountEligibility[]> {
    if (this.authService.isLoggedIn) {
      if (this.authService.currentBusiness) {
        return this.service.getDiscountEligibilityByBusinessTypeId(
          this.authService.currentBusiness.businessTypeId
        );
      }
    } else {
      return null;
    }
  }
}
