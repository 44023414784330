export class CustomResult {
  data: any;
  messageType: MessageType;
  message: string;
}

export enum MessageType {
  Success = 1,
  Warning = 2,
  Error = 3,
}
